export const setSelectedPageTable = (page) => async (dispatch) => {
	dispatch({type:'SET_INSP_SELECTED_PAGE', payload: page});
};

export const setListInspections = (page) => async (dispatch) => {
	dispatch({type:'SET_INSPECTIONS_LIST', payload: page});
};

export const setListComments = (id, comments) => async (dispatch) => {
	dispatch({type:'SET_COMMENTS_LIST', payload: {id, comments}  });
};

export const setNewComment = (id, comment) => async (dispatch) => {
	dispatch({type:'SET_NEW_COMMENT', payload: {id, comment}  });
};

export const setNewInspectionsWithState = (inspectionList) => async (dispatch) => {
	dispatch({type:'SET_INSPECTIONS_WITH_STATE', payload: inspectionList  });
};

export const setLabMode = (labModeStatus) => async (dispatch) => {
	dispatch({type:'SET_LAB_MODE', payload: labModeStatus });
};
