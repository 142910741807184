import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  setFilterList,
  deleteFilterList,
  resetFilterList,
} from "../../../actions/filterListActions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "30px",
    borderRadius: 16,
    borderStyle: "dashed",
    fontSize: 12,
    marginRight: 5,
    "&:hover": {
      color: "#fff",
      border: "unset",
      backgroundColor: "#C8B4F3",
    },
  },
  drawerPaper: {
    width: "350px",
    borderTopLeftRadius: "10px",
  },
  rootBtn: {
    color: "#fff",
    height: "15px",
    margin: "auto 0px",
  },
}));

export const LIST_STATES_INSPECTION = [
  "pendiente",//0
  "cancelar",//1
  "rehacer",//2
  "rechazar",//3
  "aprobado",
  "pendiente por aprobar"
];

const DynamicsButtons = () => {
  const filterList = useSelector((state) => state.filter.filterList);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [drawerState, setDrawerState] = useState(false);

  function toObject(arr) {
    var rv = {};
    for (var i = 0; i < arr.length; ++i) rv[arr[i]] = true;
    return rv;
  }
  const _checkboxSat = toObject(filterList);

  const [checkboxState, setCheckboxState] = useState({
    pendiente: false,
    rehacer: false,
    cancelar: false,
    rechazar: false,
    aprobar: false,
    pendPorAprobar: false,
    ..._checkboxSat,
  });
  

  const toggleDrawer = (state) => {
    setDrawerState(state);
  };

  const handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      dispatch(setFilterList(event.target.name));
    } else {
      dispatch(deleteFilterList(event.target.name));
    }
    setCheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });
  };

  let checkboxStateArr = [];
  Object.keys(checkboxState).forEach(function (key) {
    if (checkboxState[key]) checkboxStateArr.push(key);
  });

  return (
    <>
      <Drawer
        anchor={"right"}
        open={drawerState}
        onClose={() => toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <header
          style={{
            padding: "1rem",
            marginBottom: "1rem",
            backgroundColor: "#00A0F8",
          }}
        >
          <h2
            style={{
              color: "#fff",
              fontSize: "16px",
            }}
          >
            Filtro de Estados
          </h2>
        </header>
        <section
          style={{
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          {LIST_STATES_INSPECTION.map((state) => {
            return (
              <div key={state}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeCheckbox}
                      checked={checkboxState[state]}
                      name={state}
                    />
                  }
                  label={state}
                />
              </div>
            );
          })}
        </section>
      </Drawer>
      <Button
        variant="outlined"
        onClick={() => toggleDrawer(true)}
        classes={{
          root: classes.root,
        }}
      >
        Agregar un filtro
      </Button>
      {filterList.map((filtro) => (
        <Button
          variant="contained"
          color="primary"
          classes={{
            root: classes.root,
          }}
          onClick={() => {
            dispatch(deleteFilterList(filtro));
          }}
        >
          {filtro}
          <CancelIcon classes={{ root: classes.rootBtn }} />
        </Button>
      ))}
    </>
  );
};

export default DynamicsButtons;
