import {
	POST_LOGIN_PENDING,
	POST_LOGIN_FULFILLED,
	POST_LOGIN_REJECTED,
	GET_LOGOUT_PENDING,
	GET_LOGOUT_FULFILLED,
	GET_LOGOUT_REJECTED,
	GET_CHECK_AUTH_PENDING,
	GET_CHECK_AUTH_FULFILLED,
	GET_CHECK_AUTH_REJECTED,
	PATCH_LOGIN_ERROR,
	SET_AUTH_TOKEN
} from "../actions/types";

const initialState = {
	token: null,
	loading: false,
	error: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case POST_LOGIN_PENDING:
			return {
				...state,
				error: false,
				loading: true,
			};
		case POST_LOGIN_FULFILLED:
			return {
				...state,
				token: action.payload.token,
				loading: false,
			};
		case POST_LOGIN_REJECTED:
			return {
				...state,
				error: true,
				loading: false,
			};
		case GET_LOGOUT_PENDING:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_LOGOUT_FULFILLED:
			return {
				...state,
				token: null,
				loading: false,
			};
		case GET_LOGOUT_REJECTED:
			return {
				...state,
				error: true,
				loading: false,
			};
		case GET_CHECK_AUTH_PENDING:
			return {
				...state,
				error: false,
				loading: true,
			};
		case GET_CHECK_AUTH_FULFILLED:
			return {
				...state,
				loading: false,
			};
		case GET_CHECK_AUTH_REJECTED:
			return {
				...state,
				token: null,
				error: true,
				loading: false,
			};
		case PATCH_LOGIN_ERROR:
			return {
				...state,
				error: false,
			};
		case SET_AUTH_TOKEN:
			return {
				...state,
				token: action.payload,
				loading: false,
			};			
		default:
			return state;
	}
}
