import React, { useEffect, useState } from 'react';
import { getGuideInfoWithTemporaryId } from '../../../sevices/inspectionService';
import { gridPhotosFromGuide } from '../PhotoSquare/templates';
import { MAP_TYPE_PHOTO_HEADER_10, MAP_TYPE_PHOTO_HEADER_8 } from '../../../utils/constants';


const SnapShotPanel = ({ showTab, remoteVideo, idVideo, data }) => {
    const [guideInfo, setGuideInfo] = useState(null);
    const [isActive, setIsActive] = useState(showTab);

    useEffect(()=> {
        
        async function callAsync () {
            if ( !data ) return;
            const guideInfo = await getGuideInfoWithTemporaryId(idVideo);

            if ( guideInfo?.photos[0]?.name === 'Externa' && guideInfo?.photos[0]?.model === "10" && guideInfo?.photos[0]?.related?.length < 10 ) {

                guideInfo.photos[0].related = [];

                MAP_TYPE_PHOTO_HEADER_10.map(
                    (name, index) => {
                        guideInfo.photos[0].related.push({description: '', position: index, name})
                    }
                )
            }

            if ( guideInfo?.photos[0]?.name === 'Externa' && guideInfo?.photos[0]?.model === "8" && guideInfo?.photos[0]?.related?.length < 10 ) {

                guideInfo.photos[0].related = [];

                MAP_TYPE_PHOTO_HEADER_8.map(
                    (name, index) => {
                        guideInfo.photos[0].related.push({description: '', position: index, name})
                    }
                )
            }

            for (let section = 0; section < guideInfo?.photos?.length; section++) {

                const auxData = data.photos.find( element => element[0].trim() === guideInfo.photos[section].name.trim() );

                
                if ( !auxData ) continue;

                // filling photos at inspection photos 
                guideInfo.photos[section].related.map(  
                    ( data, index ) => {
                        const elementWithPhoto = auxData[1].find( element => element.name.trim() === data.name.trim() );
                        console.log('awui explita=>', elementWithPhoto );
                        guideInfo.photos[section].related[index].photo = elementWithPhoto?.photo;
                        
                    }
                );
            }
            setGuideInfo(guideInfo);
        }
        callAsync();
        setIsActive(showTab)

    },[showTab, idVideo, data])

    return (
        <div style={{padding: '5px', display: !isActive && 'none' , height:'calc(100vh - 215px)', overflowY:'scroll'}}>
            <div>{
                    guideInfo &&
                    <>
                        <h2 style={{ fontSize: '20px', color: '#333333', fontWeight: 600 }}>Guia: {guideInfo.name}</h2>
                    </>
                }
                { gridPhotosFromGuide(guideInfo, remoteVideo, idVideo) }
            </div>
        </div>
    )
}

export default SnapShotPanel;