export const POST_LOGIN_PENDING = "POST_LOGIN_PENDING";
export const POST_LOGIN_FULFILLED = "POST_LOGIN_FULFILLED";
export const POST_LOGIN_REJECTED = "POST_LOGIN_REJECTED";
export const PATCH_LOGIN_ERROR = "PATCH_LOGIN_ERROR";

export const GET_LOGOUT_PENDING = "GET_LOGOUT_PENDING";
export const GET_LOGOUT_FULFILLED = "GET_LOGOUT_FULFILLED";
export const GET_LOGOUT_REJECTED = "GET_LOGOUT_REJECTED";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";

export const GET_CHECK_AUTH_PENDING = "GET_CHECK_AUTH_PENDING";
export const GET_CHECK_AUTH_FULFILLED = "GET_CHECK_AUTH_FULFILLED";
export const GET_CHECK_AUTH_REJECTED = "GET_CHECK_AUTH_REJECTED";

export const GET_PRODUCT_PENDING = "GET_PRODUCT_PENDING";
export const GET_PRODUCT_FULFILLED = "GET_PRODUCT_FULFILLED";
export const GET_PRODUCT_REJECTED = "GET_PRODUCT_REJECTED";

export const ADD_PRODUCT_PENDING = "ADD_PRODUCT_PENDING";
export const ADD_PRODUCT_FULFILLED = "ADD_PRODUCT_FULFILLED";
export const ADD_PRODUCT_REJECTED = "ADD_PRODUCT_REJECTED";

export const UPDATE_PRODUCT_PENDING = "UPDATE_PRODUCT_PENDING";
export const UPDATE_PRODUCT_FULFILLED = "UPDATE_PRODUCT_FULFILLED";
export const UPDATE_PRODUCT_REJECTED = "UPDATE_PRODUCT_REJECTED";

export const DELETE_PRODUCT_PENDING = "DELETE_PRODUCT_PENDING";
export const DELETE_PRODUCT_FULFILLED = "DELETE_PRODUCT_FULFILLED";
export const DELETE_PRODUCT_REJECTED = "DELETE_PRODUCT_REJECTED";

export const GET_LINE_BUSSINESS_PENDING = "GET_LINE_BUSSINESS_PENDING";
export const GET_LINE_BUSSINESS_FULFILLED = "GET_LINE_BUSSINESS_FULFILLED";
export const GET_LINE_BUSSINESS_REJECTED = "GET_LINE_BUSSINESS_REJECTED";

export const GET_CURRENCY_PENDING = "GET_CURRENCY_PENDING";
export const GET_CURRENCY_FULFILLED = "GET_CURRENCY_FULFILLED";
export const GET_CURRENCY_REJECTED = "GET_CURRENCY_REJECTED";

export const GET_RISK_TYPE_PENDING = "GET_RISK_TYPE_PENDING";
export const GET_RISK_TYPE_FULFILLED = "GET_RISK_TYPE_FULFILLED";
export const GET_RISK_TYPE_REJECTED = "GET_RISK_TYPE_REJECTED";

export const GET_COVER_PENDING = "GET_COVER_PENDING";
export const GET_COVER_FULFILLED = "GET_COVER_FULFILLED";
export const GET_COVER_REJECTED = "GET_COVER_REJECTED";

export const GET_COVER_PRODUCT = "GET_COVER_PRODUCT";
export const ADD_COVER_PRODUCT = "ADD_COVER_PRODUCT";
export const UPDATE_COVER_PRODUCT = "UPDATE_COVER_PRODUCT";
export const DELETE_COVER_PRODUCT = "DELETE_COVER_PRODUCT";

export const GET_INSURANCE_PRODUCT = "GET_INSURANCE_PRODUCT";
export const ADD_INSURANCE_PRODUCT = "ADD_INSURANCE_PRODUCT";
export const DELETE_INSURANCE_PRODUCT = "DELETE_INSURANCE_PRODUCT";
export const CLEAN_INSURANCE_PRODUCT = "CLEAN_INSURANCE_PRODUCT";

export const GET_ASISTANCE_PENDING = "GET_ASISTANCE_PENDING";
export const GET_ASISTANCE_FULFILLED = "GET_ASISTANCE_FULFILLED";
export const GET_ASISTANCE_REJECTED = "GET_ASISTANCE_REJECTED";

export const GET_ASISTANCE_PRODUCT = "GET_ASISTANCE_PRODUCT";
export const ADD_ASISTANCE_PRODUCT = "ADD_ASISTANCE_PRODUCT";
export const UPDATE_ASISTANCE_PRODUCT = "UPDATE_ASISTANCE_PRODUCT";
export const DELETE_ASISTANCE_PRODUCT = "DELETE_ASISTANCE_PRODUCT";

export const GET_TAX_PENDING = "GET_TAX_PENDING";
export const GET_TAX_FULFILLED = "GET_TAX_FULFILLED";
export const GET_TAX_REJECTED = "GET_TAX_REJECTED";

export const GET_TAX_PRODUCT = "GET_TAX_PRODUCT";
export const ADD_TAX_PRODUCT = "ADD_TAX_PRODUCT";
export const DELETE_TAX_PRODUCT = "DELETE_TAX_PRODUCT";

export const GET_TARIFF_PENDING = "GET_TARIFF_PENDING";
export const GET_TARIFF_FULFILLED = "GET_TARIFF_FULFILLED";
export const GET_TARIFF_REJECTED = "GET_TARIFF_REJECTED";

export const GET_TARIFF_PRODUCT = "GET_TARIFF_PRODUCT";
export const ADD_TARIFF_PRODUCT = "ADD_TARIFF_PRODUCT";
export const DELETE_TARIFF_PRODUCT = "DELETE_TARIFF_PRODUCT";

export const FILTER_PRODUCTS = "FILTER_PRODUCTS";
export const RESET_PRODUCTS = "RESET_PRODUCTS";

export const GET_LEADS_PENDING = "GET_LEADS_PENDING";
export const GET_LEADS_FULFILLED = "GET_LEADS_FULFILLED";
export const GET_LEADS_REJECTED = "GET_LEADS_REJECTED";

export const FILTER_LEADS = "FILTER_LEADS";
export const RESET_LEADS = "RESET_LEADS";

export const CHECK_TRAFFICS_DATA = "CHECK_TRAFFICS_DATA";

export const GET_INSURANCE_PENDING = "GET_INSURANCE_PENDING";
export const GET_INSURANCE_FULFILLED = "GET_INSURANCE_FULFILLED";
export const GET_INSURANCE_REJECTED = "GET_INSURANCE_REJECTED";

export const ADD_INSURANCE_PENDING = "ADD_INSURANCE_PENDING";
export const ADD_INSURANCE_FULFILLED = "ADD_INSURANCE_FULFILLED";
export const ADD_INSURANCE_REJECTED = "ADD_INSURANCE_REJECTED";

export const UPDATE_INSURANCE_PENDING = "UPDATE_INSURANCE_PENDING";
export const UPDATE_INSURANCE_FULFILLED = "UPDATE_INSURANCE_FULFILLED";
export const UPDATE_INSURANCE_REJECTED = "UPDATE_INSURANCE_REJECTED";

export const DELETE_INSURANCE_PENDING = "DELETE_INSURANCE_PENDING";
export const DELETE_INSURANCE_FULFILLED = "DELETE_INSURANCE_FULFILLED";
export const DELETE_INSURANCE_REJECTED = "DELETE_INSURANCE_REJECTED";

export const FILTER_INSURANCES = "FILTER_INSURANCES";
export const RESET_INSURANCES = "RESET_INSURANCES";
export const GET_QUOTATION_SCREEN_PENDING = "GET_QUOTATION_SCREEN_PENDING";
export const GET_QUOTATION_SCREEN_FULFILLED = "GET_QUOTATION_SCREEN_FULFILLED";
export const GET_QUOTATION_SCREEN_REJECTED = "GET_QUOTATION_SCREEN_REJECTED";

export const GET_QUOTATION_SCREEN_BY_ID_PENDING =
	"GET_QUOTATION_SCREEN_BY_ID_PENDING";
export const GET_QUOTATION_SCREEN_BY_ID_FULFILLED =
	"GET_QUOTATION_SCREEN_BY_ID_FULFILLED";
export const GET_QUOTATION_SCREEN_BY_ID_REJECTED =
	"GET_QUOTATION_SCREEN_BY_ID_REJECTED";

export const ADD_QUOTATION_SCREEN_PENDING = "ADD_QUOTATION_SCREEN_PENDING";
export const ADD_QUOTATION_SCREEN_FULFILLED = "ADD_QUOTATION_SCREEN_FULFILLED";
export const ADD_QUOTATION_SCREEN_REJECTED = "ADD_QUOTATION_SCREEN_REJECTED";

export const UPDATE_QUOTATION_SCREEN_PENDING =
	"UPDATE_QUOTATION_SCREEN_PENDING";
export const UPDATE_QUOTATION_SCREEN_FULFILLED =
	"UPDATE_QUOTATION_SCREEN_FULFILLED";
export const UPDATE_QUOTATION_SCREEN_REJECTED =
	"UPDATE_QUOTATION_SCREEN_REJECTED";

export const DELETE_QUOTATION_SCREEN_PENDING =
	"DELETE_QUOTATION_SCREEN_PENDING";
export const DELETE_QUOTATION_SCREEN_FULFILLED =
	"DELETE_QUOTATION_SCREEN_FULFILLED";
export const DELETE_QUOTATION_SCREEN_REJECTED =
	"DELETE_QUOTATION_SCREEN_REJECTED";

export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_DASHBOARD_DATA_PENDING = "GET_DASHBOARD_DATA_PENDING";
export const GET_DASHBOARD_DATA_FULFILLED = "GET_DASHBOARD_DATA_FULFILLED";
export const GET_DASHBOARD_DATA_REJECTED = "GET_DASHBOARD_DATA_REJECTED";
export const RESET_DASHBOARD = "RESET_DASHBOARD";
export const FILTER_DASHBOARD = "FILTER_DASHBOARD";

export const ADD_QUOTATION_SCREEN_GROUP = "ADD_QUOTATION_SCREEN_GROUP";
export const UPDATE_QUOTATION_SCREEN_GROUP = "UPDATE_QUOTATION_SCREEN_GROUP";
export const DELETE_QUOTATION_SCREEN_GROUP = "DELETE_QUOTATION_SCREEN_GROUP";

export const ADD_QUOTATION_SCREEN_GROUP_FIELD =
	"ADD_QUOTATION_SCREEN_GROUP_FIELD";
export const UPDATE_QUOTATION_SCREEN_GROUP_FIELD =
	"UPDATE_QUOTATION_SCREEN_GROUP_FIELD";
export const DELETE_QUOTATION_SCREEN_GROUP_FIELD =
	"DELETE_QUOTATION_SCREEN_GROUP_FIELD";

export const SWAP_QUOTATION_SCREEN_GROUP_FIELDS =
	"SWAP_QUOTATION_SCREEN_GROUP_FIELDS";

export const GET_USERS = "GET_USERS";
export const GET_USERS_PENDING = "GET_USERS_PENDING";
export const GET_USERS_FULFILLED = "GET_USERS_FULFILLED";
export const GET_USERS_REJECTED = "GET_USERS_REJECTED";

export const ADD_USER_PENDING = "ADD_USER_PENDING";
export const ADD_USER_FULFILLED = "ADD_USER_FULFILLED";
export const ADD_USER_REJECTED = "ADD_USER_REJECTED";

export const UPDATE_USER_PENDING = "UPDATE_USER_PENDING";
export const UPDATE_USER_FULFILLED = "UPDATE_USER_FULFILLED";
export const UPDATE_USER_REJECTED = "UPDATE_USER_REJECTED";

export const DELETE_USER_PENDING = "DELETE_USER_PENDING";
export const DELETE_USER_FULFILLED = "DELETE_USER_FULFILLED";
export const DELETE_USER_REJECTED = "DELETE_USER_REJECTED";

export const FILTER_USERS = "FILTER_USERS";
export const RESET_USERS = "RESET_USERS";

export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_PENDING = "GET_ROLES_PENDING";
export const GET_ROLES_FULFILLED = "GET_ROLES_FULFILLED";
export const GET_ROLES_REJECTED = "GET_ROLES_REJECTED";

export const ADD_ROLE_PENDING = "ADD_ROLE_PENDING";
export const ADD_ROLE_FULFILLED = "ADD_ROLE_FULFILLED";
export const ADD_ROLE_REJECTED = "ADD_ROLE_REJECTED";

export const UPDATE_ROLE_PENDING = "UPDATE_ROLE_PENDING";
export const UPDATE_ROLE_FULFILLED = "UPDATE_ROLE_FULFILLED";
export const UPDATE_ROLE_REJECTED = "UPDATE_ROLE_REJECTED";

export const DELETE_ROLE_PENDING = "DELETE_ROLE_PENDING";
export const DELETE_ROLE_FULFILLED = "DELETE_ROLE_FULFILLED";
export const DELETE_ROLE_REJECTED = "DELETE_ROLE_REJECTED";

export const FILTER_ROLES = "FILTER_ROLES";
export const RESET_ROLES = "RESET_ROLES";

export const GET_RESOURCES = "GET_RESOURCES";
export const GET_RESOURCES_PENDING = "GET_RESOURCES_PENDING";
export const GET_RESOURCES_FULFILLED = "GET_RESOURCES_FULFILLED";
export const GET_RESOURCES_REJECTED = "GET_RESOURCES_REJECTED";

export const ADD_RESOURCE = "ADD_RESOURCE";
export const ADD_RESOURCE_PENDING = "ADD_RESOURCE_PENDING";
export const ADD_RESOURCE_FULFILLED = "ADD_RESOURCE_FULFILLED";
export const ADD_RESOURCE_REJECTED = "ADD_RESOURCE_REJECTED";

export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export const UPDATE_RESOURCE_PENDING = "UPDATE_RESOURCE_PENDING";
export const UPDATE_RESOURCE_FULFILLED = "UPDATE_RESOURCE_FULFILLED";
export const UPDATE_RESOURCE_REJECTED = "UPDATE_RESOURCE_REJECTED";

export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const DELETE_RESOURCE_PENDING = "DELETE_RESOURCE_PENDING";
export const DELETE_RESOURCE_FULFILLED = "DELETE_RESOURCE_FULFILLED";
export const DELETE_RESOURCE_REJECTED = "DELETE_RESOURCE_REJECTED";

export const GET_REGISTRATIONS = "GET_REGISTRATIONS"
export const GET_REGISTRATIONS_PENDING = "GET_REGISTRATIONS_PENDING"
export const GET_REGISTRATIONS_FULFILLED = "GET_REGISTRATIONS_FULFILLED"
export const GET_REGISTRATIONS_REJECTED = "GET_REGISTRATIONS_REJECTED"

export const DELETE_REGISTRATIONS = "DELETE_REGISTRATIONS"
export const DELETE_REGISTRATIONS_PENDING = "DELETE_REGISTRATIONS_PENDING"
export const DELETE_REGISTRATIONS_FULFILLED = "DELETE_REGISTRATIONS_FULFILLED"
export const DELETE_REGISTRATIONS_REJECTED = "DELETE_REGISTRATIONS_REJECTED"

export const APPROVE_REGISTRATIONS = "APPROVE_REGISTRATIONS"
export const APPROVE_REGISTRATIONS_PENDING = "APPROVE_REGISTRATIONS_PENDING"
export const APPROVE_REGISTRATIONS_FULFILLED = "APPROVE_REGISTRATIONS_FULFILLED"
export const APPROVE_REGISTRATIONS_REJECTED = "APPROVE_REGISTRATIONS_REJECTED"

export const FILTER_RESOURCES = "FILTER_RESOURCES";
export const RESET_RESOURCES = "RESET_RESOURCES";

export const RESET_QUOTATION_SCREEN = "RESET_QUOTATION_SCREEN";

export const SHOW_SUCCESS = "SHOW_SUCCESS";
export const SHOW_WARNING = "SHOW_WARNING";
export const SHOW_ERROR = "SHOW_ERROR";
export const SHOW_MESSAGE = "SHOW_MESSAGE";

export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const HIDE_SUCCESS = "HIDE_SUCCESS";
export const HIDE_WARNING = "HIDE_WARNING";
export const HIDE_ERROR = "HIDE_ERROR";

export const GET_POLICY_REQUEST_PENDING = "GET_POLICY_REQUEST_PENDING";
export const GET_POLICY_REQUEST_FULFILLED = "GET_POLICY_REQUEST_FULFILLED";
export const GET_POLICY_REQUEST_REJECTED = "GET_POLICY_REQUEST_REJECTED";

export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_PENDING = "GET_NEWS_PENDING";
export const GET_NEWS_FULFILLED = "GET_NEWS_FULFILLED";
export const GET_NEWS_REJECTED = "GET_NEWS_REJECTED";

export const ADD_NEWS = "ADD_NEWS";
export const ADD_NEWS_PENDING = "ADD_NEWS_PENDING";
export const ADD_NEWS_FULFILLED = "ADD_NEWS_FULFILLED";
export const ADD_NEWS_REJECTED = "ADD_NEWS_REJECTED";

export const UPDATE_NEWS = "UPDATE_NEWS";
export const UPDATE_NEWS_PENDING = "UPDATE_NEWS_PENDING";
export const UPDATE_NEWS_FULFILLED = "UPDATE_NEWS_FULFILLED";
export const UPDATE_NEWS_REJECTED = "UPDATE_NEWS_REJECTED";

export const DELETE_NEWS = "DELETE_NEWS";
export const DELETE_NEWS_PENDING = "DELETE_NEWS_PENDING";
export const DELETE_NEWS_FULFILLED = "DELETE_NEWS_FULFILLED";
export const DELETE_NEWS_REJECTED = "DELETE_NEWS_REJECTED";

export const FILTER_NEWS = "FILTER_NEWS";
export const RESET_NEWS = "RESET_NEWS";

export const GET_MODULES = "GET_MODULES";
export const GET_MODULES_PENDING = "GET_MODULES_PENDING";
export const GET_MODULES_FULFILLED = "GET_MODULES_FULFILLED";
export const GET_MODULES_REJECTED = "GET_MODULES_REJECTED";

export const GET_MODULE_BY_ID = "GET_MODULE_BY_ID";
export const GET_MODULE_BY_ID_PENDING = "GET_MODULE_BY_ID_PENDING";
export const GET_MODULE_BY_ID_FULFILLED = "GET_MODULE_BY_ID_FULFILLED";
export const GET_MODULE_BY_ID_REJECTED = "GET_MODULE_BY_ID_REJECTED";

export const ADD_MODULE = "ADD_MODULE";
export const ADD_MODULE_PENDING = "ADD_MODULE_PENDING";
export const ADD_MODULE_FULFILLED = "ADD_MODULE_FULFILLED";
export const ADD_MODULE_REJECTED = "ADD_MODULE_REJECTED";

export const UPDATE_MODULE = "UPDATE_MODULE";
export const UPDATE_MODULE_PENDING = "UPDATE_MODULE_PENDING";
export const UPDATE_MODULE_FULFILLED = "UPDATE_MODULE_FULFILLED";
export const UPDATE_MODULE_REJECTED = "UPDATE_MODULE_REJECTED";

export const DELETE_MODULE = "DELETE_MODULE";
export const DELETE_MODULE_PENDING = "DELETE_MODULE_PENDING";
export const DELETE_MODULE_FULFILLED = "DELETE_MODULE_FULFILLED";
export const DELETE_MODULE_REJECTED = "DELETE_MODULE_REJECTED";

export const ADD_DEFINITION_MODULE = "ADD_DEFINITION_MODULE";
export const UPDATE_DEFINITION_MODULE = "UPDATE_DEFINITION_MODULE";
export const DELETE_DEFINITION_MODULE = "DELETE_DEFINITION_MODULE";

export const ADD_EVENT_MODULE = "ADD_EVENT_MODULE";
export const UPDATE_EVENT_MODULE = "UPDATE_EVENT_MODULE";
export const DELETE_EVENT_MODULE = "DELETE_EVENT_MODULE";

export const RESET_MODULE = "RESET_MODULE";

export const ADD_INSPECTION = "ADD_INSPECTION";
export const ADD_INSPECTION_PENDING = "ADD_INSPECTION_PENDING";
export const ADD_INSPECTION_FULFILLED = "ADD_INSPECTION_FULFILLED";
export const ADD_INSPECTION_REJECTED = "ADD_INSPECTION_REJECTED";

export const START_STREAM = "START_STREAM"; 
export const CLOSE_STREAM = "CLOSE_STREAM"; 

export const GET_USER_INFO_LOGGED_PENDING = "GET_USER_INFO_LOGGED_PENDING"
export const GET_USER_INFO_LOGGED_FULFILLED = "GET_USER_INFO_LOGGED_FULFILLED"
export const GET_USER_INFO_LOGGED_REJECTED = "GET_USER_INFO_LOGGED_REJECTED"

export const UPDATE_USER_INFO_PENDING = "UPDATE_USER_INFO_PENDING"
export const UPDATE_USER_INFO_FULFILLED = "UPDATE_USER_INFO_FULFILLED"
export const UPDATE_USER_INFO_REJECTED = "UPDATE_USER_INFO_REJECTED"

export const GET_USER_CONFIG_PENDING = "GET_USER_CONFIG_PENDING";
export const GET_USER_CONFIG_FULFILLED = "GET_USER_CONFIG_FULFILLED";
export const GET_USER_CONFIG_REJECTED = "GET_USER_CONFIG_REJECTED";

export const GET_GUIDES = "GET_GUIDES";
export const GET_GUIDES_PENDING = "GET_GUIDES_PENDING";
export const GET_GUIDES_FULFILLED = "GET_GUIDES_FULFILLED";
export const GET_GUIDES_REJECTED = "GET_GUIDES_REJECTED";

export const ADD_GUIDE = "ADD_GUIDE";
export const ADD_GUIDE_PENDING = "ADD_GUIDE_PENDING";
export const ADD_GUIDE_FULFILLED = "ADD_GUIDE_FULFILLED";
export const ADD_GUIDE_REJECTED = "ADD_GUIDE_REJECTED";

export const UPDATE_GUIDE = "UPDATE_GUIDE";
export const UPDATE_GUIDE_PENDING = "UPDATE_GUIDE_PENDING";
export const UPDATE_GUIDE_FULFILLED = "UPDATE_GUIDE_FULFILLED";
export const UPDATE_GUIDE_REJECTED = "UPDATE_GUIDE_REJECTED";

export const DELETE_GUIDE = "DELETE_GUIDE";
export const DELETE_GUIDE_PENDING = "DELETE_GUIDE_PENDING";
export const DELETE_GUIDE_FULFILLED = "DELETE_GUIDE_FULFILLED";
export const DELETE_GUIDE_REJECTED = "DELETE_GUIDE_REJECTED";

export const GET_STATES_GUIDE = "GET_STATES_GUIDE";
export const GET_STATES_GUIDE_PENDING = "GET_STATES_GUIDE_PENDING";
export const GET_STATES_GUIDE_FULFILLED = "GET_STATES_GUIDE_FULFILLED";
export const GET_STATES_GUIDE_REJECTED = "GET_STATES_GUIDE_REJECTED";

export const GET_RISKS_GUIDE = "GET_RISKS_GUIDE";
export const GET_RISKS_GUIDE_PENDING = "GET_RISKS_GUIDE_PENDING";
export const GET_RISKS_GUIDE_FULFILLED = "GET_RISKS_GUIDE_FULFILLED";
export const GET_RISKS_GUIDE_REJECTED = "GET_RISKS_GUIDE_REJECTED";

// Notifications

export const SET_EVENT_LISTENERS = "SET_EVENT_LISTENERS";

export const GET_EVENTS_BY_USER_ID = "GET_EVENTS_BY_USER_ID";
export const GET_EVENTS_BY_USER_ID_PENDING = "GET_EVENTS_BY_USER_ID_PENDING";
export const GET_EVENTS_BY_USER_ID_FULFILLED = "GET_EVENTS_BY_USER_ID_FULFILLED";
export const GET_EVENTS_BY_USER_ID_REJECTED = "GET_EVENTS_BY_USER_ID_REJECTED";

export const GET_NOTIFICATIONS_BY_USER_ID = "GET_NOTIFICATIONS_BY_USER_ID";
export const GET_NOTIFICATIONS_BY_USER_ID_PENDING = "GET_NOTIFICATIONS_BY_USER_ID_PENDING";
export const GET_NOTIFICATIONS_BY_USER_ID_FULFILLED = "GET_NOTIFICATIONS_BY_USER_ID_FULFILLED";
export const GET_NOTIFICATIONS_BY_USER_ID_REJECTED = "GET_NOTIFICATIONS_BY_USER_ID_REJECTED";

export const GET_NEW_NOTIFICATION = "GET_NEW_NOTIFICATION";
export const GET_NEW_NOTIFICATION_PENDING = "GET_NEW_NOTIFICATION_PENDING";
export const GET_NEW_NOTIFICATION_FULFILLED = "GET_NEW_NOTIFICATION_FULFILLED";
export const GET_NEW_NOTIFICATION_REJECTED = "GET_NEW_NOTIFICATION_REJECTED";

export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const READ_NOTIFICATION_PENDING = "READ_NOTIFICATION_PENDING";
export const READ_NOTIFICATION_FULFILLED = "READ_NOTIFICATION_FULFILLED";
export const READ_NOTIFICATION_REJECTED = "READ_NOTIFICATION_REJECTED";

export const UPDATE_USER_EVENTS = "UPDATE_USER_EVENTS";
export const UPDATE_USER_EVENTS_PENDING = "UPDATE_USER_EVENTS_PENDING";
export const UPDATE_USER_EVENTS_FULFILLED = "UPDATE_USER_EVENTS_FULFILLED";
export const UPDATE_USER_EVENTS_REJECTED = "UPDATE_USER_EVENTS_REJECTED";