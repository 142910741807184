import { createAsyncAction } from 'redux-promise-middleware-actions';
import { GET_RISKS_GUIDE } from '../actions/types';
import { exeptionCodeResponse, inspectionApiUrl, validateResponse } from '../utils/urls';

export const getRiskGuidesAPI = createAsyncAction(GET_RISKS_GUIDE, async (token = null) => {
  const headers = { Authorization: `Bearer ${token}` };
  const res = await fetch(`${inspectionApiUrl}/risk`, { headers })
    .then((response) => validateResponse(response))
    .catch((error) => {
      throw exeptionCodeResponse(error);
    });
    return res;
});
