import axios from 'axios';
import { inspectionApiUrl, eventsUrlApi } from '../utils/urls';

// receive mail object 
export const renewId = async (id) => {

  const { data } = await axios.get(`${inspectionApiUrl}/inspections/temporary/renew/${id}`);
  return data.id;
    
} 

export const sendEmail = async (mail) => {

  const response = await axios.post(`${eventsUrlApi}/notification/sendEmail`, mail);
  return response;
    
} 

export const addCommentToInspection = async (id, comment, email='', user) => {

  const data = {comment, user, email};
  const newComment = await axios.post(`${inspectionApiUrl}/comments/${id}`, data);
  return newComment;

}

export const getComments = (id) => {

  const comments = axios.get(`${inspectionApiUrl}/comments/${id}`);
  return comments;

}

export const setComment = (id, data) => {
  const comment = axios.post(`${inspectionApiUrl}/comments/temporary/${id}`, data);
  return comment;
}


export const getCommentsWithTemporaryId = (id) => {

  const comments = axios.get(`${inspectionApiUrl}/comments/temporary/${id}`);
  return comments;

}

export const updateTracking = async (id, msg, status, email) => {

  const obj = {
    msg,
    status,
    email
  }

  const toUpdateJson = JSON.stringify( obj );
  axios.put(`${inspectionApiUrl}/inspections/temporary/tracking/${id}`, toUpdateJson , {
    headers: {
      'Content-Type': 'application/json'
    }}).then(({ data })=>{
      console.log('data=>', data);
    });

};

export const updateTrackingWithId = async (id, msg, status) => {

  const obj = {
    msg,
    status
  }

  const toUpdateJson = JSON.stringify( obj );
  axios.put(`${inspectionApiUrl}/inspections/updateTracking/${id}`, toUpdateJson , {
    headers: {
      'Content-Type': 'application/json'
    }}).then(({ data })=>{
      console.log('data=>', data);
    });

};