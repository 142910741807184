import React, { useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";
import { useSelector } from "react-redux";
import axios from "axios";
import { useStyles } from "./styles";
import {
  Grid,
  Paper,
  Button,
  Typography,
  Tabs,
  Tab,
  Box,
  Breadcrumbs,
} from "@material-ui/core";
import Sticky from "react-sticky-el";
import { decodedToken, getParams } from "../../utils";
import { inspectionApiUrl } from "../../utils/urls";
import { getComments } from "../../sevices/";
import General from "../../components/inspection/general_tab";
import Photos from "../../components/inspection/photos_tab";
import Table from "../../components/inspection/Table";
import Utils from "../../utils/utils";
import { useGetBrands, useGetYears } from "../../hooks/inspections";
import { getModels, getVersionsByModel } from "../../sevices/inspectionService";
import { useGetGuide } from "../../hooks/guides";
import SummaryTab from "../../components/inspection/summaryTab";

const columnsComments = [
  { title: "Fecha", field: "date" },
  { title: "Comentario", field: "comment" },
  { title: "Email", field: "email" },
];

const columnsTracking = [
  { title: "Fecha", field: "date" },
  { title: "Descripcion", field: "msg" },
  { title: "Email", field: "email" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function formatedDateTracking(trackingArr) {
  const formatedDataTracking = trackingArr.map((tracking) => {
    return {
      ...tracking,
      date: Utils.dateToFormat(tracking.date, "DD/MM/YYYY h:mm"),
    };
  });
  return formatedDataTracking;
}

function formatDatesData(arrData) {
  const formatedDateData = arrData?.map((obj) => {
    const _date = new Date(obj.date);
    const newDate =
      _date.getDate().toString() +
      "/" +
      (_date.getMonth() + 1).toString() +
      "/" +
      _date.getFullYear().toString() +
      " " +
      _date.getHours().toString() +
      ":" +
      _date.getMinutes().toString();
    return { ...obj, date: newDate };
  });
  return formatedDateData;
}

const Details = (inspection) => {
  const classes = useStyles();
  const [valueTab, setValueTab] = useState(0);
  const [comments, setComments] = useState([]);
  const [edit, setEdit] = useState(false);
  const [brands] = useGetBrands();
  const [years] = useGetYears();
  const [models, setModels] = useState([]);
  const [versions, setVersions] = useState([]);
  const [data, setData] = useState({});
  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleOnEdit = () => {
    setEdit((edit) => !edit);
  };

  useEffect(() => {
    const params = getParams(window.location.href);
    axios
      .get(`${inspectionApiUrl}/inspections/details/${inspection.id}`)
      .then(({ data }) => {
        setData(data);

        for (const angle in data.photo) {
          const src = data.photo[angle].images[0];
          let img = document.createElement("img");
          img.src = src;
        }
      });
  }, []);

  useEffect(() => {
    let promiseGetCmmts = Promise.resolve(getComments(inspection.id));
    promiseGetCmmts.then(({ data }) => {
      const formatedDateData = formatDatesData(data);
      setComments(formatedDateData);
    });
  }, []);

  const callGetModels = async (brandPicked) => {
    if (brandPicked) {
      const newModels = await getModels(brandPicked);
      setModels(newModels);
      setVersions([]);
    } else {
      setModels([]);
    }
  };

  const callGetVersions = async (modelPicked) => {
    if (modelPicked) {
      const newVersions = await getVersionsByModel(modelPicked);
      setVersions(newVersions);
    } else {
      setVersions([]);
    }
  };

  useEffect(() => {
    callGetModels(data.brandId);
  }, [data.brandId]);

  useEffect(() => {
    callGetVersions(data.modelId);
  }, [data.brandId, data.modelId]);

  const objToken = decodedToken(useSelector((state) => state.auth.token));
  const [companyName] = useState(objToken ? objToken.user.companyName : "");

  const guideInfo = useGetGuide(objToken.user.companyId);

  let date;
  if (data?.date?.length) {
    date =
      Utils.dateToFormatUTC(data?.date[data?.date?.length - 1], "DD/MM/YYYY") ||
      "";
  }
  let expirationDate = "";
  if (data?.expirationDate?.length) {
    expirationDate =
      Utils.dateToFormatUTC(
        data.expirationDate[data.expirationDate.length - 1],
        "DD/MM/YYYY"
      ) || "";
  }
  return (
    <Grid item xs={12}>
      <Grid style={{ marginBottom: 16 }}>
        <Grid style={{ marginBottom: 16, padding: 16 }}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link to="/dashboard">Home</Link>
            <Link to="/inspections">Inspecciones</Link>
            <Typography color="textPrimary">
              Consulta de Inspecciones
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Paper style={{ padding: 16 }}>
        <Sticky
          hideOnBoundaryHit={true}
          stickyStyle={{ zIndex: 999, paddingBottom: 20 }}
        >
          <Paper
            style={{ marginBottom: 16, boxShadow: "none", paddingTop: 16 }}
          >
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Administración de inspecciones
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container justify="flex-end">
                  <Button
                    variant="contained"
                    style={{ marginRight: 16 }}
                    color="primary"
                    onClick={() => navigate("/inspections")}
                  >
                    Regresar
                  </Button>
                  {valueTab === 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: 16 }}
                      onClick={handleOnEdit}
                    >
                      Editar
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Sticky>
        <Tabs
          value={valueTab}
          onChange={handleChange}
          aria-label="inspections-tabs"
          className={classes.tabsOpcion}
        >
          <Tab
            label="General"
            id="inspections-tab-0"
            aria-controls="inspections-tabpanel-0"
          />
          <Tab
            label="Fotos"
            id="inspections-tab-1"
            aria-controls="inspections-tabpanel-1"
            // disabled={edit}
          />
          <Tab
            label="Comentarios"
            id="inspections-tab-1"
            aria-controls="inspections-tabpanel-2"
            // disabled={edit}
          />
          <Tab
            label="Tracking"
            id="inspections-tab-1"
            aria-controls="inspections-tabpanel-3"
            // disabled={edit}
          />
          <Tab
            label="Análisis"
            id="inspections-tab-1"
            aria-controls="inspections-tabpanel-4"
            // disabled={edit}
          />
        </Tabs>

        <TabPanel value={valueTab} index={0}>
          <General
            initialValues={{ ...data, date, expirationDate }}
            disabled={true}
            inspectionSelected={data}
            years={years}
            brands={brands}
            models={models}
            versions={versions}
            guideInfo={guideInfo.data}
            companyName={companyName}
            id={inspection.id}
            edit={edit}
            callGetModels={callGetModels}
            callGetVersions={callGetVersions}
            setEdit={setEdit}
          />
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <Photos data={data} />
        </TabPanel>
        <TabPanel value={valueTab} index={2}>
          <Table
            title="Comentarios"
            data={comments}
            columns={columnsComments}
          />
        </TabPanel>
        <TabPanel value={valueTab} index={3}>
          <Table
            title="Tracking"
            data={data?.tracking ? formatedDateTracking(data?.tracking) : []}
            columns={columnsTracking}
          />
        </TabPanel>
        <TabPanel value={valueTab} index={4}>
          <SummaryTab
            data={data}
            years={years}
            brands={brands}
            models={models}
          />
        </TabPanel>
      </Paper>
    </Grid>
  );
};

export default Details;
