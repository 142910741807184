import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    background: "#4c1db77a",
  },
  rootWholeDialog: {
    borderRadius: "15px",
  },
  bodyDialog: {
    padding: "15px 70px",
  },
  rootIcon: {
    color: "black",
  },
  rootTitle: {
    paddingTop: "40px",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "#141B6A",
    textAlign: "center",
  },
  rootDialAction: {
    height: "26px",
    width: "26px",
    minHeight: "26px",
    boxShadow: "unset",
    color: "black",
    background: "unset",
    "&hover": {
      background: "unset",
    },
  },
  addFab: {
    position: "fixed",
    bottom: 20,
    right: 20,
    zIndex: 900,
    "&:hover": {
      backgroundColor: "#006064 !important",
    },
  },
  toolbarmt: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      "& > div:nth-child(1)": {
        flexWrap: "wrap",
        justifyContent: "center",
        "& > *": {
          marginBottom: 10,
        },
      },
      justifyContent: "start",
      flexDirection: "column",
    },
  },
  muted: {
    color: "#979797",
    fontSize: "0.85em",
  },
  arrow: {
    color: "#141B6A",
  },
  tooltip: {
    backgroundColor: "#141B6A",
    color: "white",
    fontSize: "12px",
    fontWeight: "bold",
    padding: "10px",
  },
}));
