import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, List, ListItem, Typography } from '@material-ui/core'
import { NotificationsOutlined } from '@material-ui/icons'
import clsx from 'clsx'
import { format } from 'date-fns'

import DrawerDetailNotification from '../drawers/drawerDetailNotification'
import StyledBadges from '../styledBadges'
import { useStyles } from '../styles'

const NotificationList = ({ notifications, theme, onRead, contained }) => {
  const classes = useStyles({ theme })
  const [selectedMessage, setSelectedMessage] = useState({})

  const [openDrawer, setOpenDrawer] = useState(false)

  const handleReadMessage = async (noti) => {
    setSelectedMessage(noti)
    handleShowDrawer(true)
  }

  const handleRead = async (noti) => {
    if (!noti.read) {
      onRead(noti)
    }
  }

  const handleShowDrawer = (opt) => setOpenDrawer(opt)

  return (
    <Fragment>
      <List className={classes.list}>
        {notifications.map((noti, i) => (
          <ListItem
            onClick={() => {
              handleReadMessage(noti)
            }}
            button
            className={clsx(classes.notificationListItem, {
              [classes.unread]: !noti.read
            })}
            key={`notification__item__${i}`}
          >
            <Grid item container alignItems='center' style={{ flex: 1 }}>
              {!noti.read ? (
                <StyledBadges
                  variant='dot'
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  overlap='circle'
                  color='secondary'
                  theme={theme}
                >
                  <NotificationsOutlined style={{ color: '#333333' }} />
                </StyledBadges>
              ) : (
                <NotificationsOutlined style={{ color: '#828282' }} />
              )}
              <Typography
                className={clsx({
                  [classes.truncateText]: contained
                })}
                component='span'
                style={{
                  marginLeft: 20,
                  fontWeight: noti.read ? 400 : 600,
                  letterSpacing: 0.15,
                  fontSize: 16
                }}
              >
                {noti.message.title}
              </Typography>
            </Grid>
            {!contained && (
              <Grid
                item
                className={classes.notificationListItemSecondary}
                style={{
                  color: noti.read ? '#C4C4C4' : '#000'
                }}
              >
                <Typography className='hora'>
                  {format(new Date(noti.sentAt), 'hh:mm a')}
                </Typography>
                <Typography className='fecha'>
                  {format(new Date(noti.sentAt), 'dd/MM/yyyy')}
                </Typography>
              </Grid>
            )}
          </ListItem>
        ))}
      </List>

      <DrawerDetailNotification
        open={openDrawer}
        close={() => handleShowDrawer(false)}
        notification={selectedMessage}
        handleRead={handleRead}
        theme={theme}
      />
    </Fragment>
  )
}

NotificationList.propTypes = {
  notifications: PropTypes.array.isRequired,
  theme: PropTypes.object,
  onRead: PropTypes.func.isRequired,
  contained: PropTypes.bool
}

export default NotificationList
