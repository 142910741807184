import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import { DeleteOutline, ImportExport, Edit } from "@material-ui/icons";
import {
  sortableElement,
  sortableContainer,
  sortableHandle,
} from "react-sortable-hoc";
import { FieldArray } from "redux-form";

const SortableItem = sortableElement(
  ({ value, handleDelete, handleEdit, groupId, position }) => {
    const disabled = !handleDelete && !handleEdit;
    const DragHandle = sortableHandle(() => (
      <IconButton aria-label="sort">
        <ImportExport />
      </IconButton>
    ));

    return (
      <Grid container>
        <Grid item style={{ flex: 1 }}>
          <Grid item container justify="space-between">
            <p>{value.name}</p>
            <p>{value.description}</p>
            <div></div>
          </Grid>
        </Grid>
        {!disabled && (
          <Grid item>
            <IconButton
              onClick={() =>
                handleEdit({
                  ...value,
                  index: groupId,
                  position,
                  type: "photo",
                })
              }
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() =>
                handleDelete({
                  ...value,
                  index: groupId,
                  position,
                  type: "photo",
                })
              }
            >
              <DeleteOutline />
            </IconButton>
            {!disabled ? <DragHandle /> : null}
          </Grid>
        )}
      </Grid>
    );
  }
);

const SortableList = sortableContainer(({ children }) => {
  return <ul>{children} </ul>;
});

const renderGuidesSort = ({ fields, ...rest }) => {
  const dependencyFields = fields.getAll();
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      fields.swap(oldIndex, newIndex);
    }
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <SortableList onSortEnd={onSortEnd} useDragHandle>
        {dependencyFields.map((field, ind) => {
          return (
            <SortableItem
              key={`field-${ind}`}
              index={ind}
              position={ind}
              value={field}
              groupId={rest.groupId}
              handleEdit={rest.handleEdit}
              handleDelete={rest.handleDelete}
            />
          );
        })}
      </SortableList>
    </div>
  );
};

const SortablePanel = ({ index, handleDelete, handleEdit }) => {
  return (
    <FieldArray
      name={`photos[${index}].related`}
      component={renderGuidesSort}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      groupId={index}
    />
  );
};

export default SortablePanel;
