import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import DataTab from '../../components/videchat/Tabs/dataChat';
import CommentsTab from '../../components/videchat/Tabs/commentsPanel';
import DamageEstimationTab from '../../components/tabs/damageEstimationTab';
import { useStyles } from "../inspection/styles";

const InspectorUI = ({ tempId }) => {
  const classes = useStyles();
  const [valueTab, setValueTab] = useState(0);
  const aIData = useSelector((state) => state.ai.data);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        <div style={{ display: ((value !== index) && 'none') }}>
          <Box p={3}>
            <Typography component="div">{children}</Typography>
          </Box>
        </div>
      </div>
    );
  } 

  return (
    <>
      <Tabs
        value={valueTab}
        onChange={handleChange}
        aria-label="inspections-tabs"
        className={classes.tabsOpcion}
      >
        <Tab
          label="General"
          id="inspections-tab-0"
        />
        <Tab
          label="Reporte"
          id="inspections-tab-1"
        />
        <Tab
          label="API"
          id="inspections-tab-1"
        />
      </Tabs>
      <TabPanel value={valueTab} index={0}>
        <div style={{maxWidth: '500px'}}>
          <DataTab room={tempId} />
        </div>
      </TabPanel>
      <TabPanel value={valueTab} index={1}>
        <div style={{maxWidth: '500px'}}> 
          <DamageEstimationTab showTab={valueTab === 1} />
        </div>
      </TabPanel>
      <TabPanel value={valueTab} index={2}>
        <div style={{maxWidth: '500px'}}>
          {
            aIData.length === 0 &&
            <div>
              <h3>Por favor, cargue fotos en la seccion izquierda para ver las respuestas correspondientes</h3>
            </div>
          }
          { 
            aIData.length > 0 &&
            aIData.map(
              ({aIData, title}) => {
                if ( aIData && title ) {
                  return <div>
                          <h3 style={{ fontSize: '12px' }}>{title}</h3>
                          <p style={{ fontSize: '10px' }}>
                            {
                              typeof aIData === 'object' 
                              ? JSON.stringify(aIData)
                              : JSON.parse(aIData).map(item=><pre>{JSON.stringify(item,null,2)}</pre>)
                            }
                          </p>
                         </div>
                }
                return <div><h3 style={{ fontSize: '12px' }}>Error</h3><p style={{ fontSize: '10px' }}>Hubo un problema, intente de nuevo</p></div>
              }
            )
          }
        </div>
      </TabPanel>
    </>
  )
};

export default InspectorUI;