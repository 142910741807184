import React, { useState } from 'react';
import { Apps } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { setLabMode } from "../../actions/appDataActions";
import { Modal } from "@material-ui/core";
import { useStyles } from './styles';

const LabModeModal = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState();

  const onClose = () => { setOpen(false) }

  const handleClick = status => {
    dispatch(setLabMode(status));
    onClose();
  }

  return (
    <div className={classes.container}>
      <Apps 
        style={{ color: '#828282' }} 
        onClick={() => setOpen(true)} 
      />
      <Modal
        className={classes.modal}
        open={open}
        onClose={onClose}
      >
        <div className={classes.paper}>
          <header className={classes.header}>
            <p className={classes.title}>Modo Laboratorio</p>
          </header>
          <p>Desea activar las funcionalidades de laboratorio?</p>
          <footer className={classes.footer}>
            <button 
              className={classes.button}
              onClick={() => handleClick(true)}>
                Si</button>
            <button className={classes.button} onClick={() => handleClick(false)}>No</button>
          </footer>
        </div>
      </Modal>
    </div>
  )
}

export default LabModeModal;