import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import { inspectionUrlBase } from '../../utils/urls';
import { useSelector, useDispatch } from 'react-redux';
import CameraView from '../../components/videchat/cameraView';
import MiniCameraView from '../../components/videchat/miniCameraView';
import CallOptionsFooter from '../../components/videchat/callOptionsFooter';
import SnapShotPanel from '../../components/videchat/Tabs/snapShotPanel';
import CommentsTab from '../../components/videchat/Tabs/commentsPanel';
import DataChat from '../../components/videchat/Tabs/dataChat'
import { Tabs, Tab, Grid, AppBar } from '@material-ui/core';
import socketIOClient from "socket.io-client";
import { closeStream, startStream } from "../../actions/streamActions";
import { updateTracking } from '../../sevices'
import { setCallStream, setLocalStream, setCallEnd, setCallStart } from '../../actions/videoCallActions'

const VideoChatPage = () => {
  const dispatch = useDispatch();
  const { isCallActive, localStreamRed, remoteStreamRed } = useSelector(state => state.videocall);
  const localVideo = useRef(null);
  const remoteVideo = useRef(null);
  const [localStreaming, setLocalStreaming] = useState(null)
  const [isRemoteJoined, setIsRemoteJoined] = useState(false)
  const [callStatus, setCallStatus] = useState('');
  const [socket, setSocket] = useState(socketIOClient(inspectionUrlBase, { autoConnect: false }));

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [room] = useState(urlParams.get('room'))

  const [peerConnection, setPeerConnection] = useState(null);
  const [valueTab, setValueTab] = useState(2);
  //Defining some global utility variables
  let isChannelReady = false;
  let isInitiator = false;
  let isStarted = false;
  let localStream;
  let pc;
  let remoteStream;
  let localStreamConstraints = {
    audio: true,
    video: {
      width: {
        ideal: 640
      },
      height:{
        ideal: 400
      }
    }
  };

  //Function to send message in a room
  const sendMessage = (message) => {
    console.log('Client sending message: ', message, room);
    socket.emit('message', message, room);
  }

  const reCall = (message) => {
    console.log('Client sending message: ', message, room);
    socket.emit('message', message, room);
  }

  //If initiator, create the peer connection
  const maybeStart = () => {
    console.log('iS', isStarted);
    console.log('lS', localStream);
    console.log('iCR', isChannelReady);
    if (!isStarted && typeof localStream !== 'undefined' && isChannelReady) {
      createPeerConnection();
      pc.addStream(localStream);
      isStarted = true;
      if (isInitiator) {
        console.log('IMMA INITIATOR FROM START', isInitiator);
        doCall();
      }
    }
  }

  const createPeerConnection = () => {

    var pcConfig = {
      iceServers: [
        {
          urls: ["stun:sp-turn2.xirsys.com"]
        },
        {
          username: "tJzkFw5wHTMf0MLnOn5KbvaSUEsJdxM-nqIRQRxh6mWntl1NI_Oiiq-jSU0fVZW7AAAAAGBjpBREc0VzdGViYW4=",
          credential: "69c9d810-91a6-11eb-b67c-0242ac120004",
          urls: ["turn:sp-turn2.xirsys.com:80?transport=tcp"]
        }
      ]
    };

    // ,"turn:bn-turn1.xirsys.com:80?transport=tcp","turn:bn-turn1.xirsys.com:3478?transport=tcp","turns:bn-turn1.xirsys.com:443?transport=tcp","turns:bn-turn1.xirsys.com:5349?transport=tcp"

    try {
      pc = new RTCPeerConnection(pcConfig);
      pc.onicecandidate = handleIceCandidate;
      pc.onaddstream = handleRemoteStreamAdded;
      pc.onremovestream = handleRemoteStreamRemoved;
      pc.oniceconnectionstatechange = ev => {
        setCallStatus(pc.iceConnectionState);
      }
      let peerConnection = pc;
      setPeerConnection(peerConnection);
      console.log('Created RTCPeerConnnection');
    } catch (e) {
      console.log('Failed to create PeerConnection, exception: ' + e.message);
      alert('Cannot create RTCPeerConnection object.');
      return;
    }
  }

  const handleIceCandidate = (event) => {
    // console.log('icecandidate event: ', event);
    if (event.candidate) {
      sendMessage({
        type: 'candidate',
        label: event.candidate.sdpMLineIndex,
        id: event.candidate.sdpMid,
        candidate: event.candidate.candidate
      }, room);
    } else {
      console.log('End of candidates.');
    }
  }

  const handleCreateOfferError = (event) => {
    console.log('createOffer() error: ', event);
  }

  const doCall = () => {
    console.log('Sending offer to peer');
    pc.createOffer(setLocalAndSendMessage, handleCreateOfferError);
  }

  const doAnswer = () => {
    console.log('Sending answer to peer.');
    pc.createAnswer().then(
      setLocalAndSendMessage,
      onCreateSessionDescriptionError
    );
  }

  const setLocalAndSendMessage = (sessionDescription) => {
    pc.setLocalDescription(sessionDescription);
    console.log('setLocalAndSendMessage sending message', sessionDescription);
    sendMessage(sessionDescription, room);
  }

  const onCreateSessionDescriptionError = (error) => {
    console.log('Failed to create session description: ' + error.toString());
  }

  const handleRemoteStreamAdded = (event) => {
    console.log('Remote stream added.');
    remoteStream = event.stream;
    remoteVideo.current.srcObject = remoteStream;
    // aqui va el video remoto 
    dispatch(setCallStream(remoteStream))
    setIsRemoteJoined(true);
  }

  const handleRemoteStreamRemoved = (event) => {
    console.log('Remote stream removed. Event: ', event);
  }

  const hangup = () => {
    console.log('Session terminated hangup.', localStreaming);
    if (localStreaming) {
      localStreaming.getTracks()[0].stop();
      localStreaming.getTracks()[1].stop();
      const videoTrack = localStreaming.getTracks().filter(
        track => track.kind === 'video'
      )
      console.log('videoTrack=>', videoTrack)
      if (videoTrack) videoTrack[0].stop();
    }
    stop();
    sendMessage('bye', room);
    socket.emit('bye', room);
    dispatch(closeStream());
    dispatch(setCallEnd());
    localVideo.current.pause();
    updateTracking(room, 'BO inspection: Videollamada terminada', 0);
  }

  const handleRemoteHangup = () => {
    console.log('Session terminated.', localStream, localVideo.current.srcObject.getTracks());
    if (localVideo.current.srcObject) {
      localVideo.current.srcObject.getTracks()[0].stop();
      localVideo.current.srcObject.getTracks()[1].stop();
      const videoTrack = localVideo.current.srcObject.getTracks().filter(
        track => track.kind === 'video'
      )
      console.log('videoTrack=>', videoTrack)
      if (videoTrack) videoTrack[0].stop();
    }
    stop();
    dispatch(closeStream());
    dispatch(setCallEnd());
  }

  const stop = () => {
    isStarted = false;
    if (socket) {
      console.log('Closing the socket!');
      socket.close();
    }
    if (peerConnection) {
      peerConnection.close();
      setPeerConnection(null);
    }
    if (pc) {
      pc.close();
      pc = null;
    }

    if (isInitiator) {
      isInitiator = false;
    }
  }

  const gotStream = (stream) => {
    localStream = stream;
    console.log('update stream', stream)
    setLocalStreaming(localStream);
    dispatch(startStream(room));
    dispatch(setLocalStream(stream))
    localVideo.current.srcObject = stream;
    sendMessage('got user media', room);
    if (isInitiator) {
      console.log('IMMA INITIATOR', isInitiator);
      maybeStart();
    }
  }

  useEffect(() => {

    if (!isCallActive) {
      console.log('Gonna open the socket!');
      socket.open();
    }
    // const getServers = async () => {

    //   // let xhr = new XMLHttpRequest();
    //   // xhr.onreadystatechange = function($evt){
    //   //   if(xhr.readyState == 4 && xhr.status == 200){
    //   //     let res = JSON.parse(xhr.responseText);
    //   //     console.log('response server: ', res);
    //   //     return res;
    //   //   }
    //   // }
    //   // xhr.open("PUT", "https://global.xirsys.net/_turn/MyFirstApp", false);
    //   // xhr.setRequestHeader ("Authorization", "Basic " + btoa("DsEsteban:9c7a6a24-8fdd-11eb-9055-0242ac150002") );
    //   // xhr.setRequestHeader ("Content-Type", "application/json");
    //   // xhr.send( JSON.stringify({"format": "urls"}) );

    //   await axios.put("https://global.xirsys.net/_turn/MyFirstApp", {}, {
    //     headers: {
    //       'Authorization': `Basic ${btoa("DsEsteban:9c7a6a24-8fdd-11eb-9055-0242ac150002")}` 
    //     }
    //   }).then(
    //     ({ data }) => console.log('res servers=>', data)
    //   ).catch(e => console.log('e=>', e))

    // }

    //   const servers = getServers();
    //   console.log('servers=>', servers)
    //   console.log('veamos=>')


    if (isCallActive) {
      console.log('localStreamRed=>', localStreamRed, remoteStreamRed)
      localVideo.current.srcObject = localStreamRed;
      remoteVideo.current.srcObject = remoteStreamRed;
      return;
    }

    socket.on("connect", () => {
      console.log('Is connected inside didMount?', socket.connected); // true
      if (socket.connected) {
        console.log('I must listen!');
        socket.on('message', function (message, room) {
          console.log('Client received message:', message, room);
          console.log('Is call started?', isStarted);
          if (message === 'got user media') {
            maybeStart();
          } else if (message.type === 'offer') {
            if (!isInitiator && !isStarted) {
              maybeStart();
            }
            pc.setRemoteDescription(new RTCSessionDescription(message));
            doAnswer();
          } else if (message.type === 'answer' && isStarted) {
            pc.setRemoteDescription(new RTCSessionDescription(message));
          } else if (message.type === 'candidate' && isStarted) {
            var candidate = new RTCIceCandidate({
              sdpMLineIndex: message.label,
              candidate: message.candidate
            });
            pc.addIceCandidate(candidate);
          } else if (message === 'bye' && isStarted) {
            handleRemoteHangup();
          }
        });
      }
    });

    if (room !== '') {
      socket.emit('create or join', room);
      console.log('Attempted to create or  join room', room);
    }

    socket.on('created', function (room) {
      console.log('Created room ' + room);
      isInitiator = true;
    });

    socket.on('join', function (room) {
      console.log('Another peer made a request to join room ' + room);
      console.log('This peer is the initiator of room ' + room + '!');
      isChannelReady = true;
    });

    socket.on('joined', function (room) {
      console.log('joined: ' + room);
      isChannelReady = true;
    });

    dispatch(setCallStart());

    navigator.mediaDevices.getUserMedia(localStreamConstraints)
      .then(gotStream)
      .catch(function (e) {
        console.log('e=>', e)
        alert('getUserMedia() error: ' + e.name);
      });

    // return function cleanup() {
    //   if (!isCallActive) {
    //       localStream.getTracks().forEach(function(track) {
    //           track.stop();
    //       })
    //   }
    // }

  }, []);

  /* useEffect(() => {
    console.log('Socket is connected?', socket.connected);
  }, [socket.connected]); */

  /* if (socket.connected) {
    console.log('I must listen!');
    socket.on('message', function (message, room) {
      console.log('Client received message:', message, room);
      console.log('Is call started?', isStarted);
      if (message === 'got user media') {
        maybeStart();
      } else if (message.type === 'offer') {
        if (!isInitiator && !isStarted) {
          maybeStart();
        }
        pc.setRemoteDescription(new RTCSessionDescription(message));
        doAnswer();
      } else if (message.type === 'answer' && isStarted) {
        pc.setRemoteDescription(new RTCSessionDescription(message));
      } else if (message.type === 'candidate' && isStarted) {
        var candidate = new RTCIceCandidate({
          sdpMLineIndex: message.label,
          candidate: message.candidate
        });
        pc.addIceCandidate(candidate);
      } else if (message === 'bye' && isStarted) {
        handleRemoteHangup();
      }
    });
  } */

  useEffect(() => {
    if (localStreaming) {
      return () => {
        hangup();
      }
    }
  }, [localStreaming]);

  const { enableAudioStream } = useSelector((state) => state.videocall);

  useEffect(() => {
    const audioTrack = localStreaming?.getTracks().filter(
      track => track.kind === 'audio'
    )
    if (audioTrack) {
      audioTrack[0].enabled = enableAudioStream;
    }
  }, [enableAudioStream])


  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <div id="video_container" className="align-items-center" style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {
              (!isRemoteJoined && !remoteStreamRed) &&
              <div style={{ position: 'absolute' }}>
                <p>Esperando que alguien se una...</p>
              </div>
            }
            {
              (callStatus.toLowerCase() === 'disconnected' || callStatus.toLowerCase() === 'disconnected') &&
              <div style={{ position: 'absolute', backgroundColor: 'lightgray', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p>El asegurado ha terminado la llamada</p>
              </div>
            }
            <CameraView remoteVideo={remoteVideo} />

          </div>
          <div>
            <CallOptionsFooter
              localVideo={localVideo}
              hangup={hangup}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ backgroundColor: 'white', height: '80%', padding: 10, borderRadius: '15px' }}>
            <Tabs
              value={valueTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChangeTabs}
              aria-label="disabled tabs example"
            >
              <Tab label="DATOS" />
              <Tab label="COMENTARIOS" />
              <Tab label="FOTOS" />
            </Tabs>
            <section style={{ backgroundColor: 'white' }}>
              <DataChat showTab={valueTab === 0} />
              <CommentsTab showTab={valueTab === 1} id={room} />
              <SnapShotPanel remoteVideo={remoteVideo} showTab={valueTab === 2} idVideo={room} />
            </section>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default VideoChatPage;