export const WORDING_STATES = new Map([
  ['pendiente', {infinitive: 'Pendiente', past_participle: 'Pendiente'}],
  ['rehacer', {infinitive: 'Rehacer', past_participle: 'Rehacer'}],
  ['cancelar', {infinitive: 'Cancelar', past_participle: 'Cancelado'}],
  ['rechazar', {infinitive: 'Rechazar', past_participle: 'Rechazado'}],
  ['aprobar', {infinitive: 'Aprobar', past_participle: 'Aprobado'}],
  ['pendienteApro', {infinitive: 'Pend. por Aprobar', past_participle: 'Pend. por Aprobar'}],
  ['0', {infinitive: 'Pendiente', past_participle: 'Pendiente'}],
  ['2', {infinitive: 'Rehacer', past_participle: 'Rehacer'}],
  ['1', {infinitive: 'Cancelar', past_participle: 'Cancelado'}],
  ['3', {infinitive: 'Rechazar', past_participle: 'Rechazado'}],
  ['4', {infinitive: 'Aprobar', past_participle: 'Aprobado'}],
  ['5', {infinitive: 'Pend. por Aprobar', past_participle: 'Pend. Aprobar'}],
]);

export const MAP_TYPE_PHOTO_HEADER = [
  'Frontal lateral izquierdo', 
  'Frontal', 
  'Frontal lateral derecho', 

  'Lateral izquierda superior',
  'Lateral derecha superior',
  'Lateral izquierda inferior', 

  'Lateral derecha inferior',
  'Trasera lateral izquierda',
  'Trasera', 

  'Trasera lateral derecha',
  'Tablero', 
  'Frente carnet', 

  'Reverso carnet'
];

export const MAP_TYPE_PHOTO_HEADER_10 = [
  'Frontal', 
  'Frontal lateral izquierdo', 
  'Frontal lateral derecho', 

  'Lateral izquierda superior',
  'Lateral derecha superior',

  'Lateral izquierda inferior', 
  'Lateral derecha inferior',

  'Trasera lateral izquierda',
  'Trasera lateral derecha',
  'Trasera'

];



export const MAP_TYPE_PHOTO_HEADER_8 = [
  'Frontal lateral izquierdo', 
  'Frontal', 
  'Frontal lateral derecho', 

  'Lateral izquierda superior',
  'Lateral derecha superior',
  
  'Trasera lateral izquierda',
  'Trasera', 

  'Trasera lateral derecha',

];

const MAP_TYPE_PHOTO = [
  'side_frontal_left', 
  'frontal', 
  'side_frontal_right', 
  'side_left_top',
  'side_right_top', 
  'side_left_lower', 
  'side_right_lower', 
  'side_back_left',
  'back', 
  'side_back_right',
  'dashboard', 
  'front_card', 
  'back_card'
];

