import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  addFab: {
    position: "fixed",
    bottom: 20,
    right: 20,
    zIndex: 900,
    "&:hover": {
      backgroundColor: "#006064 !important",
    },
  },
  toolbarmt: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      "& > div:nth-child(1)": {
        flexWrap: "wrap",
        justifyContent: "center",
        "& > *": {
          marginBottom: 10,
        },
      },
      justifyContent: "start",
      flexDirection: "column",
    },
  },
  container: {
    display: "flex",
  },
  btnAna: {
    border: 'none',
    background: '#bc9dfc',
    color: 'white',
    borderRadius: '5px',
    padding: '10px 30px',
    cursor: 'pointer'
  },
  btnAnaDisab: {
    border: 'none',
    background: 'gray',
    color: 'white',
    borderRadius: '5px',
    padding: '10px 30px',
  }
}));
