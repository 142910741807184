import { createAsyncAction } from 'redux-promise-middleware-actions';
import { showError, showSuccess } from '../actions/messagesActions';
import { decodedToken } from '../utils';
import { ADD_GUIDE, GET_GUIDES, DELETE_GUIDE, UPDATE_GUIDE } from '../actions/types';
import { exeptionCodeResponse, inspectionApiUrl, validateResponse } from '../utils/urls';

export const getGuidesAPI = createAsyncAction(GET_GUIDES, async (
  token = null, { page = 0, pageSize = 5 } = {}
) => {
  
  const headers = { Authorization: `Bearer ${token}` };

  try {
    const res = await fetch(`${inspectionApiUrl}/guides/company/${decodedToken(token).user.companyId}/?page=${page}&size=${pageSize}`, { headers })
      .then((response) => validateResponse(response))
      .catch((error) => {
        throw exeptionCodeResponse(error);
      });
    return res;

  } catch (e) {
    console.log('fallo el request de guidesApi por:', e);
    return {docs: []} 
  }
});

export const addGuideAPI = createAsyncAction( ADD_GUIDE, async (token, bodyReq, dispatch) => {
  const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
  const body = JSON.stringify(bodyReq);
  const res = await fetch(`${inspectionApiUrl}/guides`, { method: 'POST', headers, body })
      .then((response) => {
          dispatch(showSuccess("Guía creada"));
          return validateResponse(response);
      }).catch((error) => {
          
          dispatch(showError(error))
          throw exeptionCodeResponse(error);
      });
  return res;
});

export const deleteGuideAPI = createAsyncAction(
  DELETE_GUIDE,
  async (token, guideId, dispatch) => {
      const headers = { Authorization: `Bearer ${token}` };
      const res = await fetch(`${inspectionApiUrl}/guides/${guideId}`, { method: 'DELETE', headers })
          .then((response) => {
              dispatch(showSuccess('Guía eliminada'));
              return ({ status: validateResponse(response), id: guideId });
          })
          .catch((error) => {
              throw exeptionCodeResponse(error);
          });
      return res;
  }
);

export const updateGuideAPI = createAsyncAction(
  UPDATE_GUIDE,
  async (token, bodyReq, dispatch) => {
      const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
      const body = JSON.stringify(bodyReq);
      const res = await fetch(`${inspectionApiUrl}/guides`, { method: 'PUT', headers, body })
          .then((response) => {
              dispatch(showSuccess("Guía actualizada"));
              return validateResponse(response);
          }).catch((error) => {
              dispatch(showError('Ya existe mismo un tipo de guia por defecto activo'));
              throw exeptionCodeResponse(error);
          });
      return res;
  }
);
