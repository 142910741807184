import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import DinamicFilterButtons from './dinamicFilterButtons';
import { makeStyles } from '@material-ui/core/styles';
import { setGlobalFilter } from '../../../actions/filterListActions';

// import { setGlobalFilter } from '../actions/filterListActions';

const uStyles = makeStyles((theme) => ({
  root: {
    minWidth: '150px'
  },
}));

const FilterButtons = () => {
  
  const dispatch = useDispatch();
  const globalFilter = useSelector((state) => state.filter.globalFilter);

  const handleChange = ({ target }) => {
    dispatch(setGlobalFilter(target.value))
  };  

  const classes = uStyles();
  
  return (
    <div style={{paddingLeft:15, display:'flex', alignItems: 'center'}}>
      <FormControl>
        <Select
          disableUnderline
          id="select-filter-table"
          classes={{
            root: classes.root
          }}
          value={globalFilter}
          onChange={handleChange}
        >
          <MenuItem value={0} style={{display:'none'}}>Ordenar Por</MenuItem>
          <MenuItem value={'inspectionId'}>id</MenuItem>
          <MenuItem value={'date'}>Fecha</MenuItem>
          <MenuItem value={'expirationDate'}>Expiracion</MenuItem>
        </Select>
      </FormControl>
      <DinamicFilterButtons />
    </div>
  )
}

export default FilterButtons;