
export const urlBase = (window._env_ ? window._env_.REACT_APP_COTIZADOR_API_URL : false) || process.env.REACT_APP_COTIZADOR_API_URL;
export const authUrlBase = (window._env_ ? window._env_.REACT_APP_AUTH_API_URL : false) || process.env.REACT_APP_AUTH_API_URL;
//export const authUrlBase = 'http://localhost:8090/'
export const authUrlApi = `${authUrlBase}v1`;
export const eventsUrlBase = (window._env_ ? window._env_.REACT_APP_EVENTS_API_URL : false) || process.env.REACT_APP_EVENTS_API_URL;
export const eventsUrlApi = `${eventsUrlBase}v1`;
export const inspectionAppUrl = (window._env_ ? window._env_.REACT_APP_INSPECTION_APP_URL : false) || process.env.REACT_APP_INSPECTION_APP_URL;
//export const inspectionUrlBase = 'https://localhost:8087/'
export const inspectionUrlBase =  (window._env_ ? window._env_.REACT_APP_INSPECTION_API_URL : false) || process.env.REACT_APP_INSPECTION_API_URL;
export const inspectionApiUrl = `${inspectionUrlBase}v1`;
// export const quotationUrlBase = 'http://localhost:8088/'
export const quotationUrlBase = (window._env_ ? window._env_.REACT_APP_COTIZADOR_API_URL : false) || process.env.REACT_APP_COTIZADOR_API_URL;
export const quotationApiUrl = `${quotationUrlBase}v1`;

export const inspectorUrlBase = (window._env_ ? window._env_.REACT_APP_INSPECTOR_API_URL : false) || 'https://test-detectorapi.iconnectance.com/predictions/detections';
export const inspectorApiUrl = `${inspectorUrlBase}`;
// 'https://test-inspectorapi.iconnectance.com/api/'
 
export const inspectorAIUrlBase = (window._env_ ? window._env_.REACT_APP_INSPECTOR_API_URL : false) || process.env.REACT_APP_INSPECTOR_AI_API;
export const inspectorAIApiUrl = `${inspectorAIUrlBase}`;

export const streamUrl = (window._env_ ? window._env_.REACT_APP_STREAM_URL : false) || process.env.REACT_APP_STREAM_URL;

export const validateResponse = (response) => {

	if (response.ok) {
		return response.json();
	} else {
		switch (response.status.toString()) {
			case '404':
				return response.json();
				break;
			case 'NO_DEFAULT_GUIDE':
				return response.json();
				break;
		
			default:
				console.log('unregistered response=>', response);
				break;
		}
		throw exeptionCodeResponse();
	}
};

export const validateJSONResponse = (response) => {
  if (response.ok) {
    return response.json();
  } else {
    throw exeptionCodeResponse();
  }
};

export const exeptionCodeResponse = (error = "error") => {
	return error;
};
