const initialState = {
  notifications: [],
  notificationTypes: [],
  frequency: [],
  eventsNames: []
};

export default function (state = initialState, action) {
  
	switch (action.type) {
		case 'SET_NOTIFICATIONS':
			return {
				...state,
				notifications: [...action.payload]
      }; 
      
    case 'SET_EVENTS_NAMES':
      return {
        ...state,
        eventsNames: [...action.payload]
      }; 
      
		default:
			return state;
	}
}