import React, { Fragment } from "react";
import axios from "axios";
import { Button, Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { inspectionApiUrl } from "../../utils/urls";
import { makeStyles } from "@material-ui/core/styles";
import { Field, change, reduxForm } from "redux-form";
import { renderTextField, renderSelectField } from "../form/renderInputsForm";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    margin: 10,
  },
}));

const General = ({
  brands,
  models,
  years,
  versions,
  companyName,
  guideInfo,
  inspectionSelected,
  id,
  edit,
  callGetModels,
  callGetVersions,
  setEdit,
  handleSubmit,
}) => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const submit = async (values) => {
    const inspectionToEdit = {
      ...values,
      date: inspectionSelected.date,
      expirationDate: inspectionSelected.expirationDate,
    };

    const sendData = Object.fromEntries(
      Object.entries(inspectionToEdit).filter(([_, v]) => v != null)
    );

    axios
      .put(`${inspectionApiUrl}/inspections/details/${id}`, {
        ...sendData,
        id,
      })
      .then((response) => {
        setEdit((edit) => !edit);
      });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container direction="row" spacing={3}>
        {inspectionSelected.name ? (
          <Fragment>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <Field
                  classes={{
                    root: classes.root,
                  }}
                  name="inspectionId"
                  label="ID"
                  custom={{ tabIndex: 1 }}
                  component={renderTextField}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  classes={{
                    root: classes.root,
                  }}
                  name="phone"
                  label="Telefono"
                  custom={{ tabIndex: 2 }}
                  component={renderTextField}
                  disabled={!edit}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  classes={{
                    root: classes.root,
                  }}
                  name="email"
                  label="Email"
                  custom={{ tabIndex: 3 }}
                  component={renderTextField}
                  disabled={!edit}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  classes={{
                    root: classes.root,
                  }}
                  name="modelId"
                  label="Modelo"
                  component={renderSelectField}
                  custom={{ tabIndex: 4 }}
                  disabled={!edit}
                  onChange={(event) => {
                    callGetVersions(event.target.value);
                    dispatch(change("General", "modelId", event.target.value));
                  }}
                >
                  {models
                    ? models.map((value) => {
                        return (
                          <MenuItem key={value._id} value={value._id}>
                            {value.name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Field>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <Field
                  classes={{
                    root: classes.root,
                  }}
                  label="Nombre"
                  name="name"
                  custom={{ tabIndex: 3 }}
                  component={renderTextField}
                  disabled={!edit}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  classes={{
                    root: classes.root,
                  }}
                  label="Fecha y hora"
                  name="date"
                  custom={{ tabIndex: 3 }}
                  component={renderTextField}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  classes={{
                    root: classes.root,
                  }}
                  name="year"
                  label="Año"
                  component={renderSelectField}
                  custom={{ tabIndex: 4 }}
                  disabled={!edit}
                >
                  {years
                    ? years.map((value, index) => {
                        return (
                          <MenuItem key={index} id={index} value={value.value}>
                            {value.value}
                          </MenuItem>
                        );
                      })
                    : null}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Field
                  classes={{
                    root: classes.root,
                  }}
                  name="versionId"
                  label="Version"
                  component={renderSelectField}
                  custom={{ tabIndex: 4 }}
                  disabled={!edit}
                >
                  {versions
                    ? versions.map((value) => {
                        return (
                          <MenuItem key={value._id} value={value._id}>
                            {value.name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Field>
              </Grid>
            </Grid>

            <Grid item xs={4}>
              <Grid item xs={12}>
                <Field
                  classes={{
                    root: classes.root,
                  }}
                  name="lastname"
                  label="Apellido"
                  custom={{ tabIndex: 3 }}
                  component={renderTextField}
                  disabled={!edit}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  classes={{
                    root: classes.root,
                  }}
                  label="Fecha de expiracion"
                  name="expirationDate"
                  custom={{ tabIndex: 3 }}
                  component={renderTextField}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  classes={{
                    root: classes.root,
                  }}
                  name="brandId"
                  label="Marca"
                  component={renderSelectField}
                  custom={{ tabIndex: 4 }}
                  disabled={!edit}
                  onChange={(event) => {
                    dispatch(change("General", "brandId", event.target.value));
                    callGetModels(event.target.value);
                  }}
                >
                  {brands
                    ? brands.map((value, index) => {
                        return (
                          <MenuItem key={value._id} value={value._id}>
                            {value.name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Field
                  classes={{
                    root: classes.root,
                  }}
                  name="guideId"
                  label="Guia de fotos"
                  component={renderSelectField}
                  custom={{ tabIndex: 4 }}
                  disabled={!edit}
                >
                  {guideInfo
                    ? guideInfo?.map((guide) => (
                        <MenuItem key={guide.name} value={guide._id}>
                          {guide.name}
                        </MenuItem>
                      ))
                    : null}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Field
                  classes={{
                    root: classes.root,
                  }}
                  label="Nombre Empresa"
                  custom={{ tabIndex: 3 }}
                  name={"companyName"}
                  component={renderTextField}
                  InputProps={{
                    value: companyName,
                  }}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justify="flex-end"
              style={{ marginTop: 32, padding: 8 }}
            >
              <Button
                variant="outlined"
                color="secundary"
                type="submit"
                style={{
                  marginRight: 16,
                  color: !edit ? "gray" : "#4C1DB7",
                  border: !edit ? "1px solid gray" : "1px solid #4C1DB7",
                }}
                disabled={!edit}
              >
                Guardar
              </Button>
            </Grid>
          </Fragment>
        ) : (
          <Fragment>Loading...</Fragment>
        )}
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: "General",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
})(General);
