import {
	POST_LOGIN_PENDING,
	POST_LOGIN_FULFILLED,
	POST_LOGIN_REJECTED,
	GET_LOGOUT_PENDING,
	GET_LOGOUT_FULFILLED,
	GET_LOGOUT_REJECTED,
	GET_CHECK_AUTH_PENDING,
	GET_CHECK_AUTH_FULFILLED,
	GET_CHECK_AUTH_REJECTED,
	PATCH_LOGIN_ERROR,
	SET_AUTH_TOKEN
} from "../actions/types";

const initialState = {
  labMode: false,
  inspectionSelectedPage: 0,
  inspectionWithStates: null,
  inspectionsList: null,
  commentsList: {}
};

export default function (state = initialState, action) {
  
	switch (action.type) {
		case 'SET_INSP_SELECTED_PAGE':
			return {
				...state,
				inspectionSelectedPage: parseInt(action.payload)
      };
    case 'SET_INSPECTIONS_LIST':
      return {
        inspectionsList: action.payload,
        ...state,
      };
    case 'SET_COMMENTS_LIST':
      const comments = {...state.commentsList};

      return {
        ...state,
        commentsList: {...state.commentsList, [action.payload.id]: action.payload.comments}
      };
    case 'SET_NEW_COMMENT':
      const todayNow = new Date();
      const date = todayNow.getDate()+'/'+(todayNow.getMonth() +1).toString()+'/'+todayNow.getFullYear()+' '+todayNow.getHours()+':'+todayNow.getMinutes();
      const comments_ = [{...action.payload.comment, date}, ...state.commentsList[action.payload.id]];

      return {
        ...state,
        commentsList: {...state.commentsList, [action.payload.id]: comments_}
      };  
    case 'SET_INSPECTIONS_WITH_STATE': 
      return {...state, inspectionWithStates: action.payload}
    
    case 'SET_LAB_MODE': 
      return {...state, labMode: action.payload}

		default:
			return state;
	}
}
