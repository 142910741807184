import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

const CancelModal = ({ openModal, handleAccept, handleCancel }) => {
  return (
    <Dialog open={openModal} onClose={handleCancel}>
      <DialogContent>
        <DialogContentText>
          Estas seguro que desea cancelar esta operación?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleAccept} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelModal;
