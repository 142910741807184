import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  drawerPaperDrawRight: {
    width: 450,
    borderRadius: 10,
    zIndex: '9000 !important',
    '@media (max-width:426px)': {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
      zIndex: '9000 !important'
    },
    '@media (max-width:599px)': {
      // eslint-disable-line no-useless-computed-key
      marginTop: 0
    }
  },
  styledBadges: {
    '& span': {
      height: 15,
      width: 15,
      borderRadius: 25
    }
  },
  titleMessage: {
    fontSize: 16,
    fontWeight: 700,
    color: '#333333',
    paddingLeft: 10,
    paddingTop: 30
  },
  messageNotification: {
    fontSize: 15,
    fontWeight: 400,
    paddingTop: 30
  },
  numberNotification: {
    fontSize: 18,
    fontWeight: 700,
    color: '#333333',
    paddingBottom: 10,
    paddingTop: 30
  },
  titleNumberNotification: {
    fontSize: 13,
    color: 'rgba(20, 27, 106, 0.54)',
    paddingTop: 10
  },
  btnAccept: {
    padding: '10px 40px',
    '&:hover': {
      backgroundColor: (props) => props.theme && props.theme.primary.variant1
    }
  }
}))
