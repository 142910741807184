import { createTheme } from "@material-ui/core/styles";

import { neutral, primary, secondary } from "./utils/paletteApp";

const theme = createTheme({
  palette: {
    primary,
    secondary,
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 15,
      },
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiTabs: {
      root: {
        "& .MuiTabs-indicator": {
          backgroundColor: primary.main,
        },
      },
    },
    MuiInput: {
      underline: {
        "&.Mui-disabled:before": {
          border: "none",
        },
      },
    },
    MuiInputBase: {
      root: {
        "& .MuiSelect-icon.Mui-disabled": {
          display: "none",
        },
        color: neutral.main,
        "& input:disabled": {
          color: neutral.main,
        },
      },
    },
    MuiFormControl: {
      "& label": {
        transform: "scale(0.5) translate(-100%, -100%);",
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "none",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "12px !important",
        fontWeight: 500,
        lineHeight: "1.5",
        color: primary.main,
        textTransform: "uppercase",
        "&.Mui-disabled": {
          color: primary.main,
        },
      },
    },
  },
});

export default theme;
