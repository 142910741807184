import { Badge, withStyles } from '@material-ui/core'

const StyledBadges = withStyles(() => ({
  colorSecondary: {
    backgroundColor: (props) =>
      props.theme ? props.theme.secondary.variant1 : 'green'
  },
  anchorOriginBottomLeftCircle: {
    left: '15%',
    bottom: '25%'
  },
  dot: {
    height: 8,
    minWidth: 8
  }
}))(Badge)

export default StyledBadges
