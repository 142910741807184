import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    paddingData: {
        padding: '20px 30px'
    },
    sectData: {
        padding: '10px 0px'
    },
    secTitle: {
        '& .title': {
            paddingLeft: 5,
            fontWeight: 600,
            color: '#bc9dfc'
        }
    },
    typeData: {
        paddingTop: 10,
        '& .title': {
            fontWeight: 600,
            color: '#CCCCCC'
        },
        '& .data': {
            fontWeight: 600,
            color: '#666666'
        },
        '& .data-grey': {
            fontWeight: 600,
            color: '#CCCCCC'
        }
    },
    iconsLink: {
        display: 'flex',
        paddingLeft: 20,
        '& .icon': {
            color: '#51BEFA'
        }
    }
}));