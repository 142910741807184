import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import configAbility from '../authorization/ability';
import { AbilityContext, Can } from '../authorization/abilities';
import { useAuth } from '../hooks/useAuth';
import { getLogout } from '../actions/authActions';
import { authUrlBase } from '../utils/urls';
import { makeStyles } from '@material-ui/core/styles';
import Login from './login';
import Container from '../components/Container'
import MenuContainer from '../components/menu/ContainerMenu';
import { Router, Redirect } from '@reach/router';
import InspectionMain from './inspection/inspectionMain';
import InspectionShow from './inspection/inspectionShow';
import InspectionAdd from './inspection/inspectionAdd';
import { NotificationsPage } from '../components/notifications';
import Dashboard from './dashboard';
import VideoChat from './videochat';
import VideoCall from './videocall';
import Guides from './guides';
import GuidesAdd from './guides/guidesAdd';
import GuidesEdit from './guides/guidesEdit';
import GuidesShow from './guides/guidesShow';
import Inspector from './inspector';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex'
    },
    content: {
        width: '100%'
    }
}));

const PublicRoute = ({ component: Component, ...rest }) => (
    <Component {...rest} />
);

const ProtectedRoute = ({ component: Component, action, subject, ...rest }) => {
    
    const token = useSelector((state) => state.auth.token);
    const isAuth = useAuth(token);
    const dispatch = useDispatch();

    if (isAuth) {
        
        if (action && subject) {
            return (
                <Fragment>
                    <Can do={action} on={subject}>
                        <Component {...rest} />
                    </Can>
                </Fragment>
            );
        } else {
            return <Component {...rest} />;
        }
    } else {
        dispatch(getLogout());
        return window.location.assign(
            `${authUrlBase}login/inspections?redirect=${window.location.origin}`
        );
    }
};
const Routes = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    
    const isAuth = useAuth(token);

    const [height, setHeight] = useState(window.innerHeight);
    window.addEventListener('resize', () => {
        setHeight(window.innerHeight);
    });
    
    return (
        <AbilityContext.Provider value={configAbility(token)}>
            <Router>
                <PublicRoute path="login" component={Login} />
            </Router>
            <div className={classes.root} style={{ minHeight: '100vh' }}>
                {isAuth && <MenuContainer />}
                <main className={classes.content}>
                    <Container>
                        <Router>
                            <Redirect from="/" to="login" noThrow />
                            <ProtectedRoute 
                                action="read"
                                subject="List"
                                path="inspections"
                                component={InspectionMain}
                            />
                            <ProtectedRoute
                                action="read"
                                subject="List"
                                path="inspections/show/:id"
                                component={InspectionShow}
                            />
                            <ProtectedRoute
                                action="read"
                                subject="List"
                                path="inspections/add/:type"
                                component={InspectionAdd}
                            />
                            <ProtectedRoute 
                                action="read"
                                subject="List"
                                path="notifications"
                                token={token} 
                                component={NotificationsPage}
                            />
                            <ProtectedRoute 
                                action="read"
                                subject="List"
                                path="dashboard"
                                component={Dashboard}
                            />
                            <ProtectedRoute
                                action="read"
                                subject="List"
                                path="videochat" 
                                component={VideoCall} 
                            />
                            <ProtectedRoute
                                action="read"
                                subject="List"
                                path="guides" 
                                component={Guides} 
                            />
                            <ProtectedRoute
                                action="read"
                                subject="List"
                                path="guides/edit/:id"
                                component={GuidesEdit}
                            />
                            <ProtectedRoute
                                action="read"
                                subject="List"
                                path="guides/show/:id"
                                component={GuidesShow}
                            />
                            <ProtectedRoute
                                action="read"
                                subject="List"
                                path="guides/add" 
                                component={GuidesAdd} 
                            />
                            <ProtectedRoute
                                action="read"
                                subject="List"
                                path="inspector" 
                                component={Inspector} 
                            />
                            
                        </Router>
                    </Container>
                </main>
            </div>
        </AbilityContext.Provider>
    );
}
 
export default Routes;