import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  listItem: {
    fontSize: 16,
    color: '#333333',
    padding: '1rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  listItemSecondary: {
    fontSize: 14
  },
  unread: {
    backgroundColor: (props) => props.theme && props.theme.secondary.variant2,
    fontWeight: 600,
    '& > div:last-child': {
      color: '#000000'
    }
  },
  configMenu: {
    width: 450,
    borderTopLeftRadius: 10,
    padding: '20px 15px 20px 15px',
    '& h3': {
      color: '#3B3B3B',
      fontSize: 16
    }
  },
  drawerPaper: {
    marginBottom: '2rem'
  }
}))
