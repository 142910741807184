import React, { useEffect, useState } from "react"
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { isEmptyNullObject } from '../../utils';
import { getDamageEstimation } from '../../sevices/inspectorAIservice';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  table: {
    maxWidth: '60%',
    margin: '0 auto'
  },
});

const arrayHeads = ['Cart Damage Part', 'Reparation Type', 'Cost Estimation'];

const DamageEstimationTab = ({ showTab, id}) => {
  
  const aIData = useSelector((state) => state.ai.data);

  const classes = useStyles();
  const [damageEstimation, setDamageEstimation] = useState(showTab);
  const token = useSelector((state) => state.auth.token); 
  let objToken = null;
  if ( token ) {
    objToken = jwt_decode(token);
  }

  const [carDamageInfo, setCarDamageInfo] = useState ([]);

  useEffect(() => {
    if ( aIData.length > 0 ) {
      setCarDamageInfo([...aIData]);
    }
  },aIData);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {arrayHeads.map( (column, index) => {
              return <TableCell key={column}>{column}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            aIData[0] &&
            aIData[0]?.aIData?.length > 0 && 
            JSON.parse(aIData[0]?.aIData).map(
              damage => {
                return (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      { damage.part }
                    </TableCell>
                    <TableCell component="th" scope="row">
                      { damage.reparation_type }
                    </TableCell>
                    <TableCell component="th" scope="row">
                      { damage.repair_price }
                    </TableCell>
                  </TableRow>
                )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DamageEstimationTab;