import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import IcSetting from '../icons/IcSetting'
import NotificationList from '../notificationList'
import DrawerConfiguration from '../drawers/drawerConfiguration'

const useStyles = makeStyles(() => ({
  listItem: {
    fontSize: 16,
    color: '#333333',
    padding: '1rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  listItemSecondary: {
    fontSize: 14
  },
  unread: {
    backgroundColor: (props) => props.theme && props.theme.secondary.variant2,
    fontWeight: 600,
    '& > div:last-child': {
      color: '#000000'
    }
  },
  configMenu: {
    width: 350,
    borderTopLeftRadius: 10,
    padding: '20px 15px 20px 15px',
    '& h3': {
      color: '#3B3B3B',
      fontSize: 16
    }
  },
  imageSettings: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

const NotificationsMain = ({
  userEvent,
  theme,
  onRead,
  onUpdateNotificationConfig
}) => {
  const [openNotiConfig, setOpenNotiConfig] = useState(false)
  const [orderKey, setOrderKey] = useState('date')
  const classes = useStyles({ theme })

  const toggleNotificationConfig = (open) => setOpenNotiConfig(open)

  const handleOrderKeyChange = (evt) => setOrderKey(evt.target.value)

  const orderOptions = useMemo(
    () => [
      { name: 'Fecha', value: 'date' },
      { name: 'No leidos', value: 'unread' },
      { name: 'Nombre', value: 'name' }
    ],
    []
  )

  const getOrderByKey = (type) => {
    switch (type) {
      case 'date': {
        const fn = (a, b) => new Date(b.sentAt) - new Date(a.sentAt)
        return fn
      }
      case 'unread': {
        const fn = (a, b) => (a.read === b.read ? 0 : a.read ? 1 : -1)
        return fn
      }
      case 'name': {
        const fn = (a, b) => a.message.title > b.message.title
        return fn
      }
      default:
        console.log('Invalid type!')
    }
  }

  const notifications =
    orderKey !== ''
      ? userEvent.notifications.sort(getOrderByKey(orderKey))
      : userEvent.notifications

  return (
    <Grid item xs={12} style={{ marginBottom: 64 }}>
      <Grid
        container
        justify='space-between'
        alignItems='center'
        style={{ marginBottom: '1rem' }}
      >
        <Grid item>
          <FormControl style={{ minWidth: 150 }}>
            <InputLabel id='sort-control-label'>Ordenar por:</InputLabel>
            <Select
              labelid='sort-control-label'
              value={orderKey}
              onChange={handleOrderKeyChange}
            >
              {orderOptions.map((opt) => {
                return (
                  <MenuItem key={opt.name} value={opt.value}>
                    {opt.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <IconButton
            aria-label='upload picture'
            component='span'
            className={classes.imageSettings}
            onClick={() => {
              toggleNotificationConfig(true)
            }}
          >
            <IcSetting />
          </IconButton>
        </Grid>
      </Grid>

      <NotificationList
        notifications={notifications}
        theme={theme}
        onRead={onRead}
      />

      <DrawerConfiguration
        userEvent={userEvent}
        onUpdateNotificationConfig={onUpdateNotificationConfig}
        openNotiConfig={openNotiConfig}
        handleOpenClose={() => toggleNotificationConfig(false)}
        theme={theme}
      />
    </Grid>
  )
}

NotificationsMain.propTypes = {
  userEvent: PropTypes.object.isRequired,
  theme: PropTypes.object,
  onRead: PropTypes.func.isRequired,
  onUpdateNotificationConfig: PropTypes.func.isRequired
}

export default NotificationsMain
