import axios from 'axios';
import jwt_decode from "jwt-decode";
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { authUrlApi, exeptionCodeResponse } from '../utils/urls';
import { showSuccess, showError } from '../actions/messagesActions';

export const getDataUserLoggedInAPI = createAsyncAction(
    'GET_USER_INFO_LOGGED', async (token = null, userid = null) => {
      try {
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(`${authUrlApi}/usersInfo/${userid}`, { headers })
        return response.data
      } catch (error) {
        return exeptionCodeResponse(error);   
      }
});

export const updateDataUserInfoAPI = createAsyncAction(
  'UPDATE_USER_INFO', async (token, body, dispatch) => {
  try{
    const headers = { Authorization: `Bearer ${token}` };
    const { data } = await axios.put(`${authUrlApi}/usersInfo/update`, body, { headers })
    return data
  } catch (error) {
    return exeptionCodeResponse(error);   
  }
})


export const setPasswordUserAPI = createAsyncAction('SET_PASSWORD_USER', async (token, data, dispatch) => {
  try {
    
      let dataToken = jwt_decode(token);
      const headers = { Authorization: `Bearer ${token}` };
      data._id = dataToken.user._id;
      const response = await axios.put(`${authUrlApi}/users/update`, data, { headers })
      dispatch(showSuccess('Contraseña cambiada con exito'));
      return response;
  } catch (error) {
      dispatch(showError('Las contraseña actual es incorrecta'));
      exeptionCodeResponse(error);
  }
})