import axios from 'axios';
import { inspectionApiUrl } from './urls';
import { downloadPDF, getVersionsByModel, getBrand, getModels } from '../sevices/inspectionService';
import Utils from './utils';
import { getToken, decodedToken } from '.';
import { getComments } from '../sevices';

//import moment from 'moment';
//import formatNumber from './formatNumber'
const getPDFInspections = async (inspection) => {
    const token = getToken();
    const objToken = decodedToken(token);
    const companyName = objToken ? objToken.user.companyName : '';

    const [{ data: inspectionData }, { data: comments }] = await Promise.all([
        axios.get(`${inspectionApiUrl}/inspections/details/${inspection}`),
        getComments(inspection),
    ]);

    const formatCommnets = comments.map((comment) => ({
        formatDateTime: Utils.dateToFormat(comment.date, 'DD/MM/YYYY h:mm'),
        ...comment,
    }));

    const [brands, models, versions] = await Promise.all([getBrand(), getModels(inspectionData.brandId), getVersionsByModel(inspectionData.modelId)]);

    const selectedBrand = brands.find((brand) => brand._id === inspectionData.brandId);
    const selectedModel = models.find((model) => model._id === inspectionData.modelId);
    const selectedVersion = versions.find((version) => version._id === inspectionData.versionId);

    console.log(selectedBrand);
    console.log(selectedModel);
    console.log(selectedVersion);

    const dataToPdf = {
        name: 'Inspecciones',
        templateData: {
            formatDate: Utils.dateToFormat(inspectionData.date[0], 'DD/MM/YYYY'),
            fullName: `${inspectionData.name} ${inspectionData.lastname}`,
            companyName,
            formatTracking: inspectionData.tracking.map((track) => ({
                formatDateTime: Utils.dateToFormat(track.date, 'DD/MM/YYYY h:mm'),
                ...track,
            })),
            inspectedRisk: {
                brand: selectedBrand && selectedBrand.name,
                model: selectedModel && selectedModel.name,
                version: selectedVersion && selectedVersion.name,
                year: String(inspectionData.year),
            },
            formatCommnets,
            ...inspectionData,
        },
    };

    return await downloadPDF(dataToPdf);
};

export default getPDFInspections;
