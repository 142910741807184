import React, { useState, useEffect } from 'react';
import { 
  Collapse,
  Divider, 
  List, 
  ListItem, 
  TextField,
  Typography, Button, Select, MenuItem 
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { frequencies, types } from '../../utils/constants'; 
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
  root: {
    width:'90%'
  }
}));

const CollapsibleListElement = ({ data }) =>{
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <ListItem button onClick={()=>setOpen(!open)}>
        <p>{data.event}</p>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto">
        <div>
          <TextField
            id="outlined-select-currency"
            select
            label="Frecuencia"
            variant="outlined"
            classes={{
              root: classes.root
            }}
          >
            {frequencies.map((freq, i) => (
              <MenuItem key={`freq_index_${i}`} value={freq.value}>
                {freq.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          <TextField
            id="outlined-select-currency"
            select
            label="Tipo de Notificacion"
            // value={currency}
            // onChange={handleChange}
            variant="outlined"
            classes={{
              root: classes.root
            }}
          >
            {types.map((type, i) => (
              <MenuItem key={`type_index_${i}`} value={type.value.toLowerCase()}>
                {type.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </Collapse>
    </>
  )
}

export default CollapsibleListElement;