import Axios, { post, put } from 'axios';
import { inspectionApiUrl, inspectorApiUrl, inspectorAIApiUrl } from '../utils/urls';

export const getIAData = async (photo) => {

  await post(`${inspectionApiUrl}/images/convert`, { image: photo }, {responseType: 'arraybuffer'})
    .then( ({ data }) => {

        // const base64Img = Buffer.from(data, 'binary').toString('base64');
        var file = new File([data], "file.jpg", {
          type: "image/jpeg",
        }); 
        const formData = new FormData();
        formData.append('file',file)
        const config = {
          headers: {
              'content-type': 'multipart/form-data'
          }
        }
        post(`${inspectorApiUrl}assessments`, formData, config)
          .then( ({ data }) =>{
            console.log('data==>', data);
            return JSON.stringify(data, null, '  ');
          })
          .catch( e => {
            console.log('e==>', e);
            return e;
          });
    })
    .catch( e => {
      console.log('error=>', e);
      return e;
    });
  
}

export const getInspectorData = async file => { 
  
  const formData = new FormData();
  formData.append('file',file);

  const config = {
    headers: {
        'content-type': 'multipart/form-data'
    }
  };

  const res = await post(`${inspectorApiUrl}assessments`, formData, config)
    .then( ({ data }) =>{
      console.log('data==>', data);
      return JSON.stringify(data, null, '  ');
    })
    .catch( e => {
      console.log('e==>', e);
      return e;
    });
    
  return res;

}

// export const getDetectorAPIData = async () => {

//   // await Axios.get(`${inspectionApiUrl}/inspections/getJSONfromDetectorAPI`, { image: photo }, {responseType: 'arraybuffer'})
//   await Axios.get(`${inspectionApiUrl}/inspections/getJSONfromDetectorAPI`)
//     .then( ({ data }) => {

//         var file = new File([data], "file.jpg", {
//           type: "image/jpeg",
//         }); 
//         const formData = new FormData();
//         formData.append('file',file)
//         const config = {
//           headers: {
//               'content-type': 'multipart/form-data'
//           }
//         }
//     })
//     .catch( e => {
//       console.log('error=>', e);
//       return e;
//     });
  
// }
  

export const getDetectorAPIDataII = async file => {

  const res = await Axios({
    method: 'put',
    config: {
      headers: {
          'content-type': 'multipart/form-data'
      }
    },
    url: 'https://test-detectorapi.iconnectance.com/predictions/detections',
    data: file,
  }).then( ({ data }) => {
      console.log('data==>', data);
      return JSON.stringify(data, null, '  ');
    })
    .catch( e => {
      console.log('e==>', e);
      return e;
    });
    
  return res;

}

export const getDetectorAPIDataIII = async file => {

  const res = await Axios({
    method: 'put',
    config: {
      headers: {
          'content-type': 'image/jpeg'
      }
    },
    url: 'https://test-detectorapi.iconnectance.com/predictions/detections',
    data: {file},
  }).then( ({ data }) => {
      console.log('data==>', data);
      return JSON.stringify(data, null, '  ');
    })
    .catch( e => {
      console.log('e==>', e);
      return e;
    });
    
  return res;

}

export const getDetectorAPIDataIV = async (file) => {

  const res = await Axios({
    method: 'POST',
    config: {
      headers: {
        'content-type': 'multipart/form-data'
      }
    },
    url: `${inspectionApiUrl}/inspections/getJSONfromDetectorAPIII`,
    data: file,
  }).then( ({ data }) => {
      console.log('data==>', data);
      return JSON.stringify(data.body, null, '  ');
    })  
    .catch( e => {
      console.log('e==>', e);
      return e;
    });
    
  return res;

}

export const getDamageEstimation = async inspectionId => {

  const res = post(`${inspectionApiUrl}/inspections/getDamageEstimation`, {inspectionId}).then( 
    ({ data }) => {
      console.log('data==>', JSON.parse(data.body));
      return data.body
    })
    .catch( e => {
      console.log('e==>', e);
      return e;
    });
    
  return res;

}