import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '450px',
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    right: '-120px'
  },
  rootSubheaderWrapper: {
    overflow: 'auto'
  },
  rootSubheader: {
    borderRadius: '10px 0px'
  },
  rootTypo: {
    color: (props) => props.theme && props.theme.primary.main
  },
  inline: {
    display: 'inline'
  },
  rootBadge: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  shapeBadge: {
    backgroundColor: (props) => props.theme && props.theme.primary.main,
    width: 40,
    height: 40
  },
  shapeCircleBadge: {
    borderRadius: '50%'
  },
  rootNotif: {
    '&:hover': {
      backgroundColor: (props) => props.theme && props.theme.secondary.variant2,
      cursor: 'pointer'
    },
    maxHeight: 75,
    overflowY: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  rootNotifUnread: {
    '&:hover': {
      backgroundColor: (props) => props.theme && props.theme.secondary.variant2,
      cursor: 'pointer'
    },
    backgroundColor: (props) => props.theme && props.theme.secondary.variant4,
    maxHeight: 75,
    overflowY: 'hidden'
  },
  hover: {
    '&:hover': {
      backgroundColor: (props) => props.theme && props.theme.secondary.variant1,
      cursor: 'pointer'
    }
  },
  drawerPaper: {
    width: 450,
    borderRadius: 10,
    zIndex: '9000 !important',
    '@media (max-width:426px)': {
      // eslint-disable-line no-useless-computed-key
      width: '100%',
      zIndex: '9000 !important'
    },
    '@media (max-width:599px)': {
      // eslint-disable-line no-useless-computed-key
      marginTop: 0
    }
  }
}))
