const initialState = {
  globalFilter: 0,
  filterList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_GLOBAL_INSPECTIONS_FILTER":
      return { 
        ...state,
        globalFilter: action.payload
      };
    case "SET_FILTER_LIST":
      console.log('action.payload===>', action.payload);
      return {
        ...state,
        filterList: [...state.filterList, action.payload],
      };
    case "DELETE_FILTER_LIST":
      const newFilterList = state.filterList.filter((el) => {
        return el !== action.payload;
      });
      return {
        ...state,
        filterList: newFilterList,
      };
      case "RESET_FILTER_LIST":
        return{
          ...state,
          filterList : []
        }

    default:
      return state;
  }
}
