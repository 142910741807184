import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';
import { 
  Button,
  Drawer, 
  Divider } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import CollapsibleListElement from './collapsibleListElement';
import NotificationsEditForm from '../../../notifications-ui/components/notificationsEditForm';
import { getAllNotifications, updateNotificationReadStatus, getEventsByUserIdAPI, getEventsByUserIdAPINew } from '../../services';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: '350px',
    borderRadius: '10px',
    padding: '5px 10px 0px 10px'
  },
  closeIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer'
  }
}));

const DrawerConfigurations = ({ open, token }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [eventsFromApi, setEventsFromApi] = useState(null);
  const classes = useStyles();
  const { eventsNames } = useSelector((state) => state.notifications); 

  useEffect(() => {
    setOpenDrawer(open)
  }, [open]);

  /* useEffect(() => {
    let objToken = null;
    if ( token ) {
      objToken = jwt_decode(token);
    }
    getEventsByUserIdAPINew(token, objToken.user._id, objToken.user.email).then(
      res => setEventsFromApi(res)
    );
  }, [open]); */

  return (
    <Drawer 
      anchor={'right'} 
      open={openDrawer} 
      onClose={() => setOpenDrawer(false)}
      BackdropProps={{ invisible: true }}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div>
        <CloseIcon 
          onClick={() => setOpenDrawer(false)}
          classes={{
            root: classes.closeIcon
          }} 
        />
      </div>
      <div style={{
        display:'flex', 
        justifyContent:'space-between',
        alignItems:'center',
        padding: '0px 5px',
        fontWeight: 500
      }}>
        <h2
          style={{
            color: '#3B3B3B',
            fontSize: '16px'
          }}
        >Configuracion de notificaciones</h2>
        <SettingsIcon />
      </div>
      {
        eventsFromApi &&
        <NotificationsEditForm
          events={eventsFromApi}
        />
      }
      {
        eventsNames.map(
          (event, index) => {
            return (
              <>
                <CollapsibleListElement key={event} data={{event:event}}/>
                { index < (eventsNames.length-1) &&  <Divider /> }
              </>
            )
          }
        )
      }
    </Drawer>
  )
}

export default DrawerConfigurations;