import React, { useRef, useState, useMemo } from "react"
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Modal } from "@material-ui/core";
import { ImageSearch, SettingsBackupRestore } from "@material-ui/icons";
import CancelIcon from '@material-ui/icons/Cancel';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import { storageImageToInspection } from '../../../sevices/inspectionService';
import { decodedToken } from '../../../utils';
import { bytesArrayOpt, composeFormDataGuideImg } from '../../../utils/images';
import { setImage } from '../../../actions/videoCallActions'
import Empty from '../../../assets/images/empty.jpg';
import { useStyles } from './styles';

const PhotoSquare = ({ section, position, id, title, remoteVideo, idVideo, defaultImage=null }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const canvasRef = useRef(null);
    const remoteVideRef = remoteVideo;
    const { currentImages } = useSelector(state => state.videocall);
    const token = decodedToken(useSelector((state) => state.auth.token));
    const [companyName, setCompanyName] = useState(token ? token.user.companyName : '');
    const image = currentImages.get(idVideo)?.get(id);
    const [zoomImageModal, setZoomImageModal] = useState(false);

    const showImage = () => setZoomImageModal(true);
    const closeImage = () => setZoomImageModal(false);

    const getModalStyle = () => {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const handleCloseZoom = () => {
        setZoomImageModal(false)
    }

    const handleClickSnapshot = () => {
        if ( remoteVideRef && remoteVideRef.current ) {   
            let canvasEl = document.createElement('canvas');
            canvasEl.width = remoteVideRef.current.clientWidth;
            canvasEl.height = remoteVideRef.current.clientHeight;
            canvasEl.style.display = 'none';
            canvasEl.getContext('2d').drawImage(remoteVideRef.current, 0, 0, remoteVideRef.current.clientWidth, remoteVideRef.current.clientHeight);

            dispatch(setImage( {idVideo, id, image: canvasEl.toDataURL()} ))
    
            const bytesImage    = bytesArrayOpt(canvasEl.toDataURL());
            const formDataObj   = composeFormDataGuideImg('image/jpeg', bytesImage, idVideo, title, section, position);
            storageImageToInspection(formDataObj);
        }
    }

    const displayImage = () => {
        return (
            <>
                {   
                    (image ?? defaultImage) ?
                    <div style={{ position: 'relative', width: 220, height: 220, borderRadius: '8px' }}>
                        <div style={{ backgroundImage: `url(${(image ?? defaultImage)})`, width: 220, height: 220, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}></div>
                        <div className={classes.controls}>
                            <IconButton onClick={showImage} className={classes.controlButtons}>
                                <ImageSearch />
                            </IconButton>
                            <IconButton onClick={handleClickSnapshot} className={classes.controlButtons}>
                                <SettingsBackupRestore />
                            </IconButton>
                        </div>
                    </div> :
                    <div onClick={handleClickSnapshot} style={{ width: '220px', height: '220px', backgroundImage: `url(${Empty})`, backgroundPosition: 'center', borderRadius: '8px' }}></div>
                }
                <canvas ref={canvasRef} width="500px" height="500px" style={{ display: 'none' }}></canvas>
                <Modal
                    open={zoomImageModal}
                    onClose={closeImage}>
                    <div style={{ ...getModalStyle() }} className={classes.modal}>
                        <span className={classes.closeZoomIcon} onClick={handleCloseZoom}>
                            <CancelIcon />
                        </span>
                        <PinchZoomPan maxScale={5}>
                            <img src={defaultImage} width="100%" height="100%" />
                        </PinchZoomPan>
                    </div>
                </Modal>
            </>
        )
    }
       
        
    return (
        <div style={{ margin: '0px 10px', marginBottom: '20px', width: '250px', height: '250px', display: 'inline-block' }}>
            <p style={{ width: '80px', fontSize: '14px', color: '#4B4B4B', fontWeight: 500, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{title}</p>
            {   
                displayImage()
            }
        </div>
    )
}

export default PhotoSquare;