import {
    addGuideAPI,
    getGuidesAPI,
    deleteGuideAPI,
    updateGuideAPI,
} from '../sevices/guidesService';


export const getGuides = (token, params) => async (dispatch) => {
    await dispatch(getGuidesAPI(token, params))
};

export const addGuide = (token, newGuide) => async (dispatch) => {
    await dispatch(addGuideAPI(token, newGuide, dispatch));
};

export const updateGuide = (token, newGuide) => async (dispatch) => {
    await dispatch(updateGuideAPI(token, newGuide, dispatch));
};

export const deleteGuide = (token, newGuide) => async (dispatch) => {
    await dispatch(deleteGuideAPI(token, newGuide, dispatch));
};