import React, { useEffect, useRef, useState } from "react";

const initialState = {
    room: null,
    isCallActive: null,
    remoteStreamRed: null,
    remoteVideRef: null,
    enableAudioStream: true,
    localStreamRed: null,
    currentImages: new Map()
};

export default function (state = initialState, action) {

    switch (action.type) {
        case 'SET_CALL_END':
            return {
                ...state,
                isCallActive: false,
                remoteStreamRed: false,
                localStreamRed: null
            };
        case 'SET_ROOM':
            return {
                ...state,
                room: action.payload
            };
        case 'SET_CALL_START':
            return {
                ...state,
                isCallActive: true
            };
        case 'SET_CALL_STREAM':
            console.log('action.payload=>', action.payload)
            return {
                ...state,
                remoteStreamRed: action.payload
            }
        case 'SET_REMOTE_VIDEO_REF':
            return {
                ...state,
                remoteVideRef: action.payload
            }
        case 'SET_MUTE_STREAM':
            return {
                ...state,
                enableAudioStream: !state.enableAudioStream
            }
        case 'SET_LOCAL_STREAM':
            return {
                ...state,
                localStreamRed: action.payload
            }
        case 'SET_IMAGE':
            const roomDataImages = state.currentImages.get(action.payload.idVideo);
            let newMap;
            if (roomDataImages) {
                roomDataImages.set(action.payload.id, action.payload.image);
                let newRoomDataImages = new Map(roomDataImages)
                state.currentImages.set(action.payload.idVideo, newRoomDataImages)
                newMap = new Map(state.currentImages)

            } else {
                let newRoomMap = new Map()
                newRoomMap.set(action.payload.id, action.payload.image);
                let newRoomDataImages = new Map(newRoomMap)
                state.currentImages.set(action.payload.idVideo, newRoomDataImages)
                newMap = new Map(state.currentImages)
            }

            return {
                ...state,
                currentImages: newMap
            }
        default:
            return state;
    }
}
