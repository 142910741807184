import piexif from "piexifjs";
import moment from "moment";

// MOVE TO CONSTANTS FILE/FOLDER
const mapPiexifValString = new Map();
mapPiexifValString.set(piexif.ExifIFD.DateTimeOriginal, "Date Time");
mapPiexifValString.set(piexif.GPSIFD.GPSDateStamp, "Date Time GPS");
mapPiexifValString.set(piexif.GPSIFD.GPSLatitudeRef, "LatitudRef");
mapPiexifValString.set(piexif.GPSIFD.GPSLatitude, "Latitud");
mapPiexifValString.set(piexif.GPSIFD.GPSLongitudeRef, "LongitudRef");
mapPiexifValString.set(piexif.GPSIFD.GPSLongitude, "Longitud");

const Utils = {
  arrayMove: (array, from, to) => {
    const tempArray = array.slice();
    const startIndex = to < 0 ? tempArray.length + to : to;
    const item = tempArray.splice(from, 1)[0];
    tempArray.splice(startIndex, 0, item);
    return tempArray;
  },
  getObjectByType: (type, options) => {
    switch (type) {
      case "string":
        return { values: options.join(",") };
      case "numeric":
        return {
          min: options[0],
          max: options[1],
          preffix: options[2],
          suffix: options[3],
        };
      case "bool":
        return {
          affirmativeLabel: options[0],
          negativeLabel: options[1],
        };
      default:
        console.log("Invalid type!");
        break;
    }
  },
  getUploadedFileDimensions: (file) =>
    new Promise((resolve, reject) => {
      try {
        const img = new Image();

        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);

          return resolve({ width, height });
        };

        img.src = window.URL.createObjectURL(file);
      } catch (exception) {
        return reject(exception);
      }
    }),
  dateToFormatUTC: (value, format) =>
    value ? moment(value).utc().format(format) : "",
  dateToFormat: (value, format) => (value ? moment(value).format(format) : ""),
  isEmptyObject: (obj) => {
    return Object.keys(obj).length === 0;
  },
  slugify: (string) => {
    const a =
      "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
    const b =
      "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    let newSlug = string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text

    // This is to avoid a possible repeat slug at some point
    const randomId = Math.floor(Math.random() * 10000) + 100;
    newSlug = `${newSlug}-${randomId}`;

    return newSlug;
  },
  convertMetadataToArray: (metadata) => {
    if (metadata) {
      let arrayMetadata = [];
      for (const exifProp in metadata) {
        for (const value in metadata[exifProp]) {
          if (mapPiexifValString.get(parseInt(value))) {
            const dataObj = {
              key: mapPiexifValString.get(parseInt(value)),
              value: metadata[exifProp][value],
            };

            if (
              mapPiexifValString.get(parseInt(value)) === "Latitud" ||
              mapPiexifValString.get(parseInt(value)) === "Longitud"
            ) {
              dataObj.value = piexif.GPSHelper.dmsRationalToDeg(
                metadata[exifProp][value]
              );
            }

            arrayMetadata.push(dataObj);
          }
        }
      }
      return arrayMetadata;
    }

    return [];
  },
  validation: {
    isRequired: (value) => !value && "Requerido",
    isOnlyNumber: (value) =>
      value && isNaN(Number(value)) && "Solo se permiten números",
  },
  sortByAlfabethical: (current, next) => {
    if (current.toLowerCase() === next.toLowerCase()) {
      return 0;
    }

    if (current.toLowerCase() > next.toLowerCase()) {
      return 1;
    }

    return -1;
  },
  sortByDateUnix: (current, next, orderby = 'desc') => {
    let currentValid = moment(current).isValid()
      ? moment(current)
      : moment(new Date(1970, 0, 0));
    let nextValid = moment(next).isValid()
      ? moment(next)
      : moment(new Date(1970, 0, 0));
    if(orderby.toLowerCase() === 'asc'){
      return currentValid.isAfter(nextValid) ? 1 : -1;
    }
    return currentValid.isAfter(nextValid) ? -1 : 1;
  },
  
  createAndDownload: (body, filename, extension = "pdf") => {
    const blob = new Blob([body], {
      type: "application/pdf",
    });
    const fileName = `${filename}.${extension}`;
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
};

export default Utils;
