export const BORDERS_STATES_COLORS = [
  '#828282', //pendiente
  '#F2994A', //cancelar
  '#F2C94C', //rehacer
  '#EB5757', //rechazado
  '#6FCF97'  //aprobado
];

export const STATES_COLORS = [
  '#F2F2F2', // PENDIENTE
  '#f2994a42', // CANCELAR
  '#FFF9EE', // REHACER
  '#FBDDDD', // RECHAZADO
  '#E2F5EA'  // APROBADO
];

export const MAP_ID_STATE = [
  'pendiente',
  'cancelar',
  'rehacer',
  'rechazar',
  'aprobar',
  'pendienteApro'
];

let _STATES_RELATION = new Map();
_STATES_RELATION.set('pendiente', {goesTo: ['cancelar','rehacer', 'rechazar', 'aprobar'], index: 0});
_STATES_RELATION.set('cancelar', {goesTo: ['rehacer'], index: 1});
_STATES_RELATION.set('rehacer', {goesTo: ['cancelar'], index: 2});
_STATES_RELATION.set('rechazar', {goesTo: [], index: 3});
_STATES_RELATION.set('aprobar', {goesTo: [], index: 4});
_STATES_RELATION.set('pendienteApro', {goesTo: ['cancelar', 'rehacer', 'rechazar', 'aprobar'], index: 5});
_STATES_RELATION.set('0', {goesTo: ['cancelar', 'rehacer', 'rechazar', 'aprobar'], index: 0});
_STATES_RELATION.set('1', {goesTo: ['rehacer'], index: 1});
_STATES_RELATION.set('2', {goesTo: ['cancelar'], index: 2});
_STATES_RELATION.set('3', {goesTo: [], index: 3});
_STATES_RELATION.set('4', {goesTo: [], index: 4});
_STATES_RELATION.set('5', {goesTo: ['cancelar', 'rehacer', 'rechazar', 'aprobar'], index: 5});

export const STATES_RELATION = _STATES_RELATION;