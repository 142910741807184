import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  modal: {
    display: "flex",
    background: "transparent",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #fff",
    "& .makeStyles-paper-60": {
      borderColor: "transparent",
      borderRadius: 20,
    },
    borderRadius: 20,
    "& h2": {
      textAlign: "center",
    },
  },
  title: {
    color: '#141B6A',
    fontWeight: 400,
    textAlign: 'center',
  },
  text: {
    color: '#4B4B4B',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.1,
    textAlign: 'center',
  },
  header: {
    display: "flex",
    justifyContent: "center",
    padding: 10,
  },
  footer: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: 10,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 20,
    "&:focus-visible": {
      outlineColor: 'white'
    },
  },
  button: {
    fontSize: 16,
    minWidth: '100px',
    width: '20%',
    padding: '10px 20px',
    border: 'none', 
    backgroundColor: 'transparent',
    borderRadius: 5,
    cursor: "pointer",
    color: '#6716F5',
  },
}));
