export const primary = {
  light: "#29B6F6",
  main: "#4C1DB7",
  dark: "#006064",
  contrastText: "#FFFFFF",
 };
  
 export const secondary = {
  light: "#66BB6A",
  main: "#43A047",
  dark: "#1B5E20",
  contrastText: "#FFFFFF",
 };
  
 export const neutral = {
  main: "#4B4B4B",
  dark: "#000000",
 };