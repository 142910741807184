import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Grid from "@material-ui/core/Grid";
import { inspectionApiUrl } from "../../utils/urls";
import { resetFilterList, setFilterList } from "../../actions/filterListActions";
import CircleRed from "../../assets/images/red_circle.png";
import CircleGray from "../../assets/images/gray_circle.png";
import CircleGreen from "../../assets/images/green_circle.png";
import CircleYellow from "../../assets/images/yellow_circle.png";
import InfoCard from "../../components/dashboard/InfoCard";
import BarGraph from "../../components/dashboard/barGraph";
import Filter from "../../components/dashboard/filter";
import Utils from "../../utils/utils";
import { Hidden } from "@material-ui/core";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [activeModal, setActiveModal] = useState(false);
  const [dataStates, setDataStates] = useState([0, 0, 0, 0, 0]);
  const [percentStates, setPercentStates] = useState([0, 0, 0, 0, 0]);
  const [dataFilteredStates, setDataFilteredStates] = useState(null);
  const [, setPercentFilteredStates] = useState(null);
  const [filterData, setFilterGraphData] = useState(null);
  const handleReset = () => {
    setFilterGraphData(null);
    setDataFilteredStates(null);
    setPercentFilteredStates(null);
  };

  let objToken = null;
  if (token) {
    objToken = jwt_decode(token);
    console.log('objToken=>', objToken);
  }

  const resetFilters = () => {
    dispatch(resetFilterList());
  };

  useEffect(() => {}, [filterData]);

  useEffect(() => {
    resetFilters();
    // console.log('photo=>', photo);
    // fetch(photo)
    // console.log('https://iconnectance-inspection-test.s3.us-east-2.amazonaws.com/30725388d51e67c2627d6cd6e96a0d32');

    //fetch('https://iconnectance-inspection-test.s3.us-east-2.amazonaws.com/30725388d51e67c2627d6cd6e96a0d32')
    //setImage64(this.result)
    // console.log('this.result=>', this.result.replace(data:application/octet-stream;base64))
    // <--- `this.result` contains a base64 data URI
    //fetch('https://iconnectance-inspection-test.s3.us-east-2.amazonaws.com/3077da78df8d867c8c0b96c2662f3228.jpg')
    /*
    const myHeaders = new Headers({
      "Content-Type": "image/jpeg",
    });

    const myRequest = new Request(
      "https://iconnectance-inspection-test.s3.us-east-2.amazonaws.com/0016e7629d6a96df871378bac304a1a5.jpg",
      {
        method: "GET",
        headers: myHeaders,
        mode: "no-cors",
        cache: "default",
      }
    );

    fetch(myRequest)
      .then((response) => {
        console.log("response=>", response);
        return response.blob();
      })
      .then((blob) => {
        console.log("got it!!");
        console.log("blob=>", blob);
        var reader = new FileReader();
        reader.onload = function () {
          console.log("this.result=>", this.result);
        };
        reader.readAsDataURL(blob);
      });
    */

    // const reducer = (accumulator, currentValue) => accumulator + currentValue;

    axios
      .get(`${inspectionApiUrl}/statics/${objToken?.user?.companyId}/all/count`)
      .then(({ data }) => {
        console.log("data complete=>", data);
        setDataStates([
          data.pending,
          data.aprov,
          data.redo,
          data.reject,
          data.cancel,
          data.forApro
        ]);
        // setSumStates([data.pending, data.cancel, data.redo, data.reject, data.aprov].reduce(reducer));
        setPercentStates([
          data.pendingPercent,
          data.aprovPercent,
          data.redoPercent,
          data.rejectPercent,
          data.cancelPercent,
          data.forAproPercent
        ]);
      })
      .catch((e) => {
        console.log("e=>", e);
      });
  }, []);

  const filterResults = (from, until) => {
    axios
      .post(
        `${inspectionApiUrl}/statics/${objToken?.user?.companyId}/filter_date`,
        { from, until }
      )
      .then(({ data }) => {
        setDataFilteredStates([
          data.pending,
          data.aprov,
          data.redo,
          data.reject,
          data.cancel,
        ]);
        const fromFormated = Utils.dateToFormatUTC(from, "DD/MM/YYYY");
        const untilFormated = Utils.dateToFormatUTC(until, "DD/MM/YYYY");
        console.log(`${fromFormated} - ${untilFormated}`);
        setFilterGraphData(`${fromFormated} - ${untilFormated}`);
      })
      .catch((e) => {
        console.log("e=>", e);
      });
      activeModal(true);
  };

  return (
    <div>
      <Filter filterFn={filterResults} reset={handleReset} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <InfoCard
            type={"PENDIENTES"}
            value={dataFilteredStates ? dataFilteredStates[0] : dataStates[0]}
            percent={percentStates[0]}
            icon={<img src={CircleGray} />}
            isFiltered={filterData && true}
            onClick={() => {
              dispatch(setFilterList("pendiente"));
              navigate("/inspections");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InfoCard
            type={"APROBADAS"}
            value={dataFilteredStates ? dataFilteredStates[1] : dataStates[1]}
            percent={percentStates[1]}
            icon={<img src={CircleGreen} />}
            isFiltered={filterData && true}
            onClick={() => {
              dispatch(setFilterList("aprobado"));
              navigate("/inspections");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InfoCard
            type={"REHACER"}
            value={dataFilteredStates ? dataFilteredStates[2] : dataStates[2]}
            percent={percentStates[2]}
            icon={<img src={CircleYellow} />}
            isFiltered={filterData && true}
            onClick={() => {
              dispatch(setFilterList("rehacer"));
              navigate("/inspections");
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InfoCard
            type={"RECHAZADAS"}
            value={dataFilteredStates ? dataFilteredStates[3] : dataStates[3]}
            percent={percentStates[3]}
            icon={<img src={CircleRed} />}
            isFiltered={filterData && true}
            onClick={() => {
              dispatch(setFilterList("rechazar"));
              navigate("/inspections");
            }}
          />
        </Grid>
        <Hidden mdUp>
          <Grid item xs={12}>
            <InfoCard
              type={"CANCELADAS"}
              value={dataFilteredStates ? dataFilteredStates[4] : dataStates[4]}
              percent={percentStates[4]}
              icon={<img src={CircleRed} />}
              isFiltered={filterData && true}
              onClick={() => {
                dispatch(setFilterList("cancelar"));
                navigate("/inspections");
              }}
            />
          </Grid>
        </Hidden>
      </Grid>
      <br />
      <Grid
        container
        direction="row"
        spacing={2}
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs md={9}>
          <BarGraph
            labels={[
              "Pendiente",
              "Aprobado",
              "Rehacer",
              "Rechazado",
              "Cancelado",
              "Pendiente p. Aprob",
            ]}
            data={dataFilteredStates || dataStates}
            filterData={filterData}
          />
        </Grid>
        <Hidden smDown>
          <Grid item xs={12}  md={3}>
            <div>
              <InfoCard
                type={"CANCELADAS"}
                value={dataFilteredStates ? dataFilteredStates[4] : dataStates[4]}
                percent={percentStates[4]}
                icon={<img src={CircleRed} />}
                isFiltered={filterData && true}
                onClick={() => {
                  dispatch(setFilterList("cancelar"));
                  navigate("/inspections");
                }}
              />
            </div>
            <div style={{marginTop: '10px'}}>
              <InfoCard
                type={"PENDIENTES POR APROBAR"}
                value={dataFilteredStates ? dataFilteredStates[5] : dataStates[5]}
                percent={percentStates[5]}
                icon={<img src={CircleGray} />}
                isFiltered={filterData && true}
                onClick={() => {
                  dispatch(setFilterList("pendiente por aprobar"));
                  navigate("/inspections");
                }}
              />
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default DashboardPage;
