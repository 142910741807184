import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = {
  table: {
    fontFamily: 'arial, sans-serif',
    borderCollapse: 'collapse',
    width: '100%'
  },
  tsyle: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px',
  },
};

export const convertDataFromAI = (dataObj) => {
  
  const classes = useStyles;
  console.log(dataObj);
  const rows = [];
  for (const upperProperty in dataObj) {  
    console.log('veamos', dataObj[upperProperty], dataObj[upperProperty]['name']);
    const row = <tr>
      <td
        style={classes.tsyle}
      >{dataObj[upperProperty]['name'] || ''}</td>
      <td
        style={classes.tsyle}
      >{dataObj[upperProperty]['hasResult'] || ''}</td>
      <td
        style={classes.tsyle}
      >{dataObj[upperProperty]['accuracy'] || '' }</td>
      <td
        style={classes.tsyle}
      >{typeof dataObj[upperProperty]['elapsed_time'] === 'object' ? '' : dataObj[upperProperty]['elapsed_time']}</td>
    </tr>;
    rows.push(row);
  }
  
  return (
    <>
      <table
        style={classes.table}
      >
        <tr>
          <th
            style={classes.tsyle}
          >Model Name</th>
          <th
            style={classes.tsyle}
          >Result</th>
          <th
            style={classes.tsyle}
          >Probability</th>
          <th
            style={classes.tsyle}
          >Elapsed time</th>
        </tr>
        {rows.map(value => value)}
      </table>
    </>
  );
};