import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import jwt_decode from "jwt-decode";
import clsx from 'clsx';
import { navigate } from '@reach/router';
import { NotificationsDrawer } from '../notifications';
import DrawerNotificationBarMenu from '../notifications-ui'
import ProfileDrawer from '../profile/drawerProfile';
import LabModeModal from '../labModeModal';
import { AppBar, Grid, Toolbar, Typography, IconButton, /*Badge, withStyles,*/ Avatar } from '@material-ui/core';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded'
import { PowerSettingsNew, Apps } from '@material-ui/icons';
import SectionTitle from '../sectionTitle/sectionTitle';
import { getLogout } from '../../actions/authActions';
import { useStyles } from './styles'
import { getDataUserLoggedIn } from '../../actions/userInfoActions';

const BarMenu = ({ menuOpen }) => {
    const classes = useStyles();
    //const [openNoti, setOpenNoti] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    //const [elemNoti, setElemNoti] = useState(null);
    const dispatch = useDispatch();
    
    const token = useSelector((state) => state.auth.token);
    const userInfo = useSelector((state) => state.userInfo);
    const { 
        isCallActive,
        room  
    } = useSelector((state) => state.videocall);

    let objToken = null;
    if ( token ) {
        objToken = jwt_decode(token);
    }

    const handleGetBackToCall = () => {
        if ( !window.location.href.includes('room') ) navigate(`/videochat?room=${room}`);
    }
    
    const handleGetDataUserLoggeIn = (token, userid) => {  
        dispatch(getDataUserLoggedIn(token, userid))
    }

    const handleLogout = () => {
        dispatch(getLogout());
    };
    
    /*const handleNotification = event => {
        setElemNoti(event.currentTarget);
        setOpenNoti(!openNoti);
    };*/
    
    const handleProfile = () => {
        if( !openProfile )
        setOpenProfile(true)
    }
    
    const handleCloseProfile = () => {
        setOpenProfile(false);
    }
    
    useEffect(() => {
        if (token && objToken?.user?._id ) { 
            handleGetDataUserLoggeIn(token, objToken.user._id)
        }
    }, [token, objToken.user._id])
    
    const userName = objToken?.user?.companyName ? ` - ${objToken.user.companyName}` : '';
    
    return (
        <AppBar position="absolute" className={clsx(classes.appBar, menuOpen && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <Grid item>
                    <Typography className={classes.title}>
                        <SectionTitle />
                    </Typography>
                </Grid>
                <Grid item>
                    {
                        isCallActive &&
                        <IconButton color="inherit" onClick={handleGetBackToCall}>
                            <PhoneForwardedIcon style={{ color: '#828282' }} />
                        </IconButton>
                    }
                    {/* <DrawerNotificationBarMenu /> */}
                    <IconButton color="inherit" >
                        <LabModeModal />
                    </IconButton>
                    <NotificationsDrawer token={token} />
                    {/* <IconButton color="inherit" onClick={(event) => handleNotification(event)}>
                        <StyledBadge
                            variant="dot"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            overlap="circular"
                            color="secondary">
                            <NotificationsOutlined style={{ color: '#828282' }} />
                        </StyledBadge>
                        <PanelNotifications openNoti={openNoti} elemNoti={elemNoti} />
                    </IconButton> */}
                    <IconButton color="inherit" onClick={handleLogout}>
                        <PowerSettingsNew style={{ color: '#828282' }} />
                    </IconButton>
                    <IconButton color="inherit" onClick={handleProfile}>
                      
                        {userInfo.data ? (
                            userInfo.data.pictureUrl ? (
                                <Avatar src={userInfo.data.pictureUrl} />
                            ) : (
                                <Avatar className={classes.iconUser}>
                                    {userInfo.data.firstName ? (
                                        <Typography style={{ fontStyle: 'uppercase' }}>
                                            {`${userInfo.data.firstName.charAt(0)}${userInfo.data.lastName.charAt(0)}`}
                                        </Typography>
                                    ) : null}
                                </Avatar>
                            )
                        ) : (
                            null
                        )}

                        <ProfileDrawer open={openProfile} goBack={handleCloseProfile} />
                    </IconButton>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default BarMenu;
