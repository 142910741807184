import React from "react";
import TabsGroup from "./tabsGroup";
import UploadPhotos from "./uploadPhotos";
import { getParams } from '../../utils';
import { useStyles } from "./styles";

const InspectorUI = () => {
  const classes = useStyles();
  const params = getParams(window.location.href);

  return (
    <>
      <section className={classes.container}>
        <div style={{ maxWidth: '800px' }}>
          <UploadPhotos />
        </div>
        <div style={{ maxWidth: '800px' }}>
          <TabsGroup tempId={params.tmp} />
        </div>
      </section>
    </>
  )
};

export default InspectorUI;