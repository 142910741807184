import React from 'react';
import { navigate } from '@reach/router';
import { Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    addFab: {
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 900,
        '&:hover': {
            backgroundColor: '#006064 !important'
        }
    }
}));

const FabAdd = ({ urlRedirect }) => {

    const classes = useStyles();
    const handleAddInspection = () => {
        navigate(urlRedirect)
    }

    return (
        <Fab color="primary" className={classes.addFab} onClick={handleAddInspection}>
            <Add />
        </Fab>
    )
}

export default FabAdd;
