import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    sectionAvatar: {
        paddingTop: 35,
        paddingBottom: 35,
        position: 'relative',
        '& .nameProfile': {
            paddingTop: 21,
            color: '#141B6A'
        },
        '& .iconUpload': {
            backgroundColor: '#6C63FF'
        }
    },
    photoProfile: {
        height: 155,
        width: 155,
        backgroundColor: theme.palette.primary.main
    },
    spaceInput: {
        paddingBottom: 20,
        width: '100%',
        '& label':{
            color: '#AF8AFC !important'
        }
    },
    sectBtnSave: {
        paddingTop: 20,
        '& .btn-save': {
            backgroundColor: '#6C63FF'
        }
    },
    imgMessageError: {
        color: 'red',
        position: 'absolute',
        top: 10
    },
    textSmall: {
        fontSize: 15
    }
}))