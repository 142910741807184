import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Send } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: 0,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    background: 'transparent'
  },
  root: {
    minWidth: 30,
    minHeight: 30,
    background: '#4C1DB7'
  },
  rootOther: {
    background: '#4c1db77a'
  },
  speedDialContainer: {
    position: 'relative'
  },
  sizeSmall: {
    width: 30, 
    height: 30
  },
  rootIcon: {
    color: 'black'
  },
  rootDialAction: {
    height: '26px',
    width: '26px',
    minHeight: '26px',
    boxShadow: 'unset',
    color: 'black',
    background: 'unset',
    '&hover' : {
      background: 'unset'
    }
  }
}));

export default function MenuFab({ vidchat=null, whts=null, sms=null, email=null, index }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSms = () => {
    console.log('sms');
    sms();
    handleClose();
  }

  const handleMail = () => {
    console.log('mail');
    email();
    handleClose();
  }

  const handleWhatsapp = () => {
    console.log('whatsapp');
    whts();
    handleClose();
  }

  const handleVideochat = () => {
    console.log('videochat');
    vidchat();
    handleClose();
  }

  return (
    <div className={classes.speedDialContainer}>
    <Tooltip title='Enviar inspección'>
      <Send 
        classes={{
          root: classes.rootIcon,
        }}
        onClick={handleClick}
      />
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >  
        <MenuItem value={'sms'} onClick={handleSms}>SMS</MenuItem>  
        <MenuItem value={'mail'} onClick={handleMail}>Mail</MenuItem>  
        <MenuItem value={'whatsapp'} onClick={handleWhatsapp}>Whatsapp</MenuItem>
      </Menu>
    </div>
  );
}