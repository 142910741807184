import React, { useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import Utils from "../../utils/utils";

const GuidesEditSectionPhotoForm = ({
  type: { toOpen, index },
  handleClose,
  fields,
}) => {
  const [photo, setPhoto] = useState(fields.get(index)?.name || "");
  const [type] = useState(fields.get(index)?.type || "card");
  const [error, setError] = useState("");

  const handleEditPhotos = () => {
    let section = fields.get(index);
    section.name = photo.trim();
    section.type = type;

    if (
      fields.getAll().find((group, position) => {
        return position !== index && group.name.trim().toLowerCase() === section.name.toLowerCase();
      })
    ) {
      setError("Existe una sección con el mismo nombre");
      return false;
    }

    fields.splice(index, 1, section);
    handleClose();
  };

  return (
    <>
      <h2>Editar Sección {toOpen} </h2>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            label="Nombre"
            name={`${fields.name}[${index}].name`}
            value={photo}
            onChange={(event) => {
              setPhoto(event.target.value);
              if (
                !fields
                  .getAll()
                  .find((group) => group.name === event.target.value)
              ) {
                setError('');
              }
            }}
            fullWidth
            error={!photo || error}
            helperText={Utils.validation.isRequired(photo) || error}
          />
        </Grid>

        <Grid container justify="space-between">
          <Button
            variant="outlined"
            style={{ marginRight: 16 }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            onClick={photo.trim() ? handleEditPhotos : () => setPhoto("")}
            disabled={!photo}
            type="button"
            variant="contained"
            color="primary"
          >
            Aceptar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default GuidesEditSectionPhotoForm;
