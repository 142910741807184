import React from 'react';
import { Snackbar, SnackbarContent, Slide, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { hideError } from './../../actions/messagesActions';

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: red[500],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

const MessageError = () => {
  const classes = useStyles();
  const messages = useSelector(state => state.messages);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideError(false));
  };

  return (
    <Snackbar
      open={messages && messages.error}
      onClose={handleClose}
      autoHideDuration={5000}
      TransitionComponent={SlideTransition}
      >
        <SnackbarContent
          className={classes.error}
          message={
            <span className={classes.message}>
              <ErrorIcon className={`${classes.icon} ${classes.iconVariant}`} />
              {messages.error && messages.error}
            </span>
          }
          action={[
            <IconButton key="close" color="inherit" onClick={handleClose}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
    </Snackbar>
  );
};

export default MessageError;
