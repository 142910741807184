import { Ability } from "@casl/ability";
import jwt_decode from "jwt-decode";

const configAbility = (token) => {
	let permissions = [];

	if (token) {
		const decoded = jwt_decode(token);
		const permissionsToken = decoded.user.permissions;

		if (permissionsToken.hasOwnProperty("Inspections")) {
			Object.keys(permissionsToken.Inspections).map((subject) => {
				const result = permissionsToken.Inspections[subject].map(
					(permission) => {
						return {
							subject: subject,
							action: permission,
						};
					}
				);
				permissions.push(...result);
			});
		}
	}
	return new Ability([...permissions]);
};

export default configAbility;
