import { createAsyncAction } from 'redux-promise-middleware-actions';
import { authUrlApi, validateResponse, exeptionCodeResponse } from '../utils/urls';

export const loginUserAPI = createAsyncAction(
  'POST_LOGIN',
  async (bodyReq) => {
    const headers = { 'Content-Type': 'application/json' };
    const body = JSON.stringify(bodyReq);
    const res = await fetch(`${authUrlApi}/login`, { method: 'POST', headers, body })
      .then((response) => validateResponse(response))
      .catch((error) => {
        throw exeptionCodeResponse(error);
      });
    return res;
  }
);