import React from "react";
import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
import { store } from "./stores/store";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Routes from "./ui/routes";

import "./App.css";
import MomentUtils from "@date-io/moment";
import theme from "./theme";
import AuthHandler from "./ui/authHandler";

const App = () => {
  return (
    <Provider store={store}>
      <AuthHandler>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Routes />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </AuthHandler>
    </Provider>
  );
};

export default App;
