import React from "react";
import { useEffect, useState } from "react";
import { Typography, Grid, Divider } from "@material-ui/core";
import { useStyles } from "./styles";
import { getWordSpanish } from "../../utils/aiTerms";

const SummaryTab = ({ data, brands, years, version, models }) => {
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [damagesSummary, setDamagesSummary] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    getBrandName();
    getModelName();
    detectionSummary();
  }, []);

  const getBrandName = async () => {
    const brandsInspection = brands.filter(
      (brand) => brand._id === data.brandId
    );
    setBrand(brandsInspection[0].name);
  };

  const getModelName = async () => {
    const modelsInspection = models.filter(
      (model) => model._id === data.modelId
    );
    setModel(
      modelsInspection[0] && modelsInspection[0].name
        ? modelsInspection[0].name
        : ""
    );
  };

  const detectionSummary = () => {
    let arrayDamage = [];
    const detection = data.detectionSummary;
    detection.forEach((item) => {
      for (let key in item) {
        if (item.hasOwnProperty(key)) {
          let damagesDetails = item[key].damages_details;
          for (let i = 0; i < damagesDetails.length; i++) {
            arrayDamage.push(damagesDetails[i]);
          }
        }
      }
    });
    summaryDamages(arrayDamage);
  };

  const summaryDamages = (damages) => {
    if (damages && damages.length) {
      let sumDamages = damages
        .filter((item) => item.eval_damage > 0.5)
        .map((item) => ({
          part: item.part,
          type: item.type,
          eval_damage: item.eval_damage,
        }));
      setDamagesSummary(sumDamages);
    }
  };

  return (
    <>
      <div>
        <Grid container className={classes.titleSection}>
          <Grid item xs={12}>
            <Typography variant="h4">Resumen de daños</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ color: "#26ADF8" }}>
              Daño Estimados Iniciales
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.formElStyles}>
              <div
                className={classes.blockInfo}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className={classes.titleInfoMinor}>
                  <strong>MARCA</strong>
                </span>
                <span>{brand.toUpperCase()}</span>
              </div>
              <br />
              <div
                className={classes.blockInfo}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className={classes.titleInfoMinor}>
                  <strong>MODELO</strong>
                </span>
                <span>{model.toUpperCase()}</span>
              </div>
              <br />
              <div
                className={classes.blockInfo}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className={classes.titleInfoMinor}>
                  <strong>AÑO</strong>
                </span>
                <span>{data.year}</span>
              </div>
              <br />
              <div
                className={classes.blockInfo}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className={classes.titleInfoMinor}>
                  <strong>NOMBRE</strong>
                </span>
                <span>{`${data.name.toUpperCase()} ${data.lastname.toUpperCase()}`}</span>
              </div>
              <br />
              <div
                className={classes.blockInfo}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className={classes.titleInfoMinor}>
                  <strong>TELEFONO</strong>
                </span>
                <span>{data.phone.toUpperCase()}</span>
              </div>
              <br />
              <div
                className={classes.blockInfo}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className={classes.titleInfoMinor}>
                  <strong>CORREO</strong>
                </span>
                <span>{data.email.toUpperCase()}</span>
              </div>
              <br />
            </div>
          </Grid>
          <>
            <Grid item xs={12}>
              <div className={classes.formElStyles}>
                <span style={{ color: "#19A8F8", fontWeight: 700 }}>
                  Detalle de reparaciones
                </span>
                <div>
                  {damagesSummary &&
                    damagesSummary.length ?
                    (
                      damagesSummary.map((damage) => (
                      <>
                        <div
                          className={classes.blockInfo}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span className={classes.titleInfoMinor}>
                            <strong>{getWordSpanish(damage.part).toUpperCase()}</strong>
                          </span>
                        </div>
                        <br />
                        <div
                          className={classes.blockInfo}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span className={classes.titleInfoMinor}>
                            <strong>{getWordSpanish(damage.type).toUpperCase()}</strong>
                          </span>
                          <span className={classes.percentageDamage}>{(damage.eval_damage * 100).toFixed(2)}%</span>
                        </div>
                        <Divider style={{ marginTop: '5px' }} />
                      </>
                    )
                    
                    )): (
                      <Typography variant="h6" align="center" fontWeight="bold">No hay detalles de daños.</Typography>
                    )}
                </div>
              </div>
            </Grid>
          </>
        </Grid>
      </div>
    </>
  );
};

export default SummaryTab;
