export const setFilterList = (filterList) => async (dispatch) => {
	dispatch({type:'SET_FILTER_LIST', payload: filterList});
};

export const deleteFilterList = (filterElemtToList) => async (dispatch) => {
	dispatch({type:'DELETE_FILTER_LIST', payload: filterElemtToList});
};

export const setGlobalFilter = (criteria) => async (dispatch) => {
	console.log('criteria: ', criteria)
	dispatch({type:'SET_GLOBAL_INSPECTIONS_FILTER', payload: criteria});
};

export const resetFilterList = () => (dispatch) => {
	dispatch({type: 'RESET_FILTER_LIST'});
};