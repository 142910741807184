import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAIPhotosStructure } from "../../../actions/inspectorAIActions";
import { storageImageToInspection } from "../../../sevices/inspectionService";
import Loading from '../../loading/loadingCircular';
import { bytesArrayOpt, composeFormDataGuideImg } from "../../../utils/images";
import InputFile from "../../InputFile";
import PropTypes from 'prop-types';

const UploadPhotoSquare = ({
  section,
  position,
  id,
  inspectionId,
  title,
  defaultImage,
}) => {

  const [loadingImage, setLoadingImage] = useState(false);
  const ai = useSelector((state) => state.ai);
  const currentValue = ai.photos.find((value) => value.name === title);
  const dispatch = useDispatch();

  const handleUploadGuidePhoto = file => {
    const fileByteArray = bytesArrayOpt(file);
    const formDataImg = composeFormDataGuideImg('image/jpeg', fileByteArray, inspectionId, title, 'Externa', 0);
    storageImageToInspection(formDataImg, () => setLoadingImage(false));
  }

  const handleOnChange = async ({ target }) => {
    setLoadingImage(true);
    const fileImage = target.files[0];
    const formData = new FormData();

    formData.append("file", fileImage);

    dispatch(updateAIPhotosStructure({photo: fileImage, name: title}));
    const reader = new FileReader();

    reader.onload = (e) => {
      handleUploadGuidePhoto(e.target.result);
    };

    reader.readAsDataURL(fileImage);
  };

  return (
    <div style={{position: 'relative', margin:'40px 10px', minHeight: '120px',maxHeight: '120px', minWidth: '120px',maxWidth: '120px'}}>
        <div style={{zIndex: 0}}>
          <InputFile 
            onChange={handleOnChange} 
            name={title} 
            valueFile={currentValue?.photo}
          />
        </div>
        <div style={{transform: 'translateY(-200%) translateX(10px)', zIndex: 1}}>
        {
          loadingImage &&
          <Loading />
        }
        </div>
    </div>
  );
};

UploadPhotoSquare.propTypes = {
  section: PropTypes.string,
  position: PropTypes.number,
  id: PropTypes.string,
  title: PropTypes.string,
  defaultImage: PropTypes.any,
};

export default UploadPhotoSquare;