export const bytesArrayOpt = bytesArr => {
  
    try { 
      let base64data;
      if ( bytesArr.includes('data:image/jpg;base64,') ) {
        base64data = bytesArr.split('data:image/jpg;base64,');
        
      }

      if ( bytesArr.includes('data:image/jpeg;base64,') ) {
        base64data = bytesArr.split('data:image/jpeg;base64,');  
      }

      if ( bytesArr.includes('data:image/png;base64,') ) {
        base64data = bytesArr.split('data:image/png;base64,');
      }

      
      const byteCharacters = atob(base64data[1]);
      const sliceSize=512;
    
      const optimizedBytesArray = [];
    
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
    
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        optimizedBytesArray.push(byteArray);
      }
    
      return optimizedBytesArray;
      
    } catch (e) {
      return [];
      console.log('foto no subida correctamente por: ', e)
    }
}

export const composeFormDataImgObj = (blobType, byteArrays, type, id, meta, companyId) => {
  
  const blob = new Blob(byteArrays, {type: blobType});
  let formDataobj = new FormData();
  formDataobj.append('file', blob);
  formDataobj.append('type', type);
  formDataobj.append('id', id);
  formDataobj.append('meta', meta);
  formDataobj.append('companyId', companyId);

  return formDataobj;
}

export const composeFormDataGuideImg = (blobType, byteArrays, id, name, sectionId, position) => {
  
  const blob = new Blob(byteArrays, {type: blobType});
  let formDataobj = new FormData();
  formDataobj.append('file', blob);
  formDataobj.append('name', name);
  formDataobj.append('id', id);
  formDataobj.append('sectionId', sectionId);
  formDataobj.append('position', position);

  return formDataobj;
}
