import React from 'react'
import { Grid, IconButton, Drawer, Typography } from '@material-ui/core'
import { SettingsOutlined, Close } from '@material-ui/icons'

import NotificationsEditForm from '../../notificationsEditForm'
import { useStyles } from './styles'

const DrawerConfiguration = ({
  userEvent,
  onUpdateNotificationConfig,
  theme,
  openNotiConfig,
  handleOpenClose
}) => {
  const classes = useStyles({ theme })

  const handleUpdateNotificationConfig = async (updatedEvents) => {
    await onUpdateNotificationConfig(updatedEvents)
    handleOpenClose(false)
  }

  return (
    <Drawer
      ModalProps={{
        disableBackdropClick: true,
        hideBackdrop: true
      }}
      anchor='right'
      open={openNotiConfig}
      classes={{
        paper: classes.configMenu
      }}
      onClose={() => {
        handleOpenClose(false)
      }}
    >
      <Grid
        justify='space-between'
        alignItems='center'
        container
        className={classes.drawerPaper}
      >
        <IconButton
          style={{ alignSelf: 'flex-end' }}
          aria-label='close config menu'
          onClick={() => {
            handleOpenClose(false)
          }}
        >
          <Close />
        </IconButton>
        <Grid item container justify='center' alignItems='center'>
          <Typography component='h3'>
            Configuracion de notificaciones
          </Typography>
          <SettingsOutlined
            style={{ color: '#000', marginLeft: 20 }}
            fontSize='large'
          />
        </Grid>
      </Grid>
      <Grid container>
        <NotificationsEditForm
          events={userEvent.data}
          handleUpdate={handleUpdateNotificationConfig}
          theme={theme}
        />
      </Grid>
    </Drawer>
  )
}

export default DrawerConfiguration
