import React, { useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import Utils from "../../utils/utils";

const GuidesAddSectionPhotoForm = ({ fields, handleClose }) => {
  const [section, setSection] = useState(" ");
  const [error, setError] = useState("");
  const handleAddSection = () => {
    if (fields.getAll().find((group) => group.name.toLowerCase() === section.trim().toLowerCase())) {
      setError("Existe una sección con el mismo nombre");
      return false;
    }

    let newSection = { name: section.trim(), type: "card", related: [] };
    fields.push(newSection);
    handleClose();
    setSection("");
  };

  return (
    <>
      <h2>Crear Sección</h2>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            className="formInput"
            label="Nombre"
            name={`${fields.name}.name`}
            value={section}
            onChange={(event) => {
              setSection(event.target.value);
              if (
                !fields.getAll().find((group) => group.name === event.target.value)
              ) {
                setError("");
              }
            }}
            fullWidth
            error={!section || error}
            helperText={Utils.validation.isRequired(section) || error}
          />
        </Grid>
        <Grid container justify="space-between">
          <Button
            variant="outlined"
            style={{ marginRight: 16 }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            onClick={section.trim() ? handleAddSection : () => setSection("")}
            disabled={!section}
            type="button"
            variant="contained"
            color="primary"
          >
            Aceptar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default GuidesAddSectionPhotoForm;
