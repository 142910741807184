import React, { Fragment, useEffect } from 'react'
import {
  Grid,
  Drawer,
  Container,
  Typography,
  Button,
  IconButton
} from '@material-ui/core'
import { Close, NotificationsOutlined } from '@material-ui/icons'
import Handlebars from 'handlebars'

import StyledBadges from '../../styledBadges'
import { useStyles } from './styles'

const DrawerDetailNotification = ({
  open,
  close,
  notification,
  handleRead,
  theme
}) => {
  const classes = useStyles({ theme })

  useEffect(() => {
    if (Object.keys(notification).length > 0) {
      if (!notification.read) {
        handleRead(notification)
      }
    }
  }, [notification])

  const template = Handlebars.compile(`${notification?.message?.content}`)

  return (
    <Fragment>
      <Drawer
        variant='persistent'
        anchor='right'
        classes={{
          paper: classes.drawerPaperDrawRight
        }}
        open={open}
        onClose={close}
      >
        <Grid className={classes.widthDrawerProfile}>
          <IconButton
            style={{ alignSelf: 'flex-end' }}
            aria-label='close details'
            onClick={close}
          >
            <Close />
          </IconButton>
          {notification && (
            <Container>
              <Grid container style={{ padding: '30px 20px' }}>
                <Grid
                  container
                  direction='row'
                  justify='center'
                  alignItems='center'
                >
                  <StyledBadges
                    variant='dot'
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    overlap='circle'
                    color='secondary'
                    className={classes.styledBadges}
                    theme={theme}
                  >
                    <NotificationsOutlined
                      style={{ color: '#333333', fontSize: 50 }}
                    />
                  </StyledBadges>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.titleMessage}>
                      {notification?.message?.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div dangerouslySetInnerHTML={{ __html: template() }} />
                  </Grid>
                  {/* <Grid item xs={12}>
                        <Typography className={classes.messageNotification}>
                            iConnectance - {message.content}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.numberNotification}>
                            #0015
                        </Typography>
                        <LinearProgress variant="determinate" value={progress} />
                        <Typography className={classes.titleNumberNotification}>
                            Denuncia en revisión
                        </Typography>
                    </Grid> */}
                  <Grid
                    item
                    xs={12}
                    container
                    direction='row'
                    justify='center'
                    alignItems='center'
                    style={{ paddingTop: 30, paddingBottom: 30 }}
                  >
                    <Button
                      onClick={close}
                      variant='contained'
                      color='primary'
                      className={classes.btnAccept}
                    >
                      ACEPTAR
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          )}
        </Grid>
      </Drawer>
    </Fragment>
  )
}

export default DrawerDetailNotification
