import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAIJson, setAIPhotosStructure } from "../../actions/inspectorAIActions";
import { getDamageEstimation } from '../../sevices/inspectorAIservice';
import { getGuideInfoWithId } from '../../sevices/inspectionService';
import { gridPhotosFromGuideUploadInput } from '../../components/videchat/PhotoSquare/templates';
import { getParams } from '../../utils';
import { useStyles } from "./styles";

const UploadPhotos = () => {
  const classes = useStyles();
  
  const dispatch = useDispatch();
  const [thisGuideInfo, setGuideInfo] = useState(null);
  const [loadingDmgEstim, setLoadingDmgEstim] = useState(false);
  const ai = useSelector((state) => state.ai);
  const currentValue = ai.photos.filter((value) => value.photo);
  const params = getParams(window.location.href);

  const retrieveBasicData = async () => {
    const guideInfo = await getGuideInfoWithId(params.id);
    setGuideInfo(guideInfo);
    dispatch(setAIPhotosStructure({photosArr: guideInfo.photos.find(value => value.name === 'Externa').related}));
  }
  
  useEffect(() => {
    retrieveBasicData();
    return () => null;
  },[]);

  const handleOnClick = async () => {
    let formData = new FormData();
    setLoadingDmgEstim(true);
    const result = await getDamageEstimation(params.id);
    dispatch(setAIJson({aIData: JSON.parse(result), title: 'resultados:'}));
    setLoadingDmgEstim(false);
  };
  
  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {gridPhotosFromGuideUploadInput(thisGuideInfo, ['Interna', 'Documentacion'], params.tmp)}
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {
          !thisGuideInfo && 
          <></>
        }
        {
          thisGuideInfo && 
            <button 
              className={(currentValue?.length === 0 || loadingDmgEstim) ? classes.btnAnaDisab : classes.btnAna}
              onClick={handleOnClick}
              disabled={(currentValue?.length === 0 || loadingDmgEstim)}
            >{!loadingDmgEstim ? "Analizar" : "Analizando..."}</button>
        }
      </div>
    </>
  );
};

export default UploadPhotos;