import React, { useEffect, useState } from "react";
import { Chart, defaults, Bar } from "react-chartjs-2";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
//import merge from 'lodash.merge';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px',
    borderRadius: 15
  }
}));
/*
merge(defaults, {
  global: {
    legend: {
      display: false
    },
    line: {
      borderColor: '#F85F73',
     },
  },
});
*/
const VALUES_TO_FILTER = ['Ultimos 30 dias', 'Ayer', 'Hoy'];

const BarGraph = ({ data, labels, filterData }) => {
  const classes = useStyles();
  const options = {
    cornerRadius: 4,
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero: true,
              stepSize: 1
          }
      }]
    }
  };

  const [dataToGraph, setDataToGraph] = useState(
    {
      labels, 
      datasets: [
        {
          backgroundColor: [
            '#C4C4C4',
            '#27AE60',
            '#F2C94C',
            '#EB5757',
            '#F2994A'
          ],
          label: null,
          data: data
        }
      ]
    }
  );

  useEffect(()=>{
    Chart.pluginService.register({
      
      afterDraw: function (chart, easing) {
        chart.options.datasets.showLines = false;
        // if (this.options.curvature !== undefined && this.options.curvature <= 1) {
        //     var rectangleDraw = this.datasets[0].bars[0].draw;
        //     var self = this;
        //     var radius = this.datasets[0].bars[0].width * this.options.curvature * 0.5;

        //     // override the rectangle draw with ours
        //     this.datasets.forEach(function (dataset) {
        //         dataset.bars.forEach(function (bar) {
        //             bar.draw = function () {
        //                 // draw the original bar a little down (so that our curve brings it to its original position)
        //                 var y = bar.y;
        //                 // the min is required so animation does not start from below the axes
        //                 bar.y = Math.min(bar.y + radius, self.scale.endPoint - 1);
        //                 // adjust the bar radius depending on how much of a curve we can draw
        //                 var barRadius = (bar.y - y);
        //                 rectangleDraw.apply(bar, arguments);

        //                 // draw a rounded rectangle on top
        //                 Chart.helpers.drawRoundedRectangle(self.chart.ctx, bar.x - bar.width / 2, bar.y - barRadius + 1, bar.width, bar.height, barRadius);
        //                 ctx.fill();

        //                 // restore the y value
        //                 bar.y = y;
        //             }
        //         })
        //     })
        // }
      }
    });
  },[]);

  useEffect(()=>{
    setDataToGraph(
      {
        labels, 
        datasets: [
          {
            label: null,
            data: data
          }
        ]
      }
    )
  },[data]);

//   return {
//     labels,
//     datasets: [
//       {
//         label: "Estados de inspecciones",
//         data: qtysLeadsByStatus,
//         backgroundColor,
//         hoverBackgroundColor,
//         borderWidth: 5,
//         barThickness: 50,
//       },
//     ],
//   };

  const data_ = {
    labels,
    datasets: [
      {
        label: 'My First dataset',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data
      }
    ]
  };


// };
  
	return (
    <div>
      <Paper
        elevation={0}
        classes={{
          root: classes.root
        }}
      >
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
          <h4>Inspecciones</h4>
          <FormControl>
            <Select
              disableUnderline
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterData ? 30 : 0}
              onChange={()=>{}}
            >
              {
                filterData === null &&
                VALUES_TO_FILTER.map(
                  (value, index) => (
                    <MenuItem value={10*index}>{value}</MenuItem>
                  )
                )
              }
              {
                filterData !== null &&
                [...VALUES_TO_FILTER, filterData].map(
                  (value, index) => (
                    <MenuItem value={10*index}>{value}</MenuItem>
                  )
                )
              }
            </Select>
          </FormControl>
        </div>
        <div>
          <Bar 
            data={dataToGraph}
            options={options}
            width={10}
            height={5}
          />
        </div>
      </Paper>
    </div>
  );
};

export default BarGraph;