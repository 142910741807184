import React, { useEffect, useState } from "react";
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import socketIOClient from "socket.io-client";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton, Badge, withStyles } from '@material-ui/core';
import { NotificationsOutlined } from '@material-ui/icons';
import List from '@material-ui/core/List';
import moment from 'moment';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import { eventsUrlApi } from '../../../../utils/urls';
import DrawerDetailNotificationBarMenu from "../../../notifications-ui/components/drawers/drawerNotificationBarMenu";
import { useGetNotifications } from '../../../../hooks/notifications';

import { MODULE_NAME } from '../../utils/urls';
import { Util } from '../../utils';
import { 
  getAllNotifications, 
  updateNotificationReadStatus, 
  getEventsByUserIdAPI, 
  getEventsByUserIdAPINew 
} from '../../services';
import { 
  setNotifications, 
  setEventsNames
} from '../../../../actions/notificationsActions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '350px',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    position: 'absolute',
    right: '-120px'
  },
  rootSubheaderWrapper: {
    width: '350px',
    overflow: 'auto'
  },
  rootSubheader: {
    borderRadius: '10px 0px',
  },
  rootTypo:{
    color: '#6716F5'
  },
  inline: {
    display: 'inline',
  },
  rootBadge: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  shapeBadge: {
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
  },
  shapeCircleBadge: {
    borderRadius: '50%',
  },
  rootNotif : {
    '&:hover':{
      background: '#E4DAF9',
      cursor: 'pointer'
    },
    backgroundColor: theme.palette.background.paper,
  },
  rootNotifUnread : {
    '&:hover':{
      background: '#E4DAF9',
      cursor: 'pointer'
    },
    backgroundColor: '#C8B4F3',
    overflow: 'auto',
    maxHeight: 300
  },
  hover: {
    '&:hover':{
      background: '#E4DAF9',
      cursor: 'pointer'
    }
  },
  drawerPaper: {
    borderRadius: '10px',
    // height: '80%'
  },
}));


const StyledBadge = withStyles({
  anchorOriginBottomLeftCircle: {
    left: '5%',
    bottom: '5%'
  },
  colorSecondary: {
    backgroundColor: '#00A0F8'
  }
})(Badge);

const NotificationsDrawer = ({ token, url= `${eventsUrlApi}/notification/`}) => {

  const classes = useStyles();
  
  const dispatch = useDispatch();
  const [notificationsList, setNotificationsList] = useState([]);
  const [statusNotifList, setStatusNotifList] = useState(false);
  const [numberOfNotifications, setNumberOfNotifications] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isThereNoReadNotf, setIsThereNoReadNotf] = useState(true);
  const userNotifications = useSelector((state) => state.userEvents);
  const dataNotif = userNotifications.notifications;

  const handleStatusNotifList = () => {
    setStatusNotifList(!statusNotifList);
  }

  const showMoreNotif = () => {
    return (
      <>
        <ListItem 
          alignItems="flex-start"
          onClick={ () => navigate(`/notifications`) }
          classes={{
            root: classes.rootNotif
          }}
        >
          <ListItemText
            primary={<strong><p style={{color:'#333', textAlign:'center', margin:0}}>Ver mas</p></strong>}
          />
        </ListItem>
      </>
    )
  }

  const handleItemClick = () => {
    setStatusNotifList(!statusNotifList);
    navigate(`/notifications`);
  }

  const generateList = () => {
    return (
      <List
        height={400} 
        width={400} 
        itemSize={46} 
        itemCount={200}
        classes={{
          root: classes.rootSubheaderWrapper
        }}
        subheader={
          <>
            <ListSubheader
              classes={{
                root: classes.rootSubheader
              }}
              disableSticky={true}
            >
              <div 
                style={{
                  display:'flex', 
                  justifyContent:'space-between', 
                  alignItems:'center',
                  padding:'10px 0px'
                }}
              >
                <Typography
                  color="primary"
                >
                  <strong>
                    Notificaciones GG
                  </strong>
                </Typography>
                <CloseIcon
                  onClick={()=>setStatusNotifList(false)}
                />
              </div>
            </ListSubheader>
            <Divider variant="middle" component="li" />
          </>
        }
      >
        { 
          notificationsList.length > 0 &&
          notificationsList.map ( 
            (notification, index) => {
              const _date = moment(notification.sentAt).format('hh:mm   DD/MM/YYYY').split('   ');
              return (
                <React.Fragment key={'notif'+index}>
                  <ListItem 
                    onClick={handleItemClick}
                    alignItems="flex-start"
                    classes={{
                      root: !notification?.read ? classes.rootNotifUnread : classes.rootNotif
                    }}
                  >
                    <div style={{display:'flex', flexDirection:'column'}}>
                      <ListItemText
                        primary={<strong style={{color:'#333'}}>{notification?.message?.title}</strong>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {notification?.message?.content}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      <div style={{display:'flex', justifyContent:'flex-end', color:'#333'}}>
                        <small style={{color:'#D8D8D8'}}><i>{`${_date[1]}   `}</i></small><small style={{marginLeft:5}}><i>{_date[0]}</i></small>
                      </div>
                    </div>
                  </ListItem>
                  <Divider variant="middle" component="li" />
                </React.Fragment>
              )      
            }
          )
        }
        { notificationsList.length > 0 && showMoreNotif()}
        {
          notificationsList.length ===0  &&
          <ListItem>
            <div style={{display:'flex', flexDirection:'column'}}>
              <ListItemText
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      No posee notificaciones actualmente
                    </Typography>
                  </React.Fragment>
                }
              />
            </div>
          </ListItem>
        }
      </List>
    )
  }

	return (
    <div style={{position:'relative', display: 'inline-block'}}>
      <IconButton
        color="inherit" 
        onClick={() => handleStatusNotifList()}
        style={{
          cursor:'pointer'
        }}
      >
        {
            isThereNoReadNotf && 
            <NotificationsOutlined style={{ color: '#828282' }} />
          }
        {
          !isThereNoReadNotf &&   
          <StyledBadge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            overlap="circular"
            badgeContent={numberOfNotifications}
            color="secondary">
              <NotificationsOutlined style={{ color: '#828282' }} />
            </StyledBadge>
          }
      </IconButton>
      <DrawerDetailNotificationBarMenu 
        userEvent={{notifications: dataNotif || []}}
        open={statusNotifList}
        goBack={() => setStatusNotifList(false)}
      />
      {
        false &&
        <Drawer 
          anchor={'right'} 
          open={statusNotifList} 
          onClose={() => setStatusNotifList(false)}
          BackdropProps={{ invisible: true }}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {generateList()}
        </Drawer>
      }

    </div>
  );
};

export default NotificationsDrawer;