import React, { useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import Utils from "../../utils/utils";

const GuidesEditPhotoTypeForm = ({
  type: { toOpen, index, position },
  handleClose,
  fields,
}) => {
  const [photo, setPhoto] = useState(
    fields.get(index)?.related[position]?.name || " "
  );
  const [description, setDescription] = useState(
    fields.get(index)?.related[position]?.description || ""
  );
  const [error, setError] = useState("");
  const handleEditPhotos = () => {
    let newPhoto = {
      name: photo.trim(),
      description,
      position: fields.get(index).related[position].position,
    };

    if (
      fields
        .get(index)
        .related.find(
          (relate) =>
            relate.position !== newPhoto.position &&
            relate.name.trim().toLowerCase() === newPhoto.name.toLowerCase()
        )
    ) {
      setError("Existe una foto con el mismo nombre");
      return false;
    }

    fields.get(index).related.splice(position, 1, newPhoto);
    handleClose();
  };

  return (
    <>
      <h2>Editar Foto {toOpen} </h2>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            label="Nombre"
            name={`${fields.name}[${index}].related[${position}].name`}
            value={photo}
            onChange={(event) => {
              setPhoto(event.target.value);
              if (
                !fields
                  .get(index)
                  .related.find((relate) => relate.name === event.target.value)
              ) {
                setError("");
              }
            }}
            fullWidth
            error={!photo || error}
            helperText={Utils.validation.isRequired(photo) || error}
          />
          <TextField
            label="Descripción"
            name={`${fields.name}[${index}].related[${position}].description`}
            multiline
            fullWidth
            rows={6}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Grid>
        <Grid container justify="space-between">
          <Button
            variant="outlined"
            style={{ marginRight: 16 }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            onClick={photo.trim() ? handleEditPhotos : () => setPhoto("")}
            disabled={!photo}
            type="button"
            variant="contained"
            color="primary"
          >
            Aceptar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default GuidesEditPhotoTypeForm;
