import React, { Fragment, useState, useEffect, useRef } from 'react';
import axios, { post } from 'axios';
import exifr from 'exifr/dist/full.esm.mjs';
import Loading from "../../../components/loading/loadingCircular";
import { Button, Grid, Card, CardActionArea, CardContent, CardMedia, Modal, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCommentComponent from '../AddCommentComponent';
import { theme } from '../../../utils/themeToastUi';
import SinDanos from '../../../assets/images/sin_danos.png';
import Rayon from '../../../assets/images/rayon.png';
import Abolladura from '../../../assets/images/abolladura.png';
import Empty from '../../../assets/images/empty.jpg';
import InnerImageZoom from 'react-inner-image-zoom';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import Utils from '../../../utils/utils';
import { inspectionApiUrl } from '../../../utils/urls';
import { convertDataFromAI } from '../../../utils/aiUtility';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { Edit, FindInPage, ImageSearch, SettingsBackupRestore, ZoomOutMap, Android } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from './style';
import '../../../styles/components.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';

const _useStyles = makeStyles((theme) => ({
  root: {
    color: '#4B4B4B',
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '500'
  },
  iconExpand: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#fff',
    cursor: 'pointer'
  },
  cardmedia: {
    backgroundSize: 'cover !important',
    width: 380,
    height: 380
  },
  comments: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.25px',
    color: '#FFFFFF'
  },
  commentsMeta: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.25px',
    color: '#FFFFFF',
    margin: 0
  },
  text: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.25px',
    color: '#FFFFFF'
  }
}));

const CardPhoto = ({ title, photo, data, id }) => {
  const _classes = _useStyles();
  const editorRef = useRef();
  const classes = useStyles();
  
  const [openModal, setOpenModal] = useState(false);
  const [openModalJSON, setOpenModalJSON] = useState(false);
  const [jsonRes, setOpenJson] = useState(null);

  const [loadingImage, setLoadingImage] = useState(true);
  const [loadingBackImage, setLoadingBackImage] = useState(true);
  const [showZoomAction, setShowZoomAction] = useState(false);
  const [image64, setImage64] = useState(null);
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [seeDetail, setSeeDetail] = useState(false);
  const [metadata, setMetadata] = useState([])

  useEffect(()=>{
    
    axios.post(`${inspectionApiUrl}/images/convert`, { image: photo }, {responseType: 'arraybuffer'})
      .then( ({ data }) =>{
          const base64Img = Buffer.from(data, 'binary').toString('base64');
          exifr.parse(data).then(
            output => {
              const metdata = [];
              for (const property in output) {
                metdata.push({[property]: output[property]})
              }
              setMetadata(metdata)
            }
          )
          
      })
      .catch( e => {
        console.log('error=>', e)
      });
  },[])

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModalJson = () => setOpenModalJSON(false);

  const handleOpenModal = () => {
    setOpenModal(!openModal)
  }

  const handlingBackImage = () => {
    setLoadingBackImage(false)
  }

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const handleEnableZoom = () => {
    setShowZoomAction(true)
  }

  const handleDisableZoom = () => {
    setShowZoomAction(false)
  }

  const bodyModal = (
    <div id="img-container" style={modalStyle} className={classes.paper}>
      {showZoomAction == false ?
        (
          <Fragment>
            <>
              {
                loadingImage &&
                <p style={{padding:10}}>loading</p>
              }
              {
                !loadingImage &&
                <ImageEditor
                  ref={editorRef}
                  includeUI={{
                    loadImage: {
                      path: image64,
                      name: 'imagen ' + title
                    },
                    theme: theme,
                    menu: ['shape', 'draw'],
                    // initMenu: 'filter',
                    uiSize: {
                      width: '800px',
                      height: '600px'
                    },
                    menuBarPosition: 'right'
                  }}
                  cssMaxHeight={700}
                  cssMaxWidth={700}
                  selectionStyle={{
                    cornerSize: 10,
                    rotatingPointOffset: 70
                  }}
                  usageStatistics={false}
                />
              }
            </>
          </Fragment>

        ) : (
          <div className={classes.zoomModal}>
              <PinchZoomPan maxScale={5}>
                <img src={photo} width="100%" height="100%" />
              </PinchZoomPan>
              <CloseIcon 
                onClick={handleOpenModal} 
                className={classes.headerModal}
              />
          </div>
        )}
    </div>
  );

  const handleSeeDetail = () => {
    setSeeDetail(!seeDetail);
  }

  const handleGetIAData = () => {
    setOpenModalJSON(true);
    axios.post(`${inspectionApiUrl}/images/convert`, { image: photo }, {responseType: 'arraybuffer'})
      .then( ({ data }) => {

          const base64Img = Buffer.from(data, 'binary').toString('base64');
          var file = new File([data], "file.jpg", {
            type: "image/jpeg",
          }); 
          const formData = new FormData();
          formData.append('file',file)
          const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
          }
          post(`https://test-inspectorapi.iconnectance.com/api/assessments`, formData, config)
            .then( ({ data }) =>{
              const tableAI = convertDataFromAI(data);
              setOpenJson(tableAI);
            })
            .catch( e => {
              setOpenModalJSON(false);
              setOpenJson(JSON.stringify('Hubo un problema. Cierre e intente de nuevo'));
            });
      })
      .catch( e => {
        console.log('error=>', e)
      });

    
  }

  const handleOpenZoom = () => {
    setOpenModal(true);
    handleEnableZoom();
  };

  const handleOpenEdit = () => {
    setOpenModal(true);
    handleDisableZoom();
    if (photo && loadingImage) {
      axios.post(`${inspectionApiUrl}/images/convert`, { image: photo }, {responseType: 'arraybuffer'})
        .then( ({ data }) =>{
            const base64Img = Buffer.from(data, 'binary').toString('base64');
            setImage64(`data:image/jpeg;base64,${base64Img}`)
            setLoadingImage(false);
        })
        .catch( e => {
          console.log('error=>', e)
        });
    }
  };

  return (
    <div style={{ padding: '10px 20px' }}>
      <>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={'sm'}
          >
            <DialogTitle id="alert-dialog-title"><strong>Agregar comentario</strong></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <AddCommentComponent id={id} close={handleClose} />
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Typography
            gutterBottom
            variant="h5"
            component="h3"
            classes={{
              root: _classes.root
            }}
          >
            {title}
          </Typography>
          <Card>
            <div
              style={{ position: 'relative' }}
            >
              <div className={`${classes.cardContainer} ${photo ? 'hasPhoto' : ''}`}>
                <>
                  <span style={{ float: 'right', marginTop: 10, marginRight: 10 }}>
                    { data.status && <CarCondition condition={data.status} />}
                  </span>
                  {
                    (seeDetail && photo) &&
                    <CardContent>
                      <div style={{ padding: '2px 30px' }}>
                        <h4 style={{
                          fontWeight: 500,
                          fontSize: '12px',
                          lineHeight: '18px',
                          letterSpacing: '0.25px',
                          color: '#FFFFFF'
                        }}>Metadatos:</h4>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                        >
                          {
                            <div>
                            {metadata && metadata.length ? (
                              
                                metadata.map(
                                  (value, index) => {

                                    return (
                                      <Typography
                                        paragraph
                                        id={value.key + index}
                                        classes={{
                                          root: _classes.commentsMeta
                                        }}
                                      >{`${Object.keys(value)[0]}: ${value[Object.keys(value)[0]]}`}
                                      </Typography>
                                    )
                                  }
                                )
                              
                            ) : (
                              <CircularProgress />
                            )}
                              
                            </div>
                          }
                        </Typography>
                        <h4 style={{
                          fontWeight: 500,
                          fontSize: '12px',
                          lineHeight: '18px',
                          letterSpacing: '0.25px',
                          color: '#FFFFFF'
                        }}>Comentarios:</h4>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          classes={{
                            root: _classes.comments
                          }}
                        >
                          {data.comments}
                        </Typography>
                      </div>
                    </CardContent>
                  }
                  <div className={classes.controls}>
                    <IconButton onClick={handleSeeDetail} className={`${classes.controlButtons} ${seeDetail ? 'toggle' : ''}`}>
                      <FindInPage />
                    </IconButton>
                    <IconButton onClick={handleGetIAData} className={`${classes.controlButtons} ${seeDetail ? 'toggle' : ''}`}>
                      <Android />
                    </IconButton>
                    <IconButton className={classes.controlButtons} onClick={handleOpenZoom}>
                      <ImageSearch />
                    </IconButton>
                    <IconButton className={classes.controlButtons} onClick={handleOpenEdit}>
                      <Edit />
                    </IconButton>
                  </div>
                </>
              </div>
              {
                photo &&
                  <img 
                    style={{display:'none'}}
                    src={photo}
                    onLoad={handlingBackImage}
                  />
              }
              {
                (loadingBackImage && photo != undefined) &&
                <CardContent>
                  <CircularProgress />
                </CardContent>
              }
              {
                (!loadingBackImage || photo == undefined) &&
                <CardMedia
                  classes={{
                    root: _classes.cardmedia
                  }}
                  className={classes.media}
                  image={photo || Empty}
                  title={title}
                  onClick={() => handleOpenModal()}
                />
              }
            </div>
          </Card>
          {
            photo &&
            <Modal
              open={openModal}
              onClose={handleOpenModal}
            >
              {bodyModal}
            </Modal>
          }
          {
            photo &&
            <Modal
              open={openModalJSON}
              onClose={()=>setOpenModalJSON(false)}
            >
              <section style={{ 
                width: "100vw",
                height: "100vh",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <div
                  style={{ 
                    background: 'white',
                    width: '80%',
                    margin: 'auto',
                    minHeight: '200px',
                    display: 'flex',
                    alignItems: 'center', 
                    justifyContent: 'center',
                  }}
                >
                  {
                    !jsonRes ?
                    <Loading /> :
                    <div
                      style={{ 
                        display: 'flex',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <p>{jsonRes}</p>
                      <div 
                        style={{
                          width: '100%',
                          display: 'flex', 
                          justifyContent: 'center', 
                          alignItems:'center'
                        }}
                      >
                        <Button variant="outlined" color="primary" onClick={handleCloseModalJson}>
                          Salir
                        </Button>
                      </div>
                    </div>
                  }
                </div>
              </section>
            </Modal>
          }
        </>
      
    </div>
  );
}

const CarCondition = ({ condition = "" }) => {

  const config = {};
  switch (condition) {
    case 'no_damage':
      config.background = '#E2F5EA';
      config.text = 'SIN DAÑO';
      config.img = SinDanos;
      break;

    case 'scratch':
      config.background = '#FCEBDB';
      config.text = 'RAYON';
      config.img = Rayon;
      break;

    case 'hit':
      config.background = '#FCEBDB';
      config.text = 'ABOLLADURA';
      config.img = Abolladura;
      break;

    default:
      config.background = '';
      config.text = '';
      config.img = '';
      break;
  }

  return (
    <span style={{
      display: 'flex',
      backgroundColor: config.background,
      height: 25,
      borderRadius: 27,
      paddingRight: 10
    }}>
      <img src={config.img} height="25" style={{ marginRight: '10px' }} />
      <p style={{ fontSize: 12, margin: 'auto 0' }}>{config.text}</p>
    </span>
  )
};

export default CardPhoto;