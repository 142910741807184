import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    padding: 20
  },
  media: {
    height: 380,
    zIndex: 2
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    minWidth: '45%'
  },
  imageModal: {
    width: '100%'
  },
  divZoom: {
    position: 'absolute',
    bottom: 0,
    height: 130,
    width: 150,
    zIndex: 2
  },
  iconZoom: {
    position: 'absolute',
    bottom: 15,
    right: 20,
    zIndex: 4,
    color: 'white',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  iconZoomNoPhoto: {
    position: 'absolute',
    bottom: 15,
    right: 20,
    zIndex: 4,
    color: 'white',
    '&:hover': {
      cursor: 'default'
    }
  },
  modalComment: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  cardContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.53)',
    position: 'absolute',
    width: '100%',
    height: 380,
    top: 0,
    left: 0,
    zIndex: 4,
    opacity: 0,
    transition: 'opacity .2s ease-in',
    '&.hasPhoto:hover': {
      opacity: 1
    }
  },
  controls: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'absolute',
    bottom: 10
  },
  controlButtons: {
    color: '#fff',
    border: '1px solid #fff',
    margin: '0px 2.5px',
    '&.toggle': {
      color: '#aaa',
      backgroundColor: '#fff'
    }
  },
  zoomModal: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 300,
    minHeight: 300,
    '& > div': {
      flex: 1
    }
  },
  headerModal: {
    position: 'absolute', 
    top: 10, 
    right: 10,
    cursor:'pointer'
  }
}));