import { loginUserAPI } from "../sevices/userService";

export const postLogin = (user) => async (dispatch) => {
	await dispatch(loginUserAPI(user));
};

export const getLogout = () => (dispatch) => {
	localStorage.clear();
	dispatch({ type: "GET_LOGOUT_FULFILLED" });
};

export const patchErrorLogin = () => (dispatch) => {
	dispatch({ type: "PATCH_LOGIN_ERROR" });
};

export const setAuthToken = (token) => (dispatch) => {
	dispatch({ type: "SET_AUTH_TOKEN", payload: token });
};