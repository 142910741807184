import { showMessage } from "../../../actions/messagesActions";
import { getNewNotification } from "../../../actions/userEventsActions";
import jwt_decode from "jwt-decode";

export const setUserEventListeners = (
  socket,
  events = [],
  token,
  dispatch,
  notificationLogo
) => {
  /* if (socket.disconnected) {
    socket.connect();
  } */

  socket.removeAllListeners();
  const decoded = jwt_decode(token);

  const handleRedirection = (evt) => {
    console.log("Triggered");
    evt.preventDefault();
    window.open(`${window.location.origin}/notifications`, "_blank");
  };
  const browserNotificationConfig = {
    body: "Tiene una nueva notificacion",
    icon: notificationLogo,
  };

  const trigger = () => {
    const notification = new Notification(
      "iConnectance",
      browserNotificationConfig
    );
    notification.onclick = handleRedirection;
  };
  events
    .filter(
      (event) => event.active && event.notificationType.toLowerCase() === "push"
    )
    .forEach((event) => {
      // Broadcast events
      socket.on(event.access_code, (data) => {
        console.log(data);
        dispatch(showMessage());
        trigger();
        dispatch(getNewNotification(token));
      });

      // User specific events
      socket.on(`${event.access_code}::${decoded.user.email}`, (data) => {
        console.log(data);
        dispatch(showMessage());
        trigger();
        dispatch(getNewNotification(token));
      });
    });
};
