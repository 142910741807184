import React, {useState, useEffect} from 'react';
import { Link, navigate } from '@reach/router';
import {
    Grid,
    Paper,
    Typography,
    Tabs,
    Tab,
    Box,
    Breadcrumbs
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import GuideTitle from '../../components/guides/guidesTitle';
import GuidesEditForm from '../../components/guides/guidesEditForm';
import { getGuides } from '../../actions/guidesActions';

const TabPanel = ({ children, value, index }) => {
    return (
        <Typography
            component="div"
            guide="tabpanel"
            hidden={value !== index}
            id={`guides-tabpanel-${index}`}
            aria-labelledby={`guides-tab-${index}`}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
};

const GuidesEdit = ({ id, location }) => {
    const dispatch = useDispatch();
    const guides = useSelector((state) => state.guides);
    const token = useSelector((state) => state.auth.token);
    const [valueTab, setValueTab] = useState(0);
 
    const handleChangeTab = (event, newValue) => setValueTab(newValue);

    const executeInitGuides = () => {
        if (typeof guideSelect?._id === 'undefined') {
            dispatch(getGuides(token));
        }
    };

    const handleSuccess = () => {
        navigate('/guides', { state: { pageGuide: location.state.pageGuide } });
    };

    useEffect(() => {
        if(!guides.data.length){
            executeInitGuides();
        }
    }, []);

    const guideSelect = guides.data.length > 0 ? guides.data.find(
        guide => guide._id === id
    ) : {}
    
    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                <div style={{ marginBottom: 16, padding: 16 }}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link to="/">Home</Link>
                        <Link to="/guides">Guias</Link>
                        <Link to="/guides">Editar</Link>
                    </Breadcrumbs>
                </div>
                <Paper style={{ padding: 16 }}>
                    <GuideTitle
                        typeForm="guideEdit"
                        handleSuccess={handleSuccess}
                        handleChangeTab={handleChangeTab}
                        location={location}
                    />
                    <Tabs
                        value={valueTab}
                        onChange={handleChangeTab}
                        aria-label="guide-tabs"
                    >
                        <Tab label="General" id="guide-tab-0" aria-controls="guide-tabpanel-0" />
                        
                    </Tabs>
                    <TabPanel value={valueTab} index={0}>
                        {
                            guideSelect.name && <GuidesEditForm initialValues={{...guideSelect, state: guideSelect?.state?._id, risk: guideSelect?.risk?._id}} />
                        }
                    </TabPanel>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default GuidesEdit;
