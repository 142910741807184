import React, { useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import Utils from "../../utils/utils";

const GuidesAddPhotoTypeForm = ({
  type: { toOpen, index },
  handleClose,
  fields,
}) => {
  const [photo, setPhoto] = useState(" ");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const handleAddPhotos = () => {
    if (
      fields.get(index).related.find((relate) => relate.name.toLowerCase() === photo.trim().toLowerCase())
    ) {
      setError("Existe una foto con el mismo nombre");
      return false;
    }

    let newPhoto = {
      name: photo.trim(),
      description,
      position: fields.get(index).related.length,
    };

    fields.get(index).related.push(newPhoto);
    handleClose();
    setPhoto("");
    setDescription("");
  };

  return (
    <>
      <h2>Agregar Foto {toOpen} </h2>

      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            label="Nombre"
            name="namePhoto"
            value={photo}
            onChange={(event) => {
              setPhoto(event.target.value);
              if (
                !fields
                  .get(index)
                  .related.find((relate) => relate.name === event.target.value)
              ) {
                setError("");
              }
            }}
            fullWidth
            error={!photo || error}
            helperText={Utils.validation.isRequired(photo) || error}
          />
          <TextField
            className="formInput"
            label="Descripción"
            name={`description`}
            multiline
            fullWidth
            rows={6}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Grid>

        <Grid container justify="space-between">
          <Button
            variant="outlined"
            style={{ marginRight: 16 }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            onClick={photo.trim() ? handleAddPhotos : () => setPhoto("")}
            disabled={!photo}
            type="button"
            variant="contained"
            color="primary"
          >
            Aceptar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default GuidesAddPhotoTypeForm;
