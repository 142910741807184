import React, { useState, useEffect } from 'react';
import { 
  Badge, 
  Drawer, 
  Divider, 
  Grid, 
  List, 
  ListItem,
  Typography, Button, Select, MenuItem } from '@material-ui/core';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { NotificationsOutlined } from '@material-ui/icons';

const StyledBadge = withStyles({
  root: {
    fontSize: '20px'
  },
  colorSecondary: {
    backgroundColor: '#00A0F8'
  },
  anchorOriginBottomLeftCircle: {
    left: '15%',
    bottom: '25%'
  },
  dot: {
    height: 8,
    minWidth: 8
  }
})(Badge);

const centeredEl = {
  display: 'flex',
  justifyContent: 'center'
};


const useStyles = makeStyles(theme => ({
  drawer: {
    width: '350px'
  },
  iconSize: {
    fontSize: '2.5rem !important'
  },
  drawerPaper: {
    width: '350px',
    borderRadius: '10px'
  },
  labelBtn: {
    fontSize: '10px',
    padding: '4px 12px'
  }

}));

const DetailNotification = ({ notifTitle = '', notifContent = '', stateDrawer = false, fnDrawerState }) => {
  const classes = useStyles(notifTitle, notifContent, stateDrawer, fnDrawerState);
  return (
    <Drawer 
        anchor={'right'} 
        open={stateDrawer} 
        onClose={fnDrawerState}
        BackdropProps={{ invisible: true }}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div style={{
          paddingTop: '40px',
          paddingLeft: '30px',
          paddingRight: '30px'
        }}>
          <div style={centeredEl}>  
            <StyledBadge
              variant="dot"
              classes={{
                root: classes.iconSize
              }}
              style={{fontSize: '20px !important'}}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              overlap="circular"
              color="secondary">
              <NotificationsOutlined style={{ fontSize: '40px', color: '#333333' }} />
            </StyledBadge>
          </div>
          <div style={centeredEl}>
              <h3 
                style={{fontSize:'15px', lineHeight:'24px'}}>{notifTitle}</h3>
          </div>
          <div>
              <p
                style={{fontSize:'14px', lineHeight:'24px'}}
              >{notifContent}</p>
          </div>
          <div style={centeredEl}>
            <Button 
              variant="contained" 
              color="primary"
              onClick={fnDrawerState}
              classes={{
                label: classes.labelBtn
              }}
            >
              Aceptar
            </Button>
          </div>
        </div>    
      </Drawer>
  )

}
        
export default DetailNotification;