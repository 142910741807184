import React, { useState } from 'react';
import { Link, navigate } from '@reach/router';
import Sticky from 'react-sticky-el';
import LoadingModal from '../modal/loadingModal';
import CancelModal from '../modal/cancelModal';
import { useDispatch, useSelector } from 'react-redux';
import { submit, reset, touch } from 'redux-form';
import { Grid, Typography, Button, Paper } from '@material-ui/core';
import { addInspection } from '../../actions/inspectionActions';
import { decodedToken } from '../../utils/index';

const InspectionTitle = ({ typeForm, handleSuccessInspection }) => {
    const dispatch = useDispatch();
    const inspectionForm = useSelector((state) => state.form);
    const token = useSelector((state) => state.auth.token);
    const [disabled, setDisabled] = useState(false);
    const auth = useSelector((state) => state.auth);
    const [openCancel, setOpenCancel] = useState(false);

    const handleSaveProduct = async () => {
        try {

            switch (typeForm) {
                case 'inspectionAdd':
                    dispatch(submit('InspectionAddForm'));
                    dispatch(touch('InspectionAddForm'));
                    const newInspect = await addNewInspection();
                    break;
                default:
                    break;
            }
        } catch (e) {
            console.log('error al guardar==>', e);
        }
    }

    const addNewInspection = async () => {
        const userInfo = decodedToken(auth.token)
        let newInspection = {};


        if (
            inspectionForm.InspectionAddForm.anyTouched &&
            !inspectionForm.InspectionAddForm.submitFailed &&
            !inspectionForm.InspectionAddForm.syncErrors
        ) {
            
            setDisabled(true);

            newInspection = inspectionForm.InspectionAddForm.values;
            newInspection.brandId = newInspection.brandId._id;
            newInspection.modelId = newInspection.modelId._id;
            newInspection.versionId = newInspection?.versionId?._id;
            delete newInspection.undefined
            newInspection.companyId = userInfo.user.companyId;
            newInspection.phone = newInspection.phone.replace(/\s/g,'');
            await dispatch(addInspection(newInspection));
            dispatch(reset('InspectionAddForm'));
            setDisabled(false);
            handleSuccessInspection();
            return;
        }
    }

    const handleBackPage = () => {
        setOpenCancel(true);
    };

    const handleAccept = () => {
        setOpenCancel(false);
        navigate('/inspections');
    };

    const handleCancel = () => {
        setOpenCancel(false);
    };

    return (
        <Sticky hideOnBoundaryHit={true} style={{ paddingTop: 16, zIndex: 200 }}>
            <Paper style={{ marginBottom: 16, boxShadow: 'none' }}>
                <Grid container>
                    <Grid item sm={6} xs={12}>
                        <Typography variant="h6">
                            Administración de Inspecciones
                        </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Grid container justify="flex-end">
                            <Button variant="outlined" style={{ marginRight: 16 }} onClick={() => handleBackPage()}>
                                Cancelar
                            </Button>
                            <Button type="submit" variant="contained" color="primary" onClick={() => handleSaveProduct()} disabled={disabled}>
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <CancelModal openModal={openCancel} handleAccept={handleAccept} handleCancel={handleCancel} />
        </Sticky>
    );
}
 
export default InspectionTitle;