import React, { useState, useEffect } from "react";
import { Link, navigate } from "@reach/router";
import {
  Grid,
  Breadcrumbs,
  Paper,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Sticky from "react-sticky-el";
import GuidesShowForm from "../../components/guides/guidesShowForm";
import { getGuides } from "../../actions/guidesActions";

const TabPanel = ({ children, value, index }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`guide-tabpanel-${index}`}
      aria-labelledby={`guide-tab-${index}`}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

const GuidesShow = ({ id }) => {
  const dispatch = useDispatch();
  const guides = useSelector((state) => state.guides);
  const token = useSelector((state) => state.auth.token);
  const [valueTab, setValueTab] = useState(0);
  const guideSelect =
    guides.data.length > 0 ? guides.data.find((guide) => guide._id === id) : {};

  const executeInitGuides = () => {
    if (typeof guideSelect._id === "undefined") {
      dispatch(getGuides(token));
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    executeInitGuides();
  }, []);

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <div style={{ marginBottom: 16, padding: 16 }}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link to="/">Home</Link>
            <Link to="/guides">Guías</Link>
            <Link to="#">Detalle</Link>
          </Breadcrumbs>
        </div>
        <Paper style={{ padding: 16 }}>
          <Sticky
            hideOnBoundaryHit={true}
            style={{ paddingTop: 16, zIndex: 200 }}
          >
            <Paper style={{ marginBottom: 16, boxShadow: "none" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h6">Guías</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify="flex-end">
                    <Button
                      variant="contained"
                      style={{ marginRight: 16 }}
                      onClick={() => navigate("/guides")}
                    >
                      Regresar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Sticky>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            aria-label="guides-tabs"
          >
            <Tab
              label="General"
              id="guides-tab-0"
              aria-controls="guides-tabpanel-0"
            />
          </Tabs>
          <TabPanel value={valueTab} index={0}>
            {guideSelect.name && <GuidesShowForm initialValues={guideSelect} />}
          </TabPanel>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default GuidesShow;
