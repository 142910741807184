import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

const ItemDelete = ({ tagModal, title, openModal, handleAccept, handleCancel }) => {
    return (
        <Dialog open={openModal} onClose={handleCancel}>
            <DialogTitle>{`Eliminar ${tagModal}: ${title}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
          Estas seguro que quieres eliminar este Item?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
          Cancelar
                </Button>
                <Button onClick={handleAccept} color="primary">
          Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ItemDelete;
