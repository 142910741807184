import React, { useState } from 'react';
import { Snackbar, SnackbarContent, Slide, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

const MessageFailure = ({ close }) => {

  const classes = useStyles();
  const [errorMes, setErrorMes] = useState(true);

  const handleClose = () => {
    setErrorMes(false);
    if (typeof close === 'function')
      close();
  };

  return (
    <Snackbar
      open={errorMes}
      onClose={handleClose}
      autoHideDuration={2000}
      TransitionComponent={SlideTransition}
      >
        <SnackbarContent
          className={classes.error}
          message={
            <span className={classes.message}>
              <ErrorIcon className={`${classes.icon} ${classes.iconVariant}`} />
              Ha ocurrido un error
            </span>
          }
          action={[
            <IconButton key="close" color="inherit" onClick={handleClose}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
    </Snackbar>
  );
};

export default MessageFailure;
