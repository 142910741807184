import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, IconButton, Tabs, Tab, Hidden } from '@material-ui/core';
import { Edit, Close, Block, ArrowBack, Person, Lock } from '@material-ui/icons';
import { Drawer } from '@material-ui/core';
import jwt_decode from "jwt-decode";
import { useSelector, useDispatch } from 'react-redux';
import EditProfileBasic from './editProfileBasic';
import EditPassword from './editPassword';
import { useStyles } from './styles';
import { getDataUserLoggedIn } from './../../actions/userInfoActions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Grid
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <Grid>
            {children}
          </Grid>
        )}
      </Grid>
    );
}

const ProfileDrawer = ({ open, goBack }) => {
    
    const dispatch = useDispatch();
    const [disableInput, setDisableInput] = useState(true)
    const [enableEditProfile, setEnableEditProfile] = useState(false)
    const [value, setValue] = useState(0)
    const userInfo = useSelector((state) => state.userInfo);
    const classes = useStyles();
    const token = useSelector(state => state.auth.token);
    
    let objToken = null;
    if ( token ) {
        objToken = jwt_decode(token);
    }

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    }

    const handleDisableEditProfile = () => {
        setEnableEditProfile(!enableEditProfile);
        setDisableInput(!disableInput);
    }

    const handleGetDataUserLoggeIn = (token, userid) => {       
        dispatch(getDataUserLoggedIn(token, userid))
    }
    
    useEffect(() => {
        if (token && objToken?.user?._id ) { 
            handleGetDataUserLoggeIn(token, objToken.user._id)
        }
    }, [token]);

    return (
        <Drawer
            variant="persistent"
            anchor="right"
            open={open}
            classes={{
                paper: classes.rightDrawer,
            }}
        >
            <Container>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.menuProfile}
                >
                    <IconButton color="inherit" className={classes.noPadding}>
                        <Hidden xsDown>
                            <Close onClick={goBack} />
                        </Hidden>
                        <Hidden smUp>
                            <ArrowBack onClick={goBack} />
                        </Hidden>
                    </IconButton>
                    <Typography className={classes.titleDrawer}>
                        {value == 0 && !enableEditProfile ? 'Ver Perfil' : value == 0 && enableEditProfile ? 'Editar Perfil' : null}
                    </Typography>
                    {enableEditProfile ? (
                        <IconButton 
                            color="inherit" 
                            className={classes.noPadding} 
                            onClick={() => handleDisableEditProfile()}
                        >
                            <Block/>
                        </IconButton>
                    ) : (
                        <Edit className={classes.btnClick} style={{ color: '#000000' }} onClick={() => handleDisableEditProfile()}/>
                    )}
                </Grid>
                <Grid container>
                    <Grid item xs={12} style={{ paddingBottom: 30 }}>
                        <Tabs className={classes.sectionTabBar} value={value} onChange={handleChangeTabs} aria-label="profile options">
                            <Tab className={enableEditProfile && value == 0 ? classes.tabBarFull : classes.tabBar} icon={<Person />}/>
                            <Tab className={enableEditProfile && value == 1 ? classes.tabBarFull : classes.tabBar} icon={<Lock/>}/>
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <EditProfileBasic
                                dataProfile={userInfo.data && userInfo.data}
                                disableInput={disableInput}
                                handleDisableEditProfile={handleDisableEditProfile}
                                getDataProfile={handleGetDataUserLoggeIn}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <EditPassword
                                handleDisableEditProfile={handleDisableEditProfile}
                                disableInput={disableInput}
                            />
                        </TabPanel>
                    </Grid>
                </Grid>
            </Container>
        </Drawer>
    );
}

export default ProfileDrawer;