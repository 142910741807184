import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MTableToolbar } from "material-table";
import { Pageview, DeleteOutline, Edit } from "@material-ui/icons";
import {
  FormControlLabel,
  Switch,
  Breadcrumbs,
  Link,
  Typography,
  TablePagination
} from "@material-ui/core";
import { navigate } from "@reach/router";
import { deleteGuide, getGuides } from "../../actions/guidesActions";
import ItemDelete from "../../components/modal/itemDelete";
import Utils from "../../utils/utils";
import CustomMaterialTable from "../../components/table/CustomMaterialTable";

const GuidesMain = () => {
  const dispatch = useDispatch();
  const [viewAllGuides, setViewAllGuides] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const guides = useSelector((state) => state.guides);  
  const [guideDelete, setGuideDelete] = useState({});
  const [guideDeleteModal, setGuideDeleteModal] = useState(false);

  const guidesCount = guides.totalDocs;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
      setPageSize(event.target.value);
      setPage(0);
  };

  const handleListAllGuides = (event) => {
    setViewAllGuides(!viewAllGuides);
  };

  const handleEditGuide = (guideId) => {
    const state = {
      //pageGuide: paginate.page || location.state.pageGuide
    };
    navigate(`/guides/edit/${guideId._id}`, { state });
  };

  const executeInitGuides = () => {
    dispatch(getGuides(token, { page, pageSize }));
  };

  const handleShowGuide = (guide) => {
    navigate(`/guides/show/${guide._id}`);
  };

  const handleDeleteGuide = (guide) => {
    setGuideDelete(guide);
    setGuideDeleteModal(true);
  };

  const handleDeleteAccept = async () => {
    dispatch(deleteGuide(token, guideDelete._id));
    setGuideDeleteModal(false);
  };

  const handleDeleteCancel = () => {
    setGuideDeleteModal(false);
  };

  useEffect(() => {
      executeInitGuides();
  }, [page, pageSize]);

  return (
    <>
      <div style={{ marginBottom: 16, padding: 16 }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link to="/">Home</Link>
          <Typography color="textPrimary">Guías</Typography>
        </Breadcrumbs>
      </div>
      <CustomMaterialTable
        columns={[
          { title: "Código", field: "codeId" },
          {
            title: "Nombre",
            field: "name",
            customSort: (a, b) =>
              Utils.sortByAlfabethical(a?.name || "A", b?.name || "A"),
          },
          {
            title: "Riesgo",
            field: "risk",
            render: (rowData) => rowData?.risk?.name,
            customSort: (a, b) =>
              Utils.sortByAlfabethical(
                a?.risk?.name || "A",
                b?.risk?.name || "A"
              ),
          },
          {
            title: "Estado",
            field: "state",
            render: (rowData) => rowData?.state?.name,
            customSort: (a, b) =>
              Utils.sortByAlfabethical(
                a?.state?.name || "A",
                b?.state?.name || "A"
              ),
          },
        ]}
        data={
          (guides.data && viewAllGuides
            ? guides.data
            : guides.data.filter((guide) => guide?.state?.name === "Activo")) ||
          []
        }
        components={{
          Toolbar: (props) => (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", paddingLeft: "10px" }}>
                <FormControlLabel
                  control={
                    <Switch
                      value="viewAllGuides"
                      color="primary"
                      checked={viewAllGuides}
                      onChange={(event) => handleListAllGuides(event)}
                    />
                  }
                  label="Todas las guías"
                />
              </div>
              <MTableToolbar {...props} />
            </div>
          ),
          Pagination: props => (
            <TablePagination
              {...props}
              count={guidesCount}
              rowsPerPage={pageSize}
              page={page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={(e) => {
                props.onChangeRowsPerPage(e);
                handlePageSizeChange(e);
              }}
            />
          )
        }}
        actions={[
          {
            icon: () => <Pageview />,
            tooltip: "Mostrar",
            onClick: (event, rowData) => handleShowGuide(rowData),
          },
          {
            icon: () => <Edit />,
            tooltip: "Editar",
            onClick: (event, rowData) => handleEditGuide(rowData),
          },
          {
            icon: () => <DeleteOutline />,
            tooltip: "Eliminar",
            onClick: (event, rowData) => handleDeleteGuide(rowData),
          },
        ]}
        options={{
          sorting: true,
          actionsColumnIndex: -1,
          thirdSortClick: false,
        }}
        title=""
      />

      <ItemDelete
        tagModal="Guía"
        title={guideDelete && guideDelete.name}
        openModal={guideDeleteModal}
        handleAccept={handleDeleteAccept}
        handleCancel={handleDeleteCancel}
      />
    </>
  );
};

export default GuidesMain;
