import { createAsyncAction } from "redux-promise-middleware-actions";
import { eventsUrlApi, validateJSONResponse, exeptionCodeResponse } from '../../../utils/urls';
import { showSuccess } from "../../../actions/messagesActions";
import { GET_EVENTS_BY_USER_ID, GET_NEW_NOTIFICATION, GET_NOTIFICATIONS_BY_USER_ID, READ_NOTIFICATION, UPDATE_USER_EVENTS } from "../../../actions/types";

export const getEventsByUserIdAPI = createAsyncAction(
  GET_EVENTS_BY_USER_ID,
  async (token, bodyReq) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const body = JSON.stringify(bodyReq);
    const res = await fetch(`${eventsUrlApi}/userEvent/`, {
      method: "POST",
      headers,
      body,
    })
      ?.then((response) => validateJSONResponse(response))
      ?.catch((error) => {
        throw exeptionCodeResponse(error);
      });
    return res && res;
  }
);

export const updateEventsAPI = createAsyncAction(
  UPDATE_USER_EVENTS,
  async (token, bodyReq, dispatch) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const body = JSON.stringify(bodyReq);
    const res = await fetch(`${eventsUrlApi}/userEvent`, {
      method: "PUT",
      headers,
      body,
    })
      .then((response) => {
        dispatch(showSuccess());
        return validateJSONResponse(response);
      })
      .catch((error) => {
        throw exeptionCodeResponse(error);
      });
    return res;
  }
);

export const getNotificationsByUserIdAPI = createAsyncAction(
  GET_NOTIFICATIONS_BY_USER_ID,
  async (token, userId) => {
    const headers = { Authorization: `Bearer ${token}` };
    const res = await fetch(`${eventsUrlApi}/notification/${userId}`, {
      headers,
    })
      .then((response) => validateJSONResponse(response))
      .catch((error) => {
        throw exeptionCodeResponse(error);
      });
    return res;
  }
);

export const getNewNotificationAPI = createAsyncAction(
  GET_NEW_NOTIFICATION,
  async (token, userId, dispatch) => {
    const headers = { Authorization: `Bearer ${token}` };
    const res = await fetch(`${eventsUrlApi}/notification/${userId}/new`, {
      headers,
    })
      .then((response) => {
        //dispatch(showMessage());
        return validateJSONResponse(response);
      })
      .catch((error) => {
        throw exeptionCodeResponse(error);
      });
    return res;
  }
);

export const readNotificationAPI = createAsyncAction(
  READ_NOTIFICATION,
  async (token, notificationId) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({ id: notificationId });
    const res = await fetch(`${eventsUrlApi}/notification/read`, {
      method: "PUT",
      headers,
      body,
    })
      .then((response) => {
        return validateJSONResponse(response);
      })
      .catch((error) => {
        throw exeptionCodeResponse(error);
      });
    return res;
  }
);
