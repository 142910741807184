import React from 'react';
import { Link, navigate } from '@reach/router';
import { Grid, Breadcrumbs, Paper } from '@material-ui/core';
import GuidesAddForm from '../../components/guides/guidesAddForm';
import GuidesTitle from '../../components/guides/guidesTitle';
import MessageSuccess from '../../components/messages/messageSuccess';
import MessageError from '../../components/messages/messageError';

const GuidesAdd = () => {

    const handleSuccess = () => {
        navigate('/guides');
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <div style={{ marginBottom: 16, padding: 16 }}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link to="/">Home</Link>
                        <Link to="/guides">Guias</Link>
                        <Link to="/guides/add">
                          Crear
                        </Link>
                    </Breadcrumbs>
                </div>
                <Paper style={{ padding: 16 }}>
                    <GuidesTitle
                        typeForm="guideAdd"
                        handleSuccess={handleSuccess}
                    />
                    <GuidesAddForm initialValues={{
                        photos: []
                    }} />
                </Paper>
            </Grid>
            <MessageSuccess />
            <MessageError />
        </Grid>
    );
}
 
export default GuidesAdd;