import React, { useState, useEffect } from 'react';
import { Grid, Select, MenuItem } from '@material-ui/core';

const FilterComponent = ({ setOrderBy }) => {
  
  const handleChangeSelect = (event) => {
    setOrderBy(event.target.value);
  }

  return (
    <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
      <label>{'Ordenar por'}</label>
      <Select
        name="orderBy"
        onChange={handleChangeSelect}
      >
        <MenuItem value={'date'}>Fecha</MenuItem>
        <MenuItem value={'date_recent'}>Fecha(recientes)</MenuItem>
        <MenuItem value={'no_read'}>No Leidas</MenuItem>
      </Select>
    </Grid>
  )

}

export default FilterComponent;