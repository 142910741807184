import React, { useEffect, useState } from "react";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexBasis: 1,
    padding: '10px',
    borderRadius: 15
  },
  paper: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const InfoCard = ({ percent, type, value, icon, isFiltered=false, onClick }) => {
  const classes = useStyles();
  
	return (
    <div style={{cursor:'pointer'}}  onClick={onClick}>
      <Paper
        elevation={0}
        classes={{
          root: classes.root
        }}
      >
        <span>{icon}</span>
        <div style={{paddingLeft:'20px'}}>
          <div>
            <p style={{color:'#000000', fontSize:'12px', marginBottom:'0px'}}>{type}</p>
          </div>
          <div style={{
            display:'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '10px 0px',
            marginBottom: '15px'
          }}>
            <span style={{
              color: '#4B4B4B',
              fontSize: 20
            }}>{value}</span>
          </div>
        </div>
        {!isFiltered && <div>
          <span style={{color:'#8084AF', fontSize:'12px', marginBottom:'0px'}}>
            { `${percent}% ` }
          </span>
          <span style={{color:'#8084AF', fontSize:'12px', marginBottom:'0px'}}>
            Mes anterior
          </span>
        </div>}
      </Paper>
    </div>
  );
};

export default InfoCard;