import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';
import authReducer from "./authReducer";
import appDataReducer from "./appDataReducer";
import filtersReducer from "./filtersReducer";
import notificationsReducer from "./notificationsReducer";
import inspectionReducer from './inspectionReducer';
import inspectorReducer from "./inspectorAIReducer";
import VideoCallReducer from './videoCallReducer';
import streamReducer from "./streamReducer";
import userInfoReducer from "./userInfoReducer";
import messagesReducer from "./messagesReducer";
import userConfigReducer from "./userConfigReducer";
import guidesReducer from "./guidesReducer";
import guidesRiskReducer from "./guidesRiskReducer";
import guidesStateReducer from "./guidesStateReducer";
import userEventsReducer from "./userEventsReducer";

export default combineReducers({
  auth: authReducer,
  app: appDataReducer,
  filter: filtersReducer,
  notifications: notificationsReducer,
  inspectionReducer: inspectionReducer,
  videocall: VideoCallReducer,
  stream: streamReducer,
  form: formReducer,
  userInfo: userInfoReducer,
  messages: messagesReducer,
  userConfig: userConfigReducer,
  guides: guidesReducer,
  risks: guidesRiskReducer,
  states: guidesStateReducer,
  userEvents: userEventsReducer,
  ai: inspectorReducer,
});
