import { useEffect, useState } from 'react';
import axios from 'axios';
import { eventsUrlApi, inspectionAppUrl, inspectionApiUrl } from  '../utils/urls';

export const sendMessageSMS = (
  id, 
  smsNumber,
  onSuccess,
  onFail
) => {
  const message = `Completa tu autoinspeccion siguiente el siguiente enlace: ${encodeURIComponent(
    inspectionAppUrl
  )}?id=${id}`;

  const sms = {
    message: message,
    to: smsNumber,
  };

  axios
    .post(
      `${eventsUrlApi}/notification/sendSms`,
      JSON.parse(JSON.stringify(sms))
    )
    .then((res) => {
      if (res.data.success) {
        onSuccess(res.data);
      }
    })
    .catch((e) => {
      onFail(e);
    });
}

export const sendMessageSMSVideo = (
  id, 
  smsNumber,
  onSuccess,
  onFail
) => {

  const message = `Completa tu autoinspeccion siguiente el siguiente enlace: ${encodeURIComponent(
    inspectionAppUrl
  )}call?room=${id}`;

  const sms = {
    message: message,
    to: smsNumber,
  };

  axios
    .post(`${eventsUrlApi}/notification/sendSms`, sms)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((e) => {
      onFail(e);
    });
 
}

export const sendMessageWhatsApp = (
  id, 
  whatsappNumber,
  onSuccess,
  onFail
) => {

  const message = `Completa tu autoinspeccion siguiente el siguiente enlace: ${inspectionAppUrl}?id=${id}`;

  const whtsMessage = {
    message: message,
    to: whatsappNumber,
  };

  axios
    .post(`${eventsUrlApi}/notification/sendWhatsapp`, whtsMessage)
    .then((res) => {
      if (res.data.success) {
        onSuccess(res.data)
      }
    })
    .catch((e) => {
      onFail(e)
    });
}

export const sendMessageWhatsAppVideo = (
  id, 
  whatsappNumber,
  onSuccess,
  onFail
) => {

  const message = `Completa tu autoinspeccion con el siguiente enlace: ${encodeURIComponent(
    inspectionAppUrl
  )}call?room=${id}`;

  const whts = {
    message: message,
    to: whatsappNumber,
  };

  axios
    .post(`${eventsUrlApi}/notification/sendWhatsapp`, whts)
    .then((res) => {
      if (res.data.success) {
        onSuccess(res.data);
      }
    })
    .catch((e) => {
      onFail(e);
    });
 
}

export const sendMessageGeneric = (
  id, 
  to,
  type, 
  isVideoCall,
  onLoading = undefined,
  onSuccess, 
  onFail,
) => {
  console.log('video call', isVideoCall)
  const msgVideoCall = `
    Hola,
    Unete a la llamada para tu inspeccion a traves del siguiente link:
    ${inspectionAppUrl}call?room=${id}
    Saludos,
    Sistran Team!
  `;
  const msgAutoInspection = `
    Hola,
    Completa tu autoinspeccion siguiente el siguiente enlace:
    Link:  <a href='${inspectionAppUrl}'>${inspectionAppUrl}?id=${id}</a>
    Saludos,
    Sistran Team!
  `;

  const msgObject = {
    message: isVideoCall ? msgVideoCall : msgAutoInspection,
    to,
  };

  switch ( type ) {
    case 'sendSms':
    case 'sendWhatsapp':
      break;
    case 'sendEmail':
      msgObject.subject = "Sistran mail contacto!";
      break;
  }

  axios
    .post(`${eventsUrlApi}/notification/${type}`, msgObject)
    .then((res) => {
      if (res.data.success) {
        onSuccess(res.data);
      }
    })
    .catch((e) => {
      onFail(e);
    });



}