import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'

const MiniCameraView = () => {

    const dispatch = useDispatch();
    const remoteVideoRef = useRef(null);
    const remoteVideoStream = useSelector((state) => state.videocall.remoteStream);

    useEffect(() =>{
        remoteVideoRef.current.srcObject = remoteVideoStream; 
    },[remoteVideoStream])

    return (
        <>
            {
                
                <video 
                    ref={remoteVideoRef} 
                    id="remoteVideo" 
                    autoPlay 
                    style={{
                        position: 'fixed',
                        bottom: '0',
                        width:'auto',
                        height:'calc(99vh - 200px)',
                        maxWidth: '100%',
                        display: remoteVideoStream ? 'inherit' : 'none',
                        height: '375px',
                        width: 'auto',
                        zIndex: 9999
                    }}
                    playsInline
                ></video>
            }
        </>
    )
}

export default MiniCameraView;