const initialState = {
  data: [],
  photos: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_AI_JSON':

      let newDataArr = state.data.filter( value => value.title != action.payload.title )
      newDataArr = [...newDataArr, action.payload];
      
      return {
        ...state,
        data: newDataArr,
      };
    
    case 'SET_AI_PHOTOS_STRUCTURE': 
      const photos = action.payload.photosArr.map( ({ name }) => {return {name: name, photo: undefined}} )
      return {
        ...state,
        photos,
      }; 
    
    case 'UPDATE_AI_PHOTOS_STRUCTURE':
      const newPhotos = state.photos.map(
        value => {
          if ( value.name === action.payload.name ) {
            return ({ name : value.name, photo: action.payload.photo })
          }
          return value
        }
      );
      return {
        ...state,
        photos: newPhotos
      }; 

    default:
      return state;
  }
}