import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, MenuItem, CircularProgress } from "@material-ui/core";
import Loading from "../loading/loadingCircular";
import { renderAutocompleteField } from "../form/renderInputsForm";
import { Field, reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { decodedToken } from "../../utils";
import { quotationApiUrl } from "../../utils/urls";
import { useGetGuide } from "../../hooks/guides";
// import { FormConsumption } from "form-builder";

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "name",
    "lastname",
    "phone",
    "email",
    "brand",
    "year",
    "model",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Requerido";
    }

    const isPhone = (value) => value && 2 < value.replace(/\s/g, "").length;
    const isEmail = (value) =>
      value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

    if (values.email && !isEmail(values.email)) {
      errors.email = "Dirección de correo invalida";
    }

    if (values.phone && !isPhone(values.phone.replace(/\s/g, ""))) {
      errors.phone = "Telefono invalido";
    }
  });

  return errors;
};

const InspectionDynamicForm = ({ handleData }) => {
  const dispatch = useDispatch();
  const [accesories, setAccesories] = useState([
    {
      code: 11,
      name: "Faros",
    },
    {
      code: 22,
      name: "Extintor",
    },
    {
      code: 33,
      name: "Cruz de transito",
    },
  ]);
  const [guide, setGuide] = useState(null);

  const token = useSelector((state) => state.auth.token);
  const objToken = decodedToken(token);
  const { data: guideInfo, loading } = useGetGuide(objToken.user.companyId);

  useEffect(() => {
    if (guideInfo && guideInfo.length > 0) {
      console.log(guideInfo);
      const defaultValue = guideInfo?.find((value) => value.isDefault);
      if (defaultValue) {
        setGuide(defaultValue);
      }
    }
  }, [guideInfo]);

  const handleChangeGuide = (value) => {
    setGuide(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleDataReturn = (values) => {
    console.log("-------- FORM VALUES --------");
    console.log(values);
    console.log(guide);
    handleData({});
  };

  console.log("Guide", guide);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          {/* <Grid item xs={12}>
              <Field
                name="name"
                label="Nombre"
                custom={{ tabIndex: 1 }}
                component={renderTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="year"
                label="Año"
                component={renderSelectField}
                custom={{ tabIndex: 4 }}
                onChange={handleChangeYear}
              >
                {years
                  ? years.map((value, index) => {
                      return (
                        <MenuItem key={index} id={index} value={value.value}>
                          {value.value}
                        </MenuItem>
                      );
                    })
                  : null}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="versionId"
                label="Version"
                tab-index="7"
                component={renderAutocompleteField}
                options={versions == null ? [] : versions}
                getOptionLabel={(version) => (version.name ? version.name : "")}
                handleChange={handleChangeVersion}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="mileage"
                label="Kilometraje"
                custom={{ tabIndex: 10 }}
                component={renderTextField}
              />
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Grid item xs={12}>
              <Field
                name="lastname"
                label="Apellido"
                custom={{ tabIndex: 2 }}
                component={renderTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="brandId"
                label="Marca"
                tab-index="5"
                component={renderAutocompleteField}
                options={brands == null ? [] : brands}
                getOptionLabel={(brand) => (brand.name ? brand.name : "")}
                onChange={(ev, value) => handleChangeBrand(ev, value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="phone"
                label="Teléfono"
                custom={{ tabIndex: 8, placeholder: "+(cod pais) XXXXXXXXXX" }}
                changeInput={(value) => handleChange("+" + value)}
                component={renderPhoneField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                // name="accesories"
                label="Accesorios"
                component={renderMultiSelectField}
                onChange={handleChangeCheckbox}
                value={[]}
                custom={{ tabIndex: 10 }}
              >
                {(accesories || []).map((dField, i) => {
                  return (
                    <MenuItem key={i} value={dField.code}>
                      {dField.name}
                    </MenuItem>
                  );
                })}
              </Field>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Grid item xs={12}>
              <Field
                name="email"
                label="Email"
                custom={{ tabIndex: 3 }}
                component={renderTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="modelId"
                label="Modelo"
                tab-index="6"
                component={renderAutocompleteField}
                options={models == null ? [] : models}
                getOptionLabel={(model) => (model.name ? model.name : "")}
                onChange={(ev, value) => handleChangeModel(ev, value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="comments"
                label="Comentario"
                custom={{ tabIndex: 9 }}
                component={renderTextField}
              />
            </Grid> */}
          <Grid item xs={12}>
            {loading ? (
              <div style={{ margin: "20px" }}>
                <Loading />
              </div>
            ) : (
              <Field
                name="guides"
                label="Guia de fotos"
                tab-index="5"
                component={renderAutocompleteField}
                options={guideInfo == null ? [] : guideInfo}
                getOptionLabel={(guideInfo) =>
                  guideInfo.name ? guideInfo.name : ""
                }
                onChange={(ev, value) => handleChangeGuide(ev, value)}
                defaultValue={guideInfo?.find((value) => value.isDefault)}
              />
            )}
          </Grid>
        </Grid>
        {/* {guide && guide.inspection_form && (
          <Grid item xs={12}>
            <FormConsumption
              id={guide?.inspection_form?._id}
              token={token}
              onDataReturn={handleDataReturn}
            />
          </Grid>
        )} */}
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: "InspectionDynamicForm",
  touchOnChange: true,
  validate,
  onSubmit: () => {},
})(InspectionDynamicForm);
