import React, { useState } from 'react';
import clsx from 'clsx';
import { Link, navigate } from '@reach/router';
import {
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	IconButton,
	Grid, Collapse
} from "@material-ui/core";
import {
	Dashboard,
	Flag,
	ChevronRight,
	NotificationsOutlined,
	SettingsInputComposite
} from "@material-ui/icons";
// import { Can } from "../../authorization/abilities";
import Logo from '../../assets/images/iConnectance_mini_logo_2.png';
import { useStyles } from './styles'

const NavLink = props => (
    <Link
        {...props}
        getProps={({ isCurrent }) => {
            // the object returned here is passed to the
            // anchor element's props
            return {
                style: {
                    color: '#333333'
                },
                className: isCurrent ? props.className : undefined
            };
        }}
    />
);

const NavLinkItem = ({ children, ...props }) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
				style: {
					color: '#333333'
				},
				className: isCurrent ? props.className : undefined,
      };
    }}
  >
		<ListItem>
			<div className="hover-effect" style={{ width: 0 }} />
			{children}
		</ListItem>
	</Link>
);

const CollapsibleItem = ({ children, onClick, menuOpen, open, icon, title, className }) => (
	<List>
		<ListItem className={className} button onClick={onClick}>
			<ListItemIcon>
				{icon}
			</ListItemIcon>
			<ListItemText primary={title} />
		</ListItem>
		<Collapse in={open} timeout="auto" unmountOnExit>
			<List disablePadding>
				<div style={{ transition: 'all 225ms cubic-bezier(0.4, 0, 0.6, 1)', paddingLeft: menuOpen ? 32 : 0 }}>
					{children}
				</div>
			</List>
		</Collapse>
	</List>
);

const SideMenu = ({ menuOpen, handleDrawerMenu }) => {
	const classes = useStyles();
	const [ groupOpened, setGroupOpened ] = useState('');

	const handleOpenGroup = (group) => (evt) => {
		group === groupOpened ? setGroupOpened('') : setGroupOpened(group);
	};
	return (
		<Drawer
			variant="permanent"
			className={clsx(classes.drawer, {
				[classes.drawerPaper]: menuOpen,
				[classes.drawerPaperClose]: !menuOpen,
			})}
			classes={{
				paper: clsx({
					[classes.drawerPaper]: menuOpen,
					[classes.drawerPaperClose]: !menuOpen,
				})
			}}
			open={menuOpen}
		>
			<div className={classes.toolbarIcon}>
				<div>
					<Link to="/dashboard"><img src={Logo} alt="iConnectance" style={{ width: 36, height: 36 }} /></Link>
				</div>
			</div>
			<Divider />
			<Grid container item justify="center" style={{ maxWidth: 72 }}>
				<IconButton onClick={handleDrawerMenu} style={{ marginTop: 10 }}>
					<ChevronRight fontSize="large" style={{ color: '#AF8AFC' }} className={clsx(classes.barIcon, {
						[classes.barIconRotate]: menuOpen,
					})} />
				</IconButton>
			</Grid>
			<List className={classes.navList}>
				<NavLinkItem to="dashboard" className={classes.activeLink}>
					<ListItemIcon>
						<Dashboard className={classes.icon} />
					</ListItemIcon>
					<ListItemText primary="Dashboard" />
				</NavLinkItem>
				{/* <Can do="read" on="Lead"> */}
				<NavLink to="inspections" className={classes.activeLink}>
					<ListItem>
						<div className="hover-effect" style={{ width: 0 }} />
						<ListItemIcon>
							<Flag className={classes.icon}/>
						</ListItemIcon>
						<ListItemText primary="Inspecciones" />
					</ListItem>
				</NavLink>
				<NavLink to="notifications" className={classes.activeLink}>
					<ListItem>
						<div className="hover-effect" style={{ width: 0 }} />
						<ListItemIcon>
							<NotificationsOutlined className={classes.icon}/>
						</ListItemIcon>
						<ListItemText primary="Notificaciones" />
					</ListItem>
				</NavLink>
				{/* <NavLink to="guides" className={classes.activeLink}>
					<ListItem>
						<div className="hover-effect" style={{ width: 0 }} />
						<ListItemIcon>
							<SettingsInputComposite className={classes.icon}/>
						</ListItemIcon>
						<ListItemText primary="Guias" />
					</ListItem>
				</NavLink> */}
				{/* </Can> */}
			</List>
		</Drawer>
	);
};

export default SideMenu;
