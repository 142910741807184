export const setCallStart = () => async (dispatch) => {
    dispatch({type:'SET_CALL_START'});
};

export const setCallEnd = () => async (dispatch) => {
	dispatch({type:'SET_CALL_END'});
};

export const setCallStream = (stream) => async (dispatch) => {
	dispatch({type:'SET_CALL_STREAM', payload: stream});
};

export const setMuteStream = () => async (dispatch) => {
	dispatch({type:'SET_MUTE_STREAM'});
};

export const setLocalStream = (stream) => async (dispatch) => {
	dispatch({type:'SET_LOCAL_STREAM', payload: stream});
};

export const setRemoteVideoRef = (refObject) => async (dispatch) => {
	dispatch({type:'SET_REMOTE_VIDEO_REF', payload: refObject});
};

export const setRoom = (room) => async (dispatch) => {
	dispatch({type:'SET_ROOM', payload: room});
};

export const setImage = (imageData) => async (dispatch) => {
	dispatch({type:'SET_IMAGE', payload: imageData});
};