import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PhoneMissedIcon from "@material-ui/icons/PhoneMissed";
import Mic from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import CallIcon from "@material-ui/icons/Call";
import Timer from "./timer";
import { setMuteStream } from "../../actions/videoCallActions";
import StreamComponent from "../videocall/stream/StreamComponent";

const CallOptionsFooter = ({ localUser, hangup, handleMute, reconnect }) => {
  const dispatch = useDispatch();
  const [isHangedUp, setIsHangeUp] = useState(false);
  const isAudioActive = localUser !== undefined && localUser.isAudioActive();

  const handleHangup = () => {
    console.log("clicked!");
    if (!isHangedUp) {
      setIsHangeUp(true);
      hangup();
    }
  };

  return (
    <footer
      style={{
        backgroundColor: "lightgray",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "#fff",
        height: "90px",
        padding: "5px",
        borderRadius: '0px 0px 12px 12px'
      }}
    >
      {/* {localUser !== undefined && localUser.getStreamManager() !== undefined ? (
        <div className="OT_root OT_publisher custom-class mini" id="localUser" style={{ justifyContent: 'start' }}>
          <StreamComponent user={localUser} />
        </div>
      ) : (
        <div style={{ backgroundColor: "black", height: 90, width: 120 }}></div>
      )} */}

      <span
        style={{
          height: 50,
          width: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {isAudioActive ? (
          <span onClick={handleMute}>
            <svg
              width="47"
              height="47"
              viewBox="0 0 47 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_b_109_1375)">
                <rect width="47" height="47" rx="23.5" fill="#4C1DBB" />
              </g>
              <path
                d="M23 27C24.0609 27 25.0783 26.5786 25.8284 25.8284C26.5786 25.0783 27 24.0609 27 23V18C27 16.9391 26.5786 15.9217 25.8284 15.1716C25.0783 14.4214 24.0609 14 23 14C21.9391 14 20.9217 14.4214 20.1716 15.1716C19.4214 15.9217 19 16.9391 19 18V23C19 24.0609 19.4214 25.0783 20.1716 25.8284C20.9217 26.5786 21.9391 27 23 27Z"
                fill="white"
              />
              <path
                d="M30 23C30 22.7348 29.8946 22.4804 29.7071 22.2929C29.5196 22.1054 29.2652 22 29 22C28.7348 22 28.4804 22.1054 28.2929 22.2929C28.1054 22.4804 28 22.7348 28 23C28 24.3261 27.4732 25.5979 26.5355 26.5355C25.5979 27.4732 24.3261 28 23 28C21.6739 28 20.4021 27.4732 19.4645 26.5355C18.5268 25.5979 18 24.3261 18 23C18 22.7348 17.8946 22.4804 17.7071 22.2929C17.5196 22.1054 17.2652 22 17 22C16.7348 22 16.4804 22.1054 16.2929 22.2929C16.1054 22.4804 16 22.7348 16 23C16.002 24.6818 16.6094 26.3068 17.7112 27.5775C18.813 28.8482 20.3354 29.6797 22 29.92V32H19.89C19.654 32 19.4276 32.0938 19.2607 32.2607C19.0938 32.4276 19 32.654 19 32.89V33.11C19 33.346 19.0938 33.5724 19.2607 33.7393C19.4276 33.9062 19.654 34 19.89 34H26.11C26.346 34 26.5724 33.9062 26.7393 33.7393C26.9062 33.5724 27 33.346 27 33.11V32.89C27 32.654 26.9062 32.4276 26.7393 32.2607C26.5724 32.0938 26.346 32 26.11 32H24V29.92C25.6646 29.6797 27.187 28.8482 28.2888 27.5775C29.3906 26.3068 29.998 24.6818 30 23Z"
                fill="white"
              />
              <defs>
                <filter
                  id="filter0_b_109_1375"
                  x="-10.5"
                  y="-10.5"
                  width="68"
                  height="68"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.25" />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_109_1375"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_109_1375"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </span>
        ) : (
          <span onClick={handleMute}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="47"
              height="47"
              viewBox="0 0 512 512"
            >
              <path
                fill="#4c1dbb"
                d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208s208-93.31 208-208S370.69 48 256 48m0 80a48.14 48.14 0 0 1 48 48v64a47.84 47.84 0 0 1-.63 7.71a2 2 0 0 1-3.46 1l-84.42-92.86a4 4 0 0 1-.47-4.77A48.08 48.08 0 0 1 256 128m32 256h-63.55c-8.61 0-16-6.62-16.43-15.23A16 16 0 0 1 224 352h16v-17.74a103.71 103.71 0 0 1-49.21-23.38c-19.85-17.05-30.79-39.3-30.79-62.66V224a15.91 15.91 0 0 1 16.39-16A16.26 16.26 0 0 1 192 224.4v23.82c0 25.66 28 55.48 64 55.48c1.67 0 3.37-.09 5.06-.24a3.94 3.94 0 0 1 3.29 1.29l21.07 23.19a2 2 0 0 1-.89 3.26a100.33 100.33 0 0 1-12.53 3.06V352h15.55c8.61 0 16 6.62 16.43 15.23A16 16 0 0 1 288 384m-77.89-138.91l36.46 40.11a1 1 0 0 1-.95 1.66a48.26 48.26 0 0 1-37.25-41a1 1 0 0 1 1.74-.77m152.65 119.75a16 16 0 0 1-22.6-1.08l-192-210a16 16 0 0 1 23.68-21.52l192 210a16 16 0 0 1-1.08 22.6M352 248.22a77.12 77.12 0 0 1-11.93 40.87a2 2 0 0 1-3.19.3l-19.19-21.1a4 4 0 0 1-.76-4.16a43.35 43.35 0 0 0 3.07-15.91v-23.8a16.3 16.3 0 0 1 13.64-16.24c9.88-1.48 18.36 6.51 18.36 16.12Z"
              />
            </svg>
          </span>
        )}
      </span>


      <span
        style={{
          height: 42,
          width: 145,
          backgroundColor: isHangedUp ? "green" : "red",
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          marginLeft: 14
        }}
      >
        {isHangedUp ? (
          <CallIcon
            onClick={() => {
              setIsHangeUp(false);
              reconnect();
            }}
            style={{ fontSize: 30, color: "white" }}
          />
        ) : (
          <span
            style={{
              color: "#ffffff",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={handleHangup}
          >
            Terminar llamada
          </span>
        )}
      </span>

      {/* <Timer isActive={localUser !== undefined} /> */}
    </footer>
  );
};

export default CallOptionsFooter;
