import React from 'react'

import { Grid, CircularProgress } from '@material-ui/core';

const Loading = () => (
    <Grid container direction="row" justify="center" alignItems="center" item spacing={3} xs={12} sm={12} md={12}>
        <CircularProgress disableShrink />
    </Grid>
)

export default Loading;