import React, { useState } from "react";
import PropTypes from 'prop-types';
import { useEffect } from "react";
import { Folder } from '@material-ui/icons';
// import { useStyles } from "./styles";

const InputFile = ({ name, id, onChange, valueFile }) => {
  // const classes = useStyles();

  const [image, setImage] = useState(null);

  useEffect(() =>{
    if (valueFile) {
      setImage(URL.createObjectURL(valueFile));
    }
  },[valueFile])

  const handleInternalOnChange = ev => {
    const file = ev.target.files[0];
    setImage(URL.createObjectURL(file));
    onChange(ev);
  }

  return (
    <>
      <label htmlFor={name} style={{ display: 'block', cursor: 'pointer', minHeight: '120px', maxHeight: '120px', height: '120px', width: '120px', borderRadius: '5px' }}>
        {
          !image && 
            <div style={{ borderRadius: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '120px', width: '120px', border: '1px dashed rgb(188 157 252)'}}>
              <Folder style={{color: '#bc9dfc'}} />
              <p style={{ color: '#4C1DB7', textAlign: 'center', fontSize: 12, padding: 5 }}>Cargar <strong>{name}</strong></p>
            </div>
        }
        
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {
            image &&
            <div style={{ position: 'relative', borderRadius: '5px', height: '120px', width: '120px', backgroundSize: 'cover', backgroundImage: `url(${image})` }}>
              <div style={{position: 'absolute', bottom: 0, background: '#fff', opacity: '0.8', height: 30, display: 'flex', justifyContent:'center', alignItems: 'center', padding: '5px 0px' }}>
                <p style={{ fontSize: '12px', textAlign: 'center', width: 120 }}>{name}</p>
              </div>
            </div>
          }
        </div>
        <input type="file" id={name} name={name} onChange={handleInternalOnChange} style={{display:'none'}}/>
      </label>
    </>
  )
};

InputFile.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,

  // position: PropTypes.number,
  // title: PropTypes.string,
  // defaultImage: PropTypes.any,
};

export default InputFile;
