export const Util = {
  orderByOldest: (array) => {
    const arrayOrderedByOldest = array.sort((a,b) => new Date(a.sentAt) - new Date(b.sentAt));
    return arrayOrderedByOldest;
  },
  orderByUnread: (array) => {
    const noRead = array.filter(item => !item.read);
    const read = array.filter(item => item.read);
    return [...noRead, ...read];
  },
  orderByMostRecent: (array) => {
    const arrayOrderedByMostRecent = array.sort((a,b) => new Date(b.sentAt) - new Date(a.sentAt));
    return arrayOrderedByMostRecent
  }
}