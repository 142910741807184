import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, MenuItem, CircularProgress } from '@material-ui/core';
import Loading from '../loading/loadingCircular';
import { 
    renderTextField, 
    renderPhoneField, 
    renderMultiSelectField, 
    renderSelectField, 
    renderAutocompleteField 
} from '../form/renderInputsForm';
import { Field, reduxForm, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { decodedToken } from '../../utils';
import { quotationApiUrl } from '../../utils/urls';
import { useGetGuide } from '../../hooks/guides';

const validate = (values) => {
    const errors = {};
    const requiredFields = ['name', 'lastname', 'phone', 'email', 'brand', 'year', 'model'];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Requerido';
        }

        const isPhone = value => value && (2 < value.replace(/\s/g,'').length);
        const isEmail = value => value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

        if (values.email && !isEmail(values.email)) {
            errors.email = 'Dirección de correo invalida';
        }

        if (values.phone && !isPhone(values.phone.replace(/\s/g,''))) {
            errors.phone = 'Telefono invalido';
        }
    });

    return errors;
};

const InspectionAddForm = () => {
    const dispatch = useDispatch();
    const form = useSelector((state) => state.form);
    const [accesories, setAccesories] = useState([
        {
            code: 11,
            name: 'Faros'
        },
        {
            code: 22,
            name: 'Extintor'
        },
        {
            code: 33,
            name: 'Cruz de transito'
        }
    ]);
    const [years, setYears] = useState(null)
    const [brands, setBrands] = useState(null)
    const [models, setModels] = useState(null)
    const [versions, setVersions] = useState(null)

    const handleChange = (value) => {
        dispatch(change('InspectionAddForm', 'phone', value));
    }

    const objToken = decodedToken(useSelector((state) => state.auth.token));
    const {data: guideInfo, loading } = useGetGuide(objToken.user.companyId);

    useEffect(() => {
        if (guideInfo && guideInfo.length > 0) {
            const defaultValue = guideInfo?.find(value => value.isDefault);
            dispatch(change('InspectionAddForm', 'guideId', defaultValue?._id));
        }
    },[guideInfo])
    
    

    const handleChangeCheckbox = (e) => {
        const names = e.target.value
        const copyAccesories = [...accesories]

        const result = names.map((name, i) => {
            const resultFilter = copyAccesories.filter((value) => {
                return value.code == name
            })
            return resultFilter[0]
        })
        dispatch(change('InspectionAddForm', 'accesories', result));
    }

    const handleChangeYear = (e) => {
        const value = e.target.value
        dispatch(change('InspectionAddForm', 'year', value));
        getBrandByYear();
    }
    
    const handleChangeBrand = (ev, value) => {
        value ? 
            dispatch(change('InspectionAddForm', 'brand', value._id))
            : dispatch(change('InspectionAddForm', 'brand', ''))
        getModelByBrand(value)

    }

    const handleChangeVersion = (value) => {
        value ? dispatch(change('InspectionAddForm', 'version', value.name))
            : dispatch(change('InspectionAddForm', 'version', ''));
    }
    
    const handleChangeModel = (value) => {
        value ? dispatch(change('InspectionAddForm', 'model', value._id))
            : dispatch(change('InspectionAddForm', 'model', ''));
        getVersionByModel(value)
    }

    const handleChangeGuide = (value) => {
        value ? dispatch(change('InspectionAddForm', 'guideId', value._id))
            : dispatch(change('InspectionAddForm', 'guideId', ''));
    }

    const getBrandByYear = async () => {
        await axios.get(`${quotationApiUrl}/catalog/brand/`)
            .then( ({ data })  => {
                setBrands(data);
            });
    }

    const getModelByBrand = async (val) => {
        await axios.get(`${quotationApiUrl}/catalog/model/${val._id}`) 
            .then( ({ data }) => {
                setModels(data)
            });
    }

    const getVersionByModel = async (val) => {
        await axios.get(`${quotationApiUrl}/catalog/version/${val._id}`)
            .then( ({ data }) => {
                setVersions(data);
            });
    }

    useEffect(() => {
        const fetchData = async () => {         
          await axios.get(`${quotationApiUrl}/catalog/years`)
          .then( ({ data })  => {
                const arrayData = data.map(
                    year => {
                        return {'label': year, 'value': year};
                    }
                );
                setYears(arrayData);
          });
        };     
        fetchData();
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
    }

    return (
      <form onSubmit={handleSubmit}>
        <Grid container direction="row" spacing={2}>
          <Grid item sm={4} xs={12}>
            <Grid item xs={12}>
              <Field
                name="name"
                label="Nombre"
                custom={{ tabIndex: 1 }}
                component={renderTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="year"
                label="Año"
                component={renderSelectField}
                custom={{ tabIndex: 4 }}
                onChange={handleChangeYear}
              >
                {years
                  ? years.map((value, index) => {
                      return (
                        <MenuItem key={index} id={index} value={value.value}>
                          {value.value}
                        </MenuItem>
                      );
                    })
                  : null}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="versionId"
                label="Version"
                tab-index="7"
                component={renderAutocompleteField}
                options={versions == null ? [] : versions}
                getOptionLabel={(version) => (version.name ? version.name : "")}
                handleChange={handleChangeVersion}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="mileage"
                label="Kilometraje"
                custom={{ tabIndex: 10 }}
                component={renderTextField}
              />
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Grid item xs={12}>
              <Field
                name="lastname"
                label="Apellido"
                custom={{ tabIndex: 2 }}
                component={renderTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="brandId"
                label="Marca"
                tab-index="5"
                component={renderAutocompleteField}
                options={brands == null ? [] : brands}
                getOptionLabel={(brand) => (brand.name ? brand.name : "")}
                onChange={(ev, value) => handleChangeBrand(ev, value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="phone"
                label="Teléfono"
                custom={{ tabIndex: 8, placeholder: "+(cod pais) XXXXXXXXXX" }}
                changeInput={(value) => handleChange("+" + value)}
                component={renderPhoneField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                // name="accesories"
                label="Accesorios"
                component={renderMultiSelectField}
                onChange={handleChangeCheckbox}
                value={[]}
                custom={{ tabIndex: 10 }}
              >
                {(accesories || []).map((dField, i) => {
                  return (
                    <MenuItem key={i} value={dField.code}>
                      {dField.name}
                    </MenuItem>
                  );
                })}
              </Field>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Grid item xs={12}>
              <Field
                name="email"
                label="Email"
                custom={{ tabIndex: 3 }}
                component={renderTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="modelId"
                label="Modelo"
                tab-index="6"
                component={renderAutocompleteField}
                options={models == null ? [] : models}
                getOptionLabel={(model) => (model.name ? model.name : "")}
                onChange={(ev, value) => handleChangeModel(ev, value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="comments"
                label="Comentario"
                custom={{ tabIndex: 9 }}
                component={renderTextField}
              />
            </Grid>
            <Grid item xs={12}>
              {loading ? (
                <div style={{ margin: "20px" }}>
                  <Loading />
                </div>
              ) : (
                <Field
                  name="guides"
                  label="Guia de fotos"
                  tab-index="5"
                  component={renderAutocompleteField}
                  options={guideInfo == null ? [] : guideInfo}
                  getOptionLabel={(guideInfo) =>
                    guideInfo.name ? guideInfo.name : ""
                  }
                  onChange={(ev, value) => handleChangeGuide(ev, value)}
                  defaultValue={guideInfo?.find((value) => value.isDefault)}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
}

export default reduxForm({
    form: 'InspectionAddForm',
    touchOnChange: true,
    validate,
    onSubmit: () => {}
})(InspectionAddForm);