import Axios from 'axios'; 
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { inspectionApiUrl, quotationApiUrl, validateResponse, exeptionCodeResponse, quotationUrlBase } from '../utils/urls';
import { decodedToken, getToken } from '../utils/index';

export const addInspectionAPI = createAsyncAction( 'ADD_INSPECTION', async (bodyReq, dispatch) => {
    const token = getToken();
    const body = JSON.stringify(bodyReq);

    const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
    const res = await fetch(`${inspectionApiUrl}/inspections`, { method: 'POST', headers, body })
      .then((response) => {
        console.log('response =>', response);
        validateResponse(response)
      })
      .catch((error) => {
        console.log('error at create=>', error);
        throw exeptionCodeResponse(error);
      });
    return res;
  }
);

export const storageImageToInspection = async ( formDataToUpload, callback ) => {
  let configRequest = {
    header : {
      'Content-Type' : 'multipart/form-data'
    }
  }

  const response = await Axios
        .post(`${inspectionApiUrl}/inspections/uploadGuidePhoto`, formDataToUpload, configRequest)
        .then( res =>  callback())
        .catch( err => {
          console.log("error storageImageToInspection: ",err)
          callback()
        }) 

  return response;
}

// mejorar con populate en el endpoint
export const getGuideInfoWithTemporaryId = async ( temporaryId ) => {
  
    const guideId = await Axios.get(`${inspectionApiUrl}/inspections/temporary/${temporaryId}`)
          .then( ({ data }) => data.guideId )
          .catch( e => null );

    if ( !guideId ) return null;
    
    const guideInfo = await Axios.get(`${inspectionApiUrl}/guides/${guideId}`)
            .then( ({ data }) => data )
            .catch( e => null )

  return guideInfo;
}  

export const getGuideInfoWithId = async ( id ) => {

  const guideId = await Axios.get(`${inspectionApiUrl}/inspections/details/${id}`)
        .then( ({ data }) => data.guideId )
        .catch( e => null );

  if ( !guideId ) return null;
  
  const guideInfo = await Axios.get(`${inspectionApiUrl}/guides/${guideId}`)
          .then( ({ data }) => data )
          .catch( e => null )

  return guideInfo;
}  

export const uploadPhotoFile = async formDataToUpload => {
  let configRequest = {
    header : {
      'Content-Type' : 'multipart/form-data'
    }
  }

  try { 
    
    const resposeStatus = await Axios.post(`${inspectionApiUrl}/inspections/uploadGuidePhoto`, formDataToUpload, configRequest);
    return resposeStatus;

  } catch (e) {
    console.log()
  }
}

export const getYears = async () => {
  const yearsArr = await Axios.get(`${quotationApiUrl}/catalog/years`)
    .then( ({ data })  => {
      const arrayData = data.map(
        year => {
          return {'label': year, 'value': year};
        }
      );
      return arrayData;

    })
    .catch(err => {
      console.log('error at retreiving years: ', err)
      return [];
    });

  return yearsArr;
}

export const getBrand = async  () =>  {
  const res = await Axios.get(`${quotationApiUrl}/catalog/brand/`)
    .then( ({ data }) => {
        return data;
    })
    .catch(err => {
        console.log('error at retreiving brands: ', err);
        return [];
    });

  return res;
}

export const getModels = async ( brandId ) => {
  
  const models = await Axios.get(`${quotationApiUrl}/catalog/model/${brandId}`) 
      .then( ({ data }) => {
          return data;
      })
      .catch(err => {
          console.log('error at retreiving brands: ', err);
          return [];
      });

  return models;
}

export const getVersionsByModel = async modelId => {
  const versions = await Axios.get(`${quotationApiUrl}/catalog/version/${modelId }`)
    .then( ({ data }) => {
        return data;
    })
    .catch(err => {
      console.log('error at retreiving versions: ', err);
      return [];
    });

  return versions;
}

export const downloadPDF = async (data) => {
  const headers = { "Content-Type": "application/json" };
  const blobHeaders = { "Content-Type": "application/pdf" };
  const res = await fetch(`${quotationApiUrl}/contact/download`, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
  })
      .then((response) => validateResponse(response))
      .catch((error) => {
          throw exeptionCodeResponse(error);
      });

  const blob = await fetch(
      `${quotationUrlBase}templates/${res.data.path}.pdf`,
      { method: "GET", headers: blobHeaders }
  ).then((response) => response.blob());

  return {
      ...res,
      blob,
  };
};