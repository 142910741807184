import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;
export const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24,
        height: 110,
        justifyContent: "space-between",
    },
    toolbarIcon: {
        display: "flex",
        padding: "0 8px",
        height: 110,
        ...theme.mixins.toolbar,
        "& > div": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            paddingLeft: 13,
        },
    },
    drawer: {
        width: 240,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerPaper: {
        zIndex: "900 !important",
        borderRight: "none !important",
        position: "relative",
        whiteSpace: "nowrap",
        width: 240,
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        zIndex: "900 !important",
        overflowX: "hidden",
        position: "relative",
        borderRight: "none !important",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    icon: {
        color: "#4C1DB7",
    },
    navList: {
        paddingLeft: 13,
    },
    activeLink: {
        "& .MuiSvgIcon-root": {
            color: "#00A0F8",
        },
        "& .MuiListItem-root": {
            color: "#141B6A",
        },
        "& .hover-effect": {
            width: "100% !important",
            height: "100%",
            left: 0,
            top: 0,
            position: "absolute",
            backgroundColor: "#F4F4FA",
            borderTopLeftRadius: 40,
            borderBottomLeftRadius: 40,
            zIndex: -1,
            transition: "width 0.5s ease-in",
        },
    },
    listText: {
        wordBreak: "break-word",
        whiteSpace: "break-spaces",
        minWidth: 140,
    },
    barIcon: {
        transition: "all .5s ease-out",
    },
    barIconRotate: {
        transform: "rotate(-180deg)",
    },
    listItem: {
        borderTopLeftRadius: 40,
        borderBottomLeftRadius: 40,
        "&.MuiListItem-button:hover": {
            backgroundColor: "#F4F4FA",
        },
    },

    //BarMenu
    // toolbarBarMenu: {
    //     paddingRight: 24,
    //     height: 113,
    //     justifyContent: 'flex-end'
    // },
    appBar: {
        backgroundColor: "transparent",
        width: "calc(100% - 72px)",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: "0 0 0 0",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    appBarShift: {
        backgroundColor: "transparent",
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: "0 0 0 0",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
        color: "#141B6A",
        fontSize: "24px !important",
        marginLeft: 20,
    },
}));
