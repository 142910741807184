import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import moment from 'moment';
import { 
  Badge,  
  Grid } from '@material-ui/core';
import MaterialTable from "material-table";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { NotificationsOutlined } from '@material-ui/icons';
import { Util } from '../../utils';
import { MODULE_NAME } from '../../utils/urls';
import SettingsIcon from '../../../../assets/images/settings.png';
import FilterComponent from '../../components/filterNotificationsComponent';
import DetailNotificacion from '../../components/detailNotification';
import DrawerConfigurations from '../../components/drawerConfigurations';

import { NotificationsMain } from '../../../notifications-ui';

// import { getAllNotifications, updateNotificationReadStatus, getEventsByUserIdAPI, getEventsByUserIdAPINew } from '../../services';
import CustomMaterialTable from '../../../table/CustomMaterialTable';
import { readNotification, updateUserEvents } from '../../../../actions/userEventsActions';
import { setUserEventListeners } from '../../services/eventListener';
import Logo from '../../../../assets/images/iConnectance_mini_logo_2.png'

const useStyles = makeStyles(theme => ({
  listItem: {
    fontSize: 16,
    color: '#333333',
    padding: '1rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  listItemSecondary: {
    fontSize: 14,
  },
  unread: {
    backgroundColor: '#D8D0F6',
    fontWeight: 600,
    '& > div:last-child': {
      color: '#000000'
    }
  },
  configMenu: {
    width: 350,
    borderTopLeftRadius: 10,
    padding: '20px 15px 20px 15px',
    '& h3': {
      color: '#3B3B3B',
      fontSize: 16
    }
  },
  rootNotif : {
    padding: 20,
    '&:hover':{
      background: '#E4DAF9',
      cursor: 'pointer',
      '& .dateInfo': {
        color: '#000'
      }
    },
    backgroundColor: theme.palette.background.paper,
  },
  rootNotifUnread : {
    padding: 20,
    '&:hover':{
      background: '#E4DAF9',
      cursor: 'pointer'
    },
    backgroundColor: '#C8B4F3',
    overflow: 'auto',
    maxHeight: 300
  },
  hover: {
    "&:hover": {
      backgroundColor: '#E4DAF9',
      cursor: 'pointer'
    }
  },
  rootList: {
    overflow: 'auto',
    width:'100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300
  },
  containerList: {
    height: '60%',
    width:'100%',
    overflow: 'auto',
    maxHeight: '500',
    position: 'relative',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
  },
  drawer: {
    width: '365px'
  },
  iconSize: {
    fontSize: '2.5rem !important'
  },
  drawerPaper: {
    top: '100px',
    borderRadius: '10px'
  },
  labelBtn: {
    fontSize: '10px',
    padding: '4px 12px'
  }

}));

const StyledBadge = withStyles({
  root: {
    fontSize: '20px'
  },
  colorSecondary: {
    backgroundColor: '#00A0F8'
  },
  anchorOriginBottomLeftCircle: {
    left: '15%',
    bottom: '25%'
  },
  dot: {
    height: 8,
    minWidth: 8
  }
})(Badge);

function createData(title, content, date, _id, read) {
  return { title, content, date, _id, read};
}

const centeredEl = {
  display: 'flex',
  justifyContent: 'center'
};

const columnNotifications = [
  { title: 'Titulo', field: 'title',
    render: 
    rowData =>
    <div> 
      {
        rowData.read ?
        <div style={{display:'flex', alignItems:'center'}}>
          <NotificationsOutlined style={{ color: '#828282' }} />
          <span>{rowData.title}</span>
        </div>
        : 
        <div style={{display:'flex', alignItems:'center'}}>
          <StyledBadge
          variant="dot"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          overlap="circular"
          color="secondary">
            <NotificationsOutlined style={{ color: '#333333' }} />
          </StyledBadge> 
          <span>{rowData.title}</span>
        </div>
      }
    </div>
  },
  { title: 'Fecha', field: 'date' },
];

const NotificationsList = ({ token }) => {
  const dispatch = useDispatch();
  const [notificationsList_table, setNotificationsList_table] = useState([]);
  const [notificationsListPaginated, setNotificationsListPaginated] = useState([]);
  const userEvents = useSelector((state) => state.userEvents);
  const config = useSelector((state) => state.userConfig.data);

  let objToken = null;
  if ( token ) {
    objToken = jwt_decode(token);
  }

  const handleUpdateNotificationConfig = async (updatedEvents) => {
    console.log('handleUpdateNotificationConfig=>', updatedEvents);
    await dispatch(updateUserEvents(token, updatedEvents));
    setUserEventListeners(
      userEvents.io,
      updatedEvents,
      token,
      dispatch,
      config.logoImage ? config.logoImage : Logo
    );
  };

  const [notificationChoose, setNotificationChoose] = useState([]);
  const classes = useStyles();

  const [stateDrawer, setStateDrawer] = useState(false);
  const [stateDrawerConfig, setStateDrawerConfig] = useState(false);
  const [orderBy, setOrderBy] = useState(null);

  const _notifications = useSelector((state) => state.notifications);

  const dataNotif = userEvents.notifications;
  const dataEvents = userEvents.data;

  const toggleDrawer = () => {
    setStateDrawer(false);
  }

  const handleRead = async (noti) => {
    await dispatch(readNotification(token, noti._id));
  };

  /* const handleClickListItem = ( id ) => {
    const notifPicked = notificationsList_table.filter( notif => notif._id === id );    
    console.log('notifPicked=>', notifPicked);
    if (!notifPicked[0].read) {

      const newNotificationList = notificationsList_table.map( notif => {
        if (notif._id === id)
          return {...notif, read: true };
        return notif
      });

      setNotificationsList_table(newNotificationList);
      
      try {
  
        updateNotificationReadStatus(token, id)
          .then((response) => {
            console.log('response=>', response);
          }).catch((error) => {
            console.log('error=>', error);
          });
         
      } catch (e) {
        console.log('error=>', e);
      }

    }
    
    setNotificationChoose(notifPicked[0]);
    setStateDrawer(true);
  } */

  /* useEffect(() => {
    
    const objToken = jwt_decode(token);

    console.log('aqui voy, no esta vacio', );
    if ( _notifications.length > 0 ) {
      console.log('aqui voy, no esta vacio');
      const notifs = _notifications.map( notif => {
        return createData( notif.message.title, notif.message.content, moment(notif.sentAt).format('hh:mm   DD/MM/YYYY'), notif._id, notif.read );
      });
      
      setNotificationsList_table(_notifications);

    } else {
      console.log('aqui voy, esta vacio');
      //getEventsByUserIdAPI(token, MODULE_NAME, objToken.user._id, objToken.user.email).then(
      getEventsByUserIdAPINew(token, objToken.user._id, objToken.user.email).then(
        res => {
          getAllNotifications(objToken).then( 
            res => {
              const notifs = res.data.map( notif => {
                return createData( notif.message.title, notif.message.content, moment(notif.sentAt).format('hh:mm   DD/MM/YYYY'), notif._id, notif.read );
              });
              
              setNotificationsList_table(notifs);
              
              const paginated = res.data.slice(0, 10);
              setNotificationsListPaginated(paginated);
      
            }
          )
          .catch( e => {
            console.log('e=>', e);  
          });
        }
      )
      .catch( e => {
        console.log('res e=>', e);
      });

    }

    
  }, []); */

  useEffect(() => {

    switch (orderBy) {
      case 'date_recent':
        const notifListOrderByRecent = Util.orderByMostRecent(notificationsList_table);
        setNotificationsList_table([...notifListOrderByRecent]);
        break;
        
      case 'date':
        const notifListOrderByOldest = Util.orderByOldest(notificationsList_table);
        setNotificationsList_table([...notifListOrderByOldest]);
        break;

      case 'no_read':
        const notifListOrderByUnread = Util.orderByUnread(notificationsList_table);
        setNotificationsList_table([...notifListOrderByUnread]);
        break;

      default:
        break;
    }

  }, [orderBy]);

  return (
    <>
      {/* <Grid container style={{ marginTop: 20 }}>

        
        <div style={{width: '100%', display:'flex', justifyContent:'space-around', alignItems:'center'}}>
          <FilterComponent setOrderBy={setOrderBy} /> 
          <img
            src={SettingsIcon} 
            onClick={()=>setStateDrawerConfig(!stateDrawerConfig)}
            style={{cursor:'pointer'}}
          />
        </div>


        <DetailNotificacion 
          notifTitle={notificationChoose?.title} 
          notifContent={notificationChoose?.content} 
          stateDrawer={stateDrawer} 
          fnDrawerState={toggleDrawer}
        /> 


        <div 
          style={{
            height: '60%',
            width:'100%',
            overflow: 'auto',
            maxHeight: '500',
            position: 'relative',
          }}
        > 
          <CustomMaterialTable
              title=""
              columns={columnNotifications}
              data={notificationsList_table}
              onRowClick={(event, rowData, togglePanel) => handleClickListItem(rowData._id)}
              options={
                { 
                  paging: true,
                  initialPage: 1, 
                  actionsColumnIndex: -1,
                  actionsCellStyle: {
                    paddingLeft: 16
                  },
                  headerStyle: {
                    fontWeight: 'bold'
                  },
                  rowStyle: rowData => {
                    if( !rowData.read ) {
                      return {backgroundColor: '#C8B4F3'};
                    }
                    return {};
                  }
                }
              }
              components={{
                Toolbar: props => (
                  <></>
                ),
              }}
            />
        </div>
      </Grid> */}

      {/* <DrawerConfigurations 
        open={stateDrawerConfig} token={token} /> */}

      <NotificationsMain
        userEvent={{ notifications: dataNotif || [], data: dataEvents || [] }}
        onRead={handleRead}
        onUpdateNotificationConfig={handleUpdateNotificationConfig}
      />
    </>
  );
};

export default NotificationsList;
