import React, { useEffect, useState } from 'react';
import { globalHistory } from '@reach/router'

const SectionTitle = () => {
  const [ title, setTitle ] = useState('');

    const getTitleByRoute = (route) => {
        switch(route) {
            case 'dashboard': return 'Dashboard';
            case 'inspections': return 'Inspección';
            case 'details': return 'Detalle de Inspección'
            case 'videochat': return 'Videollamada'
            case 'notifications': return 'Notificaciones';
            case 'guides': return 'Guias';
            
        };
    }

    useEffect(() => {
        setTitle(getTitleByRoute(window.location.pathname.split('/')[1]));
        return globalHistory.listen((route) => {
            setTitle(getTitleByRoute(route.location.pathname.split('/')[1]));
        });
    }, []);

  return (
    <span>
      {title}
    </span>
  );
};

export default SectionTitle;
