import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { inspectionAppUrl, inspectionApiUrl } from "../../../utils/urls";
import AddCommentComponent from "../AddCommentComponent";

import Select from "@material-ui/core/Select";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { setNewInspectionsWithState } from "../../../actions/appDataActions";

import { renewId, sendEmail, addCommentToInspection } from "../../../sevices";
import { WORDING_STATES } from "../../../utils/constants";
import {
  BORDERS_STATES_COLORS,
  STATES_COLORS,
  MAP_ID_STATE,
  STATES_RELATION,
} from "../../../utils/statesMap";
import { makeStyles } from "@material-ui/core/styles";

// const BORDERS_STATES_COLORS = [
//   '#828282', //pendiente
//   '#F2994A', //cancelar
//   '#F2C94C', //rehacer
//   '#EB5757', //rechazado
//   '#6FCF97'  //aprobado
// ];

// const STATES_COLORS = [
//   '#F2F2F2', // PENDIENTE
//   '#f2994a42', // CANCELAR
//   '#FFF9EE', // REHACER
//   '#FBDDDD', // RECHAZADO
//   '#E2F5EA'  // APROBADO
// ];
// const MAP_ID_STATE = [
//   'pendiente',
//   'cancelar',
//   'rehacer',
//   'rechazar',
//   'aprobar',
//   'pendienteApro'
// ];
// let STATES_RELATION = new Map();
// STATES_RELATION.set('pendiente', {goesTo: ['rehacer', 'rechazar', 'aprobar'], index: 0});
// STATES_RELATION.set('pendienteApro', {goesTo: ['rehacer', 'rechazar', 'aprobar'], index: 5});
// STATES_RELATION.set('cancelar', {goesTo: ['rehacer'], index: 1});
// STATES_RELATION.set('rehacer', {goesTo: ['cancelar'], index: 2});
// STATES_RELATION.set('rechazar', {goesTo: [], index: 3});
// STATES_RELATION.set('aprobar', {goesTo: [], index: 4});

const useStyles = makeStyles((theme) => ({
  rootSelectPending: {
    background: STATES_COLORS[0],
    borderRadius: 16,
    padding: "5px 12px !important",
    border: `1px solid ${BORDERS_STATES_COLORS[0]}`,
    color: BORDERS_STATES_COLORS[0],
    textAlign: "center",
  },
  rootSelectCancelar: {
    background: STATES_COLORS[1],
    borderRadius: 16,
    padding: "5px 12px !important",
    border: `1px solid ${BORDERS_STATES_COLORS[1]}`,
    color: BORDERS_STATES_COLORS[1],
    textAlign: "center",
  },
  rootSelectRedo: {
    background: STATES_COLORS[2],
    borderRadius: 16,
    padding: "5px 12px !important",
    border: `1px solid ${BORDERS_STATES_COLORS[2]}`,
    color: BORDERS_STATES_COLORS[2],
    textAlign: "center",
  },
  rootSelectReject: {
    background: STATES_COLORS[3],
    borderRadius: 16,
    padding: "5px 12px !important",
    border: `1px solid ${BORDERS_STATES_COLORS[3]}`,
    color: BORDERS_STATES_COLORS[3],
    textAlign: "center",
  },
  rootSelectApro: {
    background: STATES_COLORS[4],
    borderRadius: 16,
    padding: "5px 12px !important",
    border: `1px solid ${BORDERS_STATES_COLORS[4]}`,
    color: BORDERS_STATES_COLORS[4],
    textAlign: "center",
  },
  selectIcon: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    background: "transparent",
  },
  root: {
    minWidth: 30,
    minHeight: 30,
    background: "#4C1DB7",
  },
  rootOther: {
    background: "#4c1db77a",
  },
  rootDial: {
    position: "relative",
  },
  sizeSmall: {
    width: 30,
    height: 30,
  },
  actions: {
    position: "absolute",
  },
  rootIcon: {
    color: "black",
  },
  rootDialAction: {
    height: "26px",
    width: "26px",
    minHeight: "26px",
    boxShadow: "unset",
    color: "black",
    background: "unset",
    "&hover": {
      background: "unset",
    },
  },
}));

const StatusComponent = ({ id, currentStatus, email, index }) => {
  const token = useSelector((state) => state.auth.token);
  let objToken = null;
  if (token) {
    objToken = jwt_decode(token);
  }

  const dispatch = useDispatch();
  const [user, setUser] = useState(objToken ? objToken.user._id : "");
  const [emailUser, setEmail] = useState(objToken ? objToken.user.email : "");
  const correctValue =
    currentStatus === "pendiente" || currentStatus === "Pendiente"
      ? 0
      : parseInt(currentStatus);
  const [status, setStatus] = useState(correctValue);

  useEffect(() => {
    setStatus(
      currentStatus === "pendiente" || currentStatus === "Pendiente"
        ? 0
        : parseInt(currentStatus)
    );
  }, [currentStatus]);

  const [msgModal, setMsgModal] = useState("este modal para test");
  const [msgTitle, setMsgTitle] = useState(null);
  const [stateChanged, setStateChanged] = useState(null);
  const { inspectionWithStates } = useSelector((state) => state.app);

  const theme1 = (status) =>
    createMuiTheme({
      overrides: {
        MuiSelect: {
          select: {
            "&:focus": {
              backgroundColor: STATES_COLORS[status],
              borderRadius: 16,
              color: BORDERS_STATES_COLORS[status],
            },
            "&:before": {
              borderColor: BORDERS_STATES_COLORS[status],
            },
            "&:after": {
              borderColor: BORDERS_STATES_COLORS[status],
            },
          },
        },
      },
    });

  const classes = useStyles();
  const getSelectColor = (status) => {
    switch (status.toString()) {
      case "0":
      case "5":
        return classes.rootSelectPending;

      case "1":
        return classes.rootSelectCancelar;

      case "2":
        return classes.rootSelectRedo;

      case "3":
        return classes.rootSelectReject;

      case "4":
        return classes.rootSelectApro;
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = async (value) => {
    let newValue = value.toString();
    setStateChanged(newValue);

    switch (newValue) {
      case "1":
        setMsgModal(
          "Estado Cambiado. Por favor, explique a continuacion los motivos de este cambio."
        );
        setMsgTitle("Cancelar Inspeccion");
        handleOpen();

        break;

      case "2":
        setMsgModal(
          "Solicitud enviada. Esta inspecccion estara en estado pendiente por rehacer. Por favor, explique a continuacion los motivos de este cambio de estado"
        );
        setMsgTitle("Rehacer Inspeccion");
        handleOpen();

        break;

      case "3":
        setMsgModal(
          "Estado Cambiado. Por favor, explique a continuacion los motivos de este cambio."
        );
        setMsgTitle("Rechazar Inspeccion");

        const messageRej = `
          Se le notifica que su inspeccion ha sido rechazada.
          Sistran Team!
        `;
        const emailObjRej = {
          message: messageRej,
          to: email,
          subject: "Sistran mail: Inspeccion Rechazada",
        };
        handleOpen();
        break;

      case "4":
        const messageApro = `
          Se le notifica que su inspeccion ha sido aprobada.
          Sistran Team!
        `;
        const emailObjApro = {
          message: messageApro,
          to: email,
          subject: "Sistran mail: inspeccion Aprobada",
        };
        await addCommentToInspection(
          id,
          "Inspeccion Aprobada",
          emailUser,
          user
        );
        sendEmail(emailObjApro);

        await axios.put(`${inspectionApiUrl}/inspections/details/${id}`, {
          status: newValue,
        });
        await axios.post(`${inspectionApiUrl}/inspections/changeStatus`, {
          inspectionId: id,
          status: newValue,
          emailAuthor: emailUser,
        });
        console.log("cambie su valor", parseInt(newValue));
        setStatus(parseInt(newValue));
        break;

      default:
        break;
    }
  };

  const handleCancel = (isCanceled) => {
    console.log("isCanceled=>", isCanceled);
  };

  const handleAcceptModal = async () => {
    switch (stateChanged.toString()) {
      case "1":
        const messageCan = `
        Se le notifica que su inspeccion ha sido cancelada.
        Sistran Team!
        `;
        const emailObjCan = {
          message: messageCan,
          to: email,
          subject: "Sistran mail: Inspeccion Cancelada",
        };

        await addCommentToInspection(
          id,
          "Inspeccion Cancelada",
          emailUser,
          user
        );
        sendEmail(emailObjCan);

        break;

      case "2":
        const idChanged = await renewId(id);
        const messageRenw = `
          Hola,
          Se ha solicitado que su inspeccion sea realizada nuevamente. Haga click en el siguiente enlace
          Link: ${inspectionAppUrl}?id=${idChanged}
          Saludos,
          Sistran Team!
        `;
        const emailObjRenw = {
          message: messageRenw,
          to: email,
          subject: "Sistran mail: Rehacer inspeccion",
        };

        sendEmail(emailObjRenw);
        await addCommentToInspection(
          id,
          "Inspeccion solicitada nuevamente",
          emailUser,
          user
        );

        break;

      case "3":
        await addCommentToInspection(
          id,
          "Inspeccion rechazada",
          emailUser,
          user
        );
        const messageRej = `
          Se le notifica que su inspeccion ha sido rechazada.
          Sistran Team!
        `;
        const emailObjRej = {
          message: messageRej,
          to: email,
          subject: "Sistran mail: Inspeccion Rechazada",
        };
        sendEmail(emailObjRej);

        break;

      case "4":
        const messageApro = `
          Se le notifica que su inspeccion ha sido aprobada.
          Sistran Team!
        `;
        const emailObjApro = {
          message: messageApro,
          to: email,
          subject: "Sistran mail: inspeccion Aprobada",
        };
        await addCommentToInspection(
          id,
          "Inspeccion Aprobada",
          emailUser,
          user
        );
        sendEmail(emailObjApro);
        break;

      default:
        break;
    }

    const inspection = inspectionWithStates.find( insp => insp._id === id );
    inspection.status = stateChanged;
    const restInspections = inspectionWithStates.filter( insp => insp._id !== id );
    dispatch(setNewInspectionsWithState([...restInspections, inspection]));
    setStatus(parseInt(stateChanged));

    await axios.put(`${inspectionApiUrl}/inspections/details/${id}`, {
      status: stateChanged,
    });
    await axios.post(`${inspectionApiUrl}/inspections/changeStatus`, {
      inspectionId: id,
      status: stateChanged,
      emailAuthor: emailUser,
    });



  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          <strong>{msgTitle || "Agregar comentario"}</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msgModal}
            <AddCommentComponent
              id={id}
              close={handleClose}
              runFn={handleCancel}
              handleAccept={handleAcceptModal}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <div className={classes.rootDial}>
        <ThemeProvider theme={theme1(status)}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status}
            disableUnderline
            onChange={(event) => handleChange(event.target.value)}
            classes={{
              root: getSelectColor(status),
              icon: classes.selectIcon,
            }}
            disabled={
              STATES_RELATION.get(MAP_ID_STATE[status]).goesTo.length < 1
            }
          >
            {STATES_RELATION.get(status.toString()).goesTo.length > 0 && [
              STATES_RELATION.get(status.toString()).goesTo.map((option) => {
                return (
                  <MenuItem
                    value={STATES_RELATION.get(option)?.index}
                    key={STATES_RELATION.get(option)?.index}
                  >
                    {WORDING_STATES.get(option).infinitive}
                  </MenuItem>
                );
              }),
              <MenuItem value={status} style={{ display: "none" }} key={status}>
                {WORDING_STATES.get(status.toString()).past_participle}
              </MenuItem>,
            ]}
            {STATES_RELATION.get(status.toString()).goesTo.length === 0 && (
              <MenuItem value={status}>
                {WORDING_STATES.get(status.toString()).past_participle}
              </MenuItem>
            )}
          </Select>
        </ThemeProvider>
      </div>
    </>
  );
};

export default StatusComponent;
