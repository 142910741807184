import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BarMenu from './BarMenu';
import SideMenu from './SideMenu';
import { getEventsByUserId, getNotificationsByUserId } from '../../actions/userEventsActions';
import { EVENTS_MODULES } from '../notifications/utils/urls';
import { setUserEventListeners } from '../notifications/services/eventListener';
import Logo from "../../assets/images/iConnectance_mini_logo_2.png";

const ContainerMenu = () => {
    const dispatch = useDispatch();
    const [menuOpen, setMenuOpen] = useState(false);
    const token = useSelector((state) => state.auth.token);
    const userEvents = useSelector((state) => state.userEvents);
    const config = useSelector((state) => state.userConfig.data);

    useEffect(() => {
      initUserEvents();

      //react-hooks/exhaustive-deps
    }, []);

    const initUserEvents = async () => {
      await dispatch(getEventsByUserId(token, EVENTS_MODULES));
      await dispatch(getNotificationsByUserId(token));
    };

    useEffect(() => {
      if (userEvents.data.length > 0) {
        initSockets();
      }
    }, [userEvents]);

    const initSockets = async () => {
      setUserEventListeners(
        userEvents.io,
        userEvents.data,
        token,
        dispatch,
        config.logoImage ? config.logoImage : Logo
      );
    };

    const handleDrawerMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <Fragment>
            <BarMenu menuOpen={menuOpen} handleDrawerMenu={handleDrawerMenu} />
            <SideMenu menuOpen={menuOpen} handleDrawerMenu={handleDrawerMenu} />
        </Fragment>
    );
};

export default ContainerMenu;
