import jwt_decode from "jwt-decode";

export const getParams = function (url) {
	var params = {};
	var parser = document.createElement('a');
	parser.href = url;
	var query = parser.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		params[pair[0]] = decodeURIComponent(pair[1]);
  }
  
	return params;
};

export const decodedToken = (token = null) => {
	if(token) {
		const tokenDecode = jwt_decode(token)
		return tokenDecode
	} else {
		// const obj = JSON.parse(localStorage.getItem('persist:root_cotizador_backoffice'))
		// const auth = JSON.parse(obj.auth)
		// const tokenDecode = jwt_decode(auth.token)
	
		// return tokenDecode
		return null
	}
}

export const getDataToken = (token = null) => {
	if(token) {
		const tokenDecode = jwt_decode(token)
		return tokenDecode
	} else {
		const auth = localStorage.getItem("iC:IBO");
		const tokenDecode = jwt_decode(auth)

		return tokenDecode
	}
}

export const getToken = () => {
	const token = localStorage.getItem("iC:IBO");
	return token;
};

export const saveToken = (token = null) => {
  if (token) localStorage.setItem("iC:IBO", token);
};

export const OrderByFilter = (arrayData, criteria) => {
	
	if (criteria && criteria.includes('date')) {
		const _dataSorted = arrayData.sort((a, b) => { 
			return new Date(a.date) - new Date(b.date)
		});
		return _dataSorted;
	}

	const dataSorted = arrayData.sort((a, b) => { 
		return a[criteria] - b[criteria] 
	});
	return dataSorted;
}

export const isEmptyNullObject = obj => { 
		if (obj) {
			return Object.keys(obj).length === 0 
		}
		return true;
	
}