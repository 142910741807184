import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  IconButton,
  ListItem,
  ListItemText,
  List,
  ListSubheader,
  Divider,
  Drawer
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { format } from 'date-fns'

import DrawerDetailNotification from '../drawerDetailNotification'
import { useStyles } from './styles'

const DrawerNotificationBarMenu = ({
  userEvent,
  theme,
  onRead,
  navigate,
  goBack,
  open
}) => {
  const classes = useStyles({ theme })
  const [selectedMessage, setSelectedMessage] = useState({})
  const [openDrawer, setOpenDrawer] = useState(false)
  const notificationsList = userEvent.notifications.sort(
    (a, b) => new Date(b.sentAt) - new Date(a.sentAt)
  )

  const handleReadMessage = async (noti) => {
    setSelectedMessage(noti)
    handleToggleDrawer(true)
  }

  const handleRead = async (noti) => {
    if (!noti.read) {
      onRead(noti)
    }
  }

  const handleToggleDrawer = (opt) => setOpenDrawer(opt)

  const showMoreNotif = () => {
    return (
      <>
        <ListItemText
          onClick={() => navigate('/notifications')}
          classes={{
            root: classes.rootNotif
          }}
        >
          <ListItemText
            primary={
              <strong>
                <p style={{ color: '#333', textAlign: 'center', margin: 0 }}>
                  Ver mas
                </p>
              </strong>
            }
          />
        </ListItemText>
      </>
    )
  }

  const generateList = () => {
    return (
      <List
        classes={{
          root: classes.rootSubheaderWrapper
        }}
        subheader={
          <>
            <ListSubheader
              classes={{
                root: classes.rootSubheader
              }}
              disableSticky
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '10px 0px'
                }}
              >
                <Typography color='primary'>
                  <strong>Notificaciones</strong>
                </Typography>
                <IconButton
                  color='inherit'
                  className={classes.noPadding}
                  onClick={goBack}
                >
                  <Close />
                </IconButton>
              </div>
            </ListSubheader>
            <Divider variant='middle' component='li' />
          </>
        }
      >
        {notificationsList.length > 0 &&
          notificationsList
            .filter((_, i) => i < 10)
            .map((notification, index) => {
              const _date = format(
                new Date(notification.sentAt),
                'hh:mm   dd/MM/yyyy'
              ).split('   ')

              return (
                <Fragment key={'notif' + index}>
                  <ListItem
                    onClick={() => handleReadMessage(notification)}
                    alignItems='flex-start'
                    classes={{
                      root: !notification?.read
                        ? classes.rootNotifUnread
                        : classes.rootNotif
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <ListItemText
                        primary={
                          <strong style={{ color: '#333' }}>
                            {notification?.message?.title}
                          </strong>
                        }
                        secondary={
                          <Fragment>
                            <Typography
                              component='span'
                              variant='body2'
                              className={classes.inline}
                              color='textPrimary'
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: notification?.message?.content
                                }}
                              />
                            </Typography>
                          </Fragment>
                        }
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          color: '#333'
                        }}
                      >
                        <small style={{ color: '#D8D8D8' }}>
                          <i>{`${_date[1]}   `}</i>
                        </small>
                        <small style={{ marginLeft: 5 }}>
                          <i>{_date[0]}</i>
                        </small>
                      </div>
                    </div>
                  </ListItem>
                  <Divider variant='middle' component='li' />
                </Fragment>
              )
            })}
        {notificationsList.length > 0 && showMoreNotif()}
        {notificationsList.length === 0 && (
          <ListItem>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ListItemText
                secondary={
                  <Fragment>
                    <Typography
                      component='span'
                      variant='body2'
                      className={classes.inline}
                      color='textPrimary'
                    >
                      No posee notificaciones actualmente
                    </Typography>
                  </Fragment>
                }
              />
            </div>
          </ListItem>
        )}
      </List>
    )
  }

  return (
    <Fragment>
      <Drawer
        variant='persistent'
        anchor='right'
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
        onClose={goBack}
      >
        {generateList()}
      </Drawer>

      <DrawerDetailNotification
        open={openDrawer}
        close={() => handleToggleDrawer(false)}
        notification={selectedMessage}
        handleRead={handleRead}
      />
    </Fragment>
  )
}

DrawerNotificationBarMenu.propTypes = {
  userEvent: PropTypes.object.isRequired,
  theme: PropTypes.object,
  onRead: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default DrawerNotificationBarMenu
