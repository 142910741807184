import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    maxWidth: '80%',
    margin: '0 auto'
  },
});

export default function BasicTable({ title="Table", columns, data }) {
  const classes = useStyles();
  
  const createData = (columns, data) => {
    const row = [];
    for ( let i = 0; i < columns.length; i++) {
      row.push( data[columns[i].field] );
    } 
    return row; 
  }

  return (
    <>
      <div style={{margin:'0 auto', maxWidth: '60%'}}>
        <h2>{title}</h2>
      </div>
      <TableContainer component={Paper} style={{ maxHeight: '440px' }}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          {/* <TableRow>
            {columns.map( (column, index) => {
              return <TableCell key={index+column.field}>{column.title}</TableCell>
            })}
          </TableRow> */}
        </TableHead>
        <TableBody>
          {data.map( row =>   
            <TableRow key={row.name}>
              {
                createData(columns, row).map( value => 
                  <TableCell component="th" scope="row">
                    {value}
                  </TableCell>
                )
              }
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}