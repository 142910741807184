import React, { useState, useEffect } from "react";
import { Grid, ExpansionPanelDetails } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircle } from "@material-ui/icons";
import { Field, reduxForm } from "redux-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  renderSwitch,
  RenderTextFieldDisabled,
  renderAutocompleteField,
  RenderRadioGroupDisabled,
  RenderCardRadioGroup,
} from "../form/renderInputsForm";
import SortablePanel from "../sortable/SortablePanel";
import { ControlledExpansionPanel } from "./guidePanel";
import { getStateGuide } from "../../actions/guidesStateActions";
import { getRiskGuide } from "../../actions/guidesRiskActions";
import vehicleGrid8 from "./../../assets/images/grid-car-8.png";
import vehicleGrid10 from "./../../assets/images/grid-car-10.png";

const useStyles = makeStyles(() => ({
  cardImage: {
    textAlign: "center",
    border: "4px dashed #f4f4f4",
    width: 200,
    margin: "30px 10px",
    flexWrap: "wrap",
  },
}));

const GuidesShowForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form);
  const risks = useSelector((state) => state.risks.data);
  const states = useSelector((state) => state.states.data);
  const token = useSelector((state) => state.auth.token);
  const [, setTypePhoto] = useState(null);

  const handleOpen = (type) => {
    setTypePhoto(type);
  };

  useEffect(() => {
    if (token) {
      dispatch(getStateGuide(token));
      dispatch(getRiskGuide(token));
      handleOpen({ toOpen: "Externa", index: 0 });
      handleOpen({ toOpen: "Interna", index: 1 });
      handleOpen({ toOpen: "Documentacion", index: 2 });
    }
  }, [dispatch, token]);

  return (
    <form>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} style={{ padding: 8 }}>
          <Field
            name="isDefault"
            label="Por Defecto"
            custom={{ tabIndex: 1 }}
            component={renderSwitch}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ padding: 8 }}>
          <Field
            name="name"
            label="Nombre"
            custom={{ tabIndex: 3 }}
            component={RenderTextFieldDisabled}
          />
          <Field
            name="codeId"
            label="Código"
            custom={{ tabIndex: 2 }}
            component={RenderTextFieldDisabled}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ padding: 8 }}>
          <Field
            name="state"
            label="Estado"
            tab-index="4"
            component={renderAutocompleteField}
            options={states}
            getOptionLabel={(option) => (option ? option.name : "")}
            getOptionSelected={(option, value) => option._id === value._id}
            disabled
          />
          <Field
            name="risk"
            label="Riesgo"
            tab-index="5"
            component={renderAutocompleteField}
            options={risks}
            getOptionLabel={(option) => (option ? option.name : "")}
            getOptionSelected={(option, value) => option._id === value._id}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={12}>
          {
            <>
              <h3>Sección de Fotos</h3>
              {form[props.form]?.values?.photos?.map((group, index) => (
                <ControlledExpansionPanel
                  key={`group_${index}`}
                  summary={
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      style={{ padding: "0 1rem" }}
                    >
                      <Grid item>{group.name}</Grid>
                      <Grid
                        container
                        style={{ width: "auto" }}
                        item
                        justify="space-between"
                        alignItems="center"
                      ></Grid>
                    </Grid>
                  }
                >
                  <ExpansionPanelDetails>
                    <Grid container justify="center" spacing={2}>
                      {group.name === "Externa" && group.related.length ? (
                        <Grid item xs={11} style={{ padding: 8 }}>
                          <Field
                            name={`photos[${index}].type`}
                            component={RenderRadioGroupDisabled}
                            options={[
                              { value: "card", label: "Tarjeta" },
                              { value: "image", label: "Imagen" },
                            ]}
                            custom={{
                              defaultValue: "card",
                              tabIndex: 1,
                            }}
                            value={"card"}
                          />
                        </Grid>
                      ) : null}
                      {group.type === "card" ? (
                        <SortablePanel group={group} index={index} />
                      ) : (
                        <Grid item xs={11}>
                          <Grid lg={10} container item>
                            <Field
                              disabled={true}
                              value={"8"}
                              name={`photos[${index}].model`}
                              component={RenderCardRadioGroup}
                              custom={{
                                icon: <CheckCircle />,
                                tabIndex: 1,
                                options: [
                                  {
                                    source: vehicleGrid8,
                                    value: "8",
                                    label: "8 Fotos",
                                  },
                                  {
                                    source: vehicleGrid10,
                                    value: "10",
                                    label: "10 Fotos",
                                  },
                                ],
                              }}
                              value={"card"}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </ExpansionPanelDetails>
                </ControlledExpansionPanel>
              ))}
            </>
          }
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: "GuideShowForm",
  enableReinitialize: true,
})(GuidesShowForm);
