import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { getDataUserLoggedIn } from '../../actions/userInfoActions';
import CommentsTab from '../../components/videchat/Tabs/commentsPanel';
import DataChat from '../../components/videchat/Tabs/dataChat';
import SnapShotPanel from '../../components/videchat/Tabs/snapShotPanel';
import VideoRoomComponent from '../../components/videocall/VideoRoomComponent';
import { getParams } from '../../utils';
import { inspectionApiUrl } from '../../utils/urls';

const VideoCall = () => {
  const dispatch = useDispatch();
  const [valueTab, setValueTab] = useState(0);
  const [clientData, setDataClient] =  useState(null);
  const [dataUser, setDataUser] = useState(null);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [ room ] = useState(urlParams.get('room'));
  const [remoteVideo, setRemoteVideo] = useState(null);
  const token = useSelector((state) => state.auth.token);

  let objToken = null;
    if ( token ) {
        objToken = jwt_decode(token);
    }
  

  useEffect(() => {
    const params = getParams(window.location.href);
    axios.get(`${inspectionApiUrl}/inspections/temporary/${params.room}`)
        .then(({ data }) => {
            setDataClient(data)
        });
  },[])

  useEffect(() => {
    if (token && objToken?.user?._id ) { 
      setDataUser(objToken.user);
    }
}, [token]);

  const handleChangeTabs = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleRemoteVideo = (remote) => {
    setRemoteVideo(remote);
  };
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <VideoRoomComponent sessionName={room} handleRemoteVideo={handleRemoteVideo} />
      </Grid>
      <Grid item xs={6}>
        <div style={{ backgroundColor: 'white', padding: 10, height: 'calc(100vh - 240px)', borderRadius: 15 }}>
          <Tabs
            value={valueTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTabs}
            aria-label="disabled tabs example"
          >
            <Tab label="DATOS" />
            <Tab label="COMENTARIOS" />
            <Tab label="FOTOS" />
          </Tabs>
          <section style={{ backgroundColor: 'white' }}>
            <DataChat showTab={valueTab === 0} userData={dataUser} />
            <CommentsTab showTab={valueTab === 1} id={room} />
            <SnapShotPanel showTab={valueTab === 2} idVideo={room} remoteVideo={remoteVideo} data={clientData} />
          </section>
        </div>
      </Grid>
    </Grid>
  );
};

export default VideoCall;
