import {
    GET_GUIDES_PENDING,
    GET_GUIDES_FULFILLED,
    GET_GUIDES_REJECTED,
    ADD_GUIDE_PENDING,
    ADD_GUIDE_FULFILLED,
    ADD_GUIDE_REJECTED,
    UPDATE_GUIDE_PENDING,
    UPDATE_GUIDE_FULFILLED,
    UPDATE_GUIDE_REJECTED,
    DELETE_GUIDE_PENDING,
    DELETE_GUIDE_FULFILLED,
    DELETE_GUIDE_REJECTED,
} from "../actions/types";

const initialState = {
    data: [],
    filteredData: [],
    loading: false,
    error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_GUIDES_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_GUIDES_FULFILLED:
            return {
                ...state,
                data: action.payload.docs,
                totalDocs: action.payload.totalDocs,
                loading: false,
                error: false,
            };
        case GET_GUIDES_REJECTED:
            return {
                ...state,
                data: [],
                loading: false,
                error: true,
            };
        
        case ADD_GUIDE_PENDING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case ADD_GUIDE_FULFILLED:
            return {
                ...state,
                data: [...state.data, action.payload],
                loading: false,
                error: false
            };
        case ADD_GUIDE_REJECTED:
                return {
                    ...state,
                    loading: false,
                    error: true
                };
        case UPDATE_GUIDE_PENDING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case UPDATE_GUIDE_FULFILLED: {

            const index = state.data.findIndex(item => item._id === action.payload._id)

            const newData = [...state.data]

            newData[index] = action.payload

            return {
                ...state,
                data: newData,
                loading: false,
                error: false
            };
        }
        case UPDATE_GUIDE_REJECTED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case DELETE_GUIDE_PENDING:
            return {
                ...state,
                loading: true,
                error: false
            };
        case DELETE_GUIDE_FULFILLED:
            return {
                ...state,
                data: state.data.filter(d => d._id !== action.payload.id),
                loading: false,
                error: false
            };
        case DELETE_GUIDE_REJECTED:
            return {
                ...state,
                loading: false,
                error: true
            };
        default:
            return state;
    }
}