import { 
  SHOW_SUCCESS, 
  SHOW_ERROR, 
  SHOW_WARNING, 
  HIDE_SUCCESS, 
  HIDE_WARNING, 
  HIDE_ERROR, 
  SHOW_MESSAGE, 
  HIDE_MESSAGE 
} from "./types";

export const showSuccess = (msg) => (dispatch) => {
  dispatch({
    type: SHOW_SUCCESS,
    payload: true,
    msg: msg ? msg : false
  });
};

export const hideSuccess = () => (dispatch) => {
  dispatch({
    type: HIDE_SUCCESS,
    payload: false
  });
};

export const showWarning = (message) => (dispatch) => {
  dispatch({
    type: SHOW_WARNING,
    payload: message
  });
};

export const showError = (message) => (dispatch) => {
  dispatch({
    type: SHOW_ERROR,
    payload: message
  });
};

export const hideWarning = () => (dispatch) => {
  dispatch({
    type: HIDE_WARNING,
    payload: false
  });
};

export const hideError = () => (dispatch) => {
  dispatch({
    type: HIDE_ERROR,
    payload: false
  });
};

export const showMessage = () => (dispatch) => {
  dispatch({
    type: SHOW_MESSAGE,
    payload: true
  });
};

export const hideMessage = () => (dispatch) => {
  dispatch({
    type: HIDE_MESSAGE,
    payload: false
  });
};