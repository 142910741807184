import React, { useEffect, useState } from "react";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { getCommentsWithTemporaryId, setComment } from "../../../sevices";
import { useSelector } from "react-redux";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from "../../../components/inspection/Table";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import SendIcon from "@material-ui/icons/Send"
import SendIcon from "../../../assets/images/sendIcon.png";


const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 20,
        height: 120,
        width: '95%',
        fontSize: '14px !important',
        padding: '3px 10px',
        overflow: 'hidden',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    minWidth: '35px',
    padding: '15px'
  },
}));

const columnsComments = [
  // { title: 'Fecha', field: 'date' },
  { title: "Comentario", field: "comment" },
  { title: "Email", field: "email" },
];

const CommentsTab = ({ showTab, id }) => {
  const classes = useStyles();
  const token = useSelector((state) => state.auth.token);
  let objToken = null;
  if (token) {
    objToken = jwt_decode(token);
  }

  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [isActive, setIsActive] = useState(showTab);
  const [value, setValue] = useState("");

  const saveComment = () => {
    // *TODO - Enviar fecha al objeto comments
    const data = {
      comment: value,
      user: objToken.user._id,
      email: objToken.user.email,
    };
    if (value !== "") {
      let promiseSetCmmt = Promise.resolve(setComment(id, data));
      promiseSetCmmt.then(({ data }) => {
        setComments(data);
        setValue("");
      });
    }
  };

  function formatDatesData(arrData) {
    const formatedDateData = arrData.map((obj) => {
      const _date = new Date(obj.date);
      const _comment = obj.comment;
      const newDate =
        _date.getDate().toString() +
        "/" +
        (_date.getMonth() + 1).toString() +
        "/" +
        _date.getFullYear().toString() +
        " " +
        _date.getHours().toString() +
        ":" +
        _date.getMinutes().toString();
      // const comments = {_comment, newDate}
      return { ...obj, date: newDate };
    });
    return formatedDateData;
  }

  useEffect(() => {
    setLoading(true);
    let promiseGetCmmts = Promise.resolve(getCommentsWithTemporaryId(id));
    promiseGetCmmts.then(({ data }) => {
      const formatedDateData = formatDatesData(data);
      setComments(formatedDateData);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setIsActive(showTab);
  }, [showTab]);

  return (
    <div style={{ display: !isActive && "none" }}>
      {loading && <p>Cargando</p>}
      {!loading && <Table title="" data={comments} columns={columnsComments} />}
      <div style={{ display: "flex", marginTop: 20, alignItems: "self-end", width: '80%', marginLeft: '80px' }}>
      <CssTextField
          multiline
          rows={5}
          variant="outlined"
          value={value}
          placeholder="Escribe un comentario"
          onChange={(ev) => setValue(ev.target.value)}
          style={{ flex: 1, borderRadius: "8px" }}
        />
        <img style={{ paddingTop: '10px', cursor: "pointer", marginBottom: '12px' }} src={SendIcon} width={30} height={30} onClick={saveComment} />
      </div>
    </div>
  );
};

export default CommentsTab;
