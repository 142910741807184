import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    sectionPassword: {
        paddingTop: 32,
        paddingBottom: 32
    },
    spaceInput: {
        paddingBottom: 20,
        width: '100%',
        '& label':{
            color: '#AF8AFC !important'
        }
    },
    sectBtnSave: {
        paddingTop: 20,
        '& .btn-save': {
            backgroundColor: '#6C63FF'
        }
    }
}))