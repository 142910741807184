import React from 'react';
import GuideUI from './guides';
const GuidesComponent = () => {
    return (
        <>
            <GuideUI />
        </>
    )
}

export default GuidesComponent;