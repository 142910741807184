import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import jwt_decode from "jwt-decode";
import { inspectionApiUrl } from  '../../utils/urls';
import { 
  setNewComment
} from '../../actions/appDataActions';
import axios from 'axios';

const AddCommentComponent = ({ id, close, runFn=()=>{}, handleAccept=()=>{} }) => {
  const token = useSelector((state) => state.auth.token); 
  const commentList = useSelector((state) => state.app.commentsList); 
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  let objToken = null;
  if ( token ) {
    objToken = jwt_decode(token);
  }
  const [user, setUser] = useState(objToken ? objToken.user._id : '');
  const [email, setEmail] = useState(objToken ? objToken.user.email : '');

  const handleChange = (ev) => {
    setValue(ev.target.value);
  }

  const handleSaveComment = async () => {
    handleAccept();
    const data = {comment: value, user, email};

    if ( commentList[id] ) {
      dispatch(setNewComment(id, data));
    }

    const _log = await axios.post(`${inspectionApiUrl}/comments/${id}`, data);
    setValue('');
    close();
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        justifyContent:'center'
      }}
    >
       <div style={{width:'100%'}}>
        <textarea
          value={value}
          onChange={handleChange}
          style={{width:'100%', marginTop:20}}
        ></textarea>
      </div>
      <div style={{width: '100%', display:'flex', justifyContent:'space-between', paddingTop:'15px'}}>
        <Button onClick={()=>{runFn(true); close()}} color="primary">
          Cancelar
        </Button>
        <Button 
          onClick={handleSaveComment} 
          color="primary"
          disabled={value === ''}
        >
          Aceptar
        </Button>
      </div>
    </div>
  )
}

export default AddCommentComponent;