import {
    ADD_INSPECTION_PENDING,
    ADD_INSPECTION_FULFILLED,
    ADD_INSPECTION_REJECTED
} from "../actions/types";

const initialState = {
	dataClient: null,
	data: [],
	loading: false,
	error: false,
};
  
export default function (state = initialState, action) {
    switch (action.type) {
      case ADD_INSPECTION_PENDING:
				return {
					...state,
					error: false,
					loading: true,
				};
			case ADD_INSPECTION_FULFILLED:
				return {
					...state,
					data: [...state.data, action.payload],
					loading: false,
				};
			case ADD_INSPECTION_REJECTED:
				return {
					...state,
					error: true,
					loading: false,
				};
			case 'SET_DATA_CLIENT': 
				return {
					...state,
					dataClient: action.payload
				}
      default:
        return state;
    }
}
