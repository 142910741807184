import React, { useState } from "react";
import { navigate } from "@reach/router";
import Sticky from "react-sticky-el";
import CancelModal from "../modal/cancelModal";
import { useDispatch, useSelector } from "react-redux";
import { submit, reset, touch } from "redux-form";
import {
  Grid,
  Typography,
  Button,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { decodedToken } from "../../utils/index";
import { addGuide, updateGuide } from "../../actions/guidesActions";
import MessageError from "./../messages/messageError";
import { showError } from "../../actions/messagesActions";

const GuideTitle = ({ typeForm, handleSuccess }) => {
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form);
  const token = useSelector((state) => state.auth.token);
  const guides = useSelector((state) => state.guides);
  const auth = useSelector((state) => state.auth);
  const [openCancel, setOpenCancel] = useState(false);

  const handleSaveGuide = async () => {
    switch (typeForm) {
      case "guideAdd":
        dispatch(submit("GuideAddForm"));
        dispatch(touch("GuideAddForm"));
        await addNewGuide();

        return;
      case "guideEdit":
        dispatch(submit("GuidesEditForm"));
        dispatch(touch("GuidesEditForm"));
        await editGuide();
        return;
      default:
        break;
    }
  };

  const addNewGuide = async () => {
    const userInfo = decodedToken(auth.token);
    let newGuide = {};

    if (
      form.GuideAddForm.anyTouched &&
      !form.GuideAddForm.submitFailed &&
      !form.GuideAddForm.syncErrors
    ) {
      const { photos, ...rest } = form.GuideAddForm.values;
      newGuide = { ...rest };
      newGuide.companyId = userInfo.user.companyId;
      let photoAvailable = 0;

      photos.forEach((element) => {
        if (element.related.length) {
          photoAvailable++;
        }
      });

      if (!photos.length && !photoAvailable) {
        dispatch(showError("Se debe registrar al menos una seccion"));
        return false;
      }

      photoAvailable = 0;

      newGuide.photos = photos.map((photo) => ({
        ...photo,
        type: photo.type === "image" ? "image" : "card",
      }));

      let shouldPass = true;

      for (const section of newGuide.photos) {
        console.log('section=>', section.related);
        if (section.related.length === 0) {
          shouldPass = false;
          break;
        }        
      }
      if ( !shouldPass ) {
        dispatch(showError("Se debe registrar al menos una foto en cada seccion"));
        return;
      }
      await dispatch(addGuide(token, newGuide));
      dispatch(reset("GuideAddForm"));
      handleSuccess();
      return;
    }
  };

  const editGuide = async () => {
    const userInfo = decodedToken(auth.token);
    let newGuide = {};

    if (!form.GuidesEditForm.submitFailed && !form.GuidesEditForm.syncErrors) {
      const { photos, ...rest } = form.GuidesEditForm.values;
      newGuide = { ...rest, newGuide };
      newGuide.companyId = userInfo.user.companyId;

      let photoAvailable = 0;

      photos.forEach((element) => {
        if (element.related.length) {
          photoAvailable++;
        }
      });

      if (!photos.length && !photoAvailable) {
        dispatch(showError("Se debe registrar al menos una foto"));
        return false;
      }

      photoAvailable = 0;
      
      newGuide.photos = photos.map((photo) => ({
        ...photo,
        type: photo.type === "image" ? "image" : "card",
      }));
      await dispatch(updateGuide(token, newGuide));
      handleSuccess();

      return;
    }
  };

  const handleBackPage = () => {
    setOpenCancel(true);
  };

  const handleAccept = () => {
    setOpenCancel(false);
    navigate("/guides");
  };

  const handleCancel = () => {
    setOpenCancel(false);
  };

  return (
    <>
      <Sticky hideOnBoundaryHit={true} style={{ paddingTop: 16, zIndex: 200 }}>
        <Paper style={{ marginBottom: 16, boxShadow: "none" }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h5">Administración de Guias</Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container justify="flex-end">
                <Button
                  variant="outlined"
                  style={{ marginRight: 16 }}
                  onClick={() => handleBackPage()}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={guides.loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => handleSaveGuide()}
                >
                  Guardar {guides.loading && <CircularProgress size={24} />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <CancelModal
          openModal={openCancel}
          handleAccept={handleAccept}
          handleCancel={handleCancel}
        />
      </Sticky>
      <MessageError />
    </>
  );
};

export default GuideTitle;
