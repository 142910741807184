import { useEffect, useState } from 'react';
import axios from 'axios';
import { inspectionApiUrl } from  '../utils/urls';
import { useGetGuideInfoWithTemporaryId } from '../sevices/inspectionService';

export const useGetInspectionWithTemporaryId = ( temporaryId ) => {
    const [data, setData] = useState([])
    
    useEffect(() => {
        
        axios.get(`${inspectionApiUrl}/inspections/temporary/${temporaryId}`)
            .then(( data ) => { setData(data[0]) } )
            .catch( e => setData(null) )

    },[])

    return data;
}

export const useGetGuide = ( companyId ) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.get(`${inspectionApiUrl}/guides/company/${companyId}`)
            .then( ({ data }) => {
                setData(data.docs);
                setLoading(false);
            })
            .catch( e => setData(null))

    },[])

    return { 
        data, 
        loading
    };
}