import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  Grid,
  Box,
  Typography,
  MenuItem,
  TextField,
  DialogContentText,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  Divider,
} from "@material-ui/core";
import { navigate } from '@reach/router';
import { DriveEta, Pageview, Person } from "@material-ui/icons";
import { useStyles } from "./styles";
import { getParams } from "../../../utils/index";
import jwt_decode from "jwt-decode";
import {
  eventsUrlApi,
  inspectionApiUrl,
  inspectionAppUrl,
} from "../../../utils/urls";
import { RenderTextFieldDisabled } from "../../form/renderInputsForm";
import { useGetBrandsSWR } from "../../../hooks/inspections";
import { useForm } from "react-hook-form";
import { getModels } from "../../../sevices/inspectionService";
import MenuFab from "../../inspection/MenuVideochat";
import { updateTracking } from "../../../sevices";
import { useSelector } from "react-redux";
import AddCommentComponent from "../../inspection/AddCommentComponent";

const DataChat = ({ showTab, room, userData }) => {
  const classes = useStyles();
  const [dataClient, setDataClient] = useState(null);
  const [isActive, setIsActive] = useState(showTab);
  const brands = useGetBrandsSWR();
  const [models, setModels] = useState([]);
  const { register } = useForm();
  const [titleDialog, setTitleDialog] = useState("");
  const [msgModal, setMsgModal] = useState(
    "Su correo ha sido enviado exitosamente!"
  );
  const [idComment, setIdComment] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const token = useSelector((state) => state.auth.token);

  let objToken = null;
  if (token) {
    objToken = jwt_decode(token);
  }
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const errorMessageMissingGuide = () => {
    setTitleDialog("Error envio de inspeccion");
    setMsgModal(
      "Esta inspeccion no tiene asociada una guia. Por favor, elija una antes de enviar la inspeccion"
    );
    handleClickOpenDialog();
  };

  const handleClickSendWhts = (id, whatsappNumber, guideId) => {
    if (!guideId) {
      errorMessageMissingGuide();
      return;
    }
    const message = `Completa tu autoinspeccion siguiente el siguiente enlace: ${encodeURIComponent(
      inspectionAppUrl
    )}?id=${id}`;
    const whts = {
      message: message,
      to: whatsappNumber,
    };

    axios
      .post(`${eventsUrlApi}/notification/sendWhatsapp`, whts)
      .then((res) => {
        if (res.data.success) {
          setMsgModal("Su mensaje por whatsapp ha sido enviado exitosamente!");
          setTitleDialog("Envio de WhatsApp");
          updateTracking(
            id,
            "BO inspection: Enviado Mensaje de WhatsApp a " + whatsappNumber,
            0,
            objToken?.user?.email
          );
          handleClickOpenDialog();
        }
      })
      .catch((e) => {
        setMsgModal(
          "Hubo un problema con su envio de whatsapp, Verifique que su numero corresponda al siguiente formato Ej: +584242031762 e intenten nuevamente luego"
        );
        setTitleDialog("Envio de WhatsApp");
        handleClickOpenDialog();
      });
  };

  const handleClickSendEmail = (id, email, guideId) => {
    if (!guideId) {
      errorMessageMissingGuide();
      return;
    }
    const message = `
      Hola,
      Completa tu autoinspeccion siguiente el siguiente enlace:
      Link:  ${inspectionAppUrl}call?room=${id}
      Saludos,
      Sistran Team!
    `;
    const mail = {
      message: message,
      to: email,
      subject: "Sistran mail contacto!",
    };

    axios
      .post(`${eventsUrlApi}/notification/sendEmail`, mail)
      .then((res) => {
        if (res.data.success) {
          setMsgModal("Su correo ha sido enviado exitosamente!");
          setTitleDialog("Envio de Correo");
          updateTracking(
            id,
            "BO inspection: Enviado Email a " + email,
            0,
            objToken?.user?.email
          );
          handleClickOpenDialog();
        }
      })
      .catch((e) => {
        setMsgModal(
          "Hubo un problema con su envio de correo, intente nuevamente luego"
        );
        setTitleDialog("Envio de Correo");
        handleClickOpenDialog();
      });
  };

  const handleClickSendSms = (id, smsNumber, guideId) => {
    if (!guideId) {
      errorMessageMissingGuide();
      return;
    }
    const message = `Completa tu autoinspeccion siguiente el siguiente enlace: ${encodeURIComponent(
      inspectionAppUrl
    )}call?room=${id}`;

    const sms = {
      message: message,
      to: smsNumber,
    };

    axios
      .post(
        `${eventsUrlApi}/notification/sendSms`,
        JSON.parse(JSON.stringify(sms))
      )
      .then((res) => {
        if (res.data.success) {
          setMsgModal("Su SMS ha sido enviado exitosamente!");
          setTitleDialog("Envio de SMS");
          updateTracking(
            id,
            "BO inspection: Enviado SMS a " + smsNumber,
            0,
            objToken?.user?.email
          );
          handleClickOpenDialog();
        }
      })
      .catch((e) => {
        setMsgModal(
          "Hubo un problema con su envio de SMS e intenten nuevamente"
        );
        setTitleDialog("Envio de SMS");
        handleClickOpenDialog();
      });
  };

  useEffect(() => {
    setIsActive(showTab);
  }, [showTab]);

  useEffect(() => {
    
    let source = axios.CancelToken.source();
    const params = getParams(window.location.href);
    axios
      .get(`${inspectionApiUrl}/inspections/temporary/${room || params.room}`,{cancelToken: source.token})
      .then(({ data }) => {
        setDataClient(data);
      });
    
    return () => {
      source.cancel("Cancelling in cleanup");  
    };

  }, []);

  useEffect(() => {
    async function callGetModels(brandPicked) {
      if (brandPicked) {
        const newModels = await getModels(brandPicked);
        setModels(newModels);
      } else {
        setModels([]);  
      }
    }
    callGetModels(dataClient?.brandId);
    
    return () =>  null;

  }, [dataClient?.brandId]);


  return dataClient == null ? (
    "...Cargando"
  ) : (
    <>
      <div style={{ display: !isActive && "none" }}>
        <Grid container className={classes.paddingData}>
          <Grid container item xs={12} className={classes.sectData}>
          <Grid item xs={2} className={classes.typeData}>
              <RenderTextFieldDisabled
                label="id"
                value={dataClient.inspectionId}
              />
            </Grid>
          <Grid item xs={3} className={classes.typeData}>
              <RenderTextFieldDisabled
                label="nombre"
                value={dataClient.name}
              />
            </Grid>
            <Grid item xs={3} className={classes.typeData}>
              <RenderTextFieldDisabled
                label="apellido"
                value={dataClient.lastname}
              />
            </Grid>
            <Grid item xs={4} className={classes.typeData}>
              <RenderTextFieldDisabled label="email" value={dataClient.email} />
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.sectData}>
            <Grid item xs={3} className={classes.typeData}>
              <RenderTextFieldDisabled
                label="telefonos"
                value={dataClient.phone}
              />
            </Grid>
            <Grid item xs={3} className={classes.typeData}>
              <RenderTextFieldDisabled
                label="fecha y hora"
                value={moment(dataClient.date[0]).format('L')}
              />
            </Grid>
            <Grid item xs={4} className={classes.typeData}>
              <RenderTextFieldDisabled
                label="fecha de expiracion"
                value={moment(dataClient.expirationDate[0]).format('L')}
              />
            </Grid>
          <Divider style={{ width: '100%' }} />
          </Grid>
          <Grid container item xs={12} className={classes.sectData}>
          <Grid item xs={3} className={classes.typeData}>
              <TextField
                select
                classes={{
                  root: classes.root,
                }}
                name="modelId"
                defaultValue={dataClient.modelId}
                inputRef={register({
                  name: "model",
                })}
                disabled={true}
                label="Modelo"
                custom={{ tabIndex: 2 }}
              >
                {models
                  ? models.map((value) => {
                      return (
                        <MenuItem key={value._id} value={value._id}>
                          {value.name}
                        </MenuItem>
                      );
                    })
                  : null}
              </TextField>
            </Grid>
            <Grid item xs={3} className={classes.typeData}>
              <RenderTextFieldDisabled label="año" value={dataClient.year} />
            </Grid>
            <Grid item xs={3} className={classes.typeData}>
              <TextField
                select
                classes={{
                  root: classes.root,
                }}
                name="brandId"
                defaultValue={dataClient.brandId}
                inputRef={register({
                  name: "brand",
                })}
                disabled={true}
                label="Marca"
                custom={{ tabIndex: 1 }}
              >
                {brands.result
                  ? brands.result.map((value) => {
                      return (
                        <MenuItem key={value._id} value={value._id}>
                          {value.name}
                        </MenuItem>
                      );
                    })
                  : null}
              </TextField>
            </Grid>
            <Grid item xs={3} className={classes.typeData}>
              <RenderTextFieldDisabled label="version" value={dataClient.version ? dataClient.version: 'N/A'} />
            </Grid>
          </Grid>
          <Grid container xs={12} className={classes.sectData}>
          <Grid item xs={4} className={classes.typeData}>
              <RenderTextFieldDisabled label="nombre empresa" value={userData?.companyName ? userData.companyName : 'N/A'} />
            </Grid>
            <Grid item xs={4} className={classes.typeData}>
              <RenderTextFieldDisabled label="guia de fotos" value={dataClient.version ? dataClient.version: 'N/A'} />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={msgModal ? false : true}
        maxWidth={msgModal ? "xs" : "sm"}
        classes={{
          paper: classes.rootWholeDialog,
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          classes={{
            root: classes.rootTitle,
          }}
        >
          {titleDialog}
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.bodyDialog,
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {msgModal ? (
              <>{msgModal}</>
            ) : (
              <AddCommentComponent id={idComment} close={handleCloseDialog} />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {msgModal && (
            <Button onClick={handleCloseDialog} color="primary">
              Aceptar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DataChat;
