import React from 'react';
import { Grid } from '@material-ui/core';
import GuidesMain from './guidesMain';
import FabAdd from '../../components/FabAdd';

const GuideUI = () => {
    return (
        <Grid>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={12}>
                        <GuidesMain />
                    </Grid>
                </Grid>
            </Grid>
            <FabAdd urlRedirect={'guides/add'} />
        </Grid>
    )
}

export default GuideUI;