import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton
} from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1)
    },
    hide: {
        display: 'none'
    },
    fullWidth: {
        width: '100%'
    }
}));

const Filter = ({ filterFn, reset }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [valueFrom, setValueFrom] = useState(null);
    const [valueUntil, setValueUntil] = useState(null);

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    const handleChangeFrom = (date) => {
      setValueFrom(date);
    }

    const handleChangeUntil = (date) => {
      setValueUntil(date);
    }

    const handleReset = () => {
      setValueFrom(null);
      setValueUntil(null);
      reset(null);
      // use endpoint normal para regresar valores

    }

    return (
        <div style={{marginBottom:20}}>
            <IconButton onClick={handleExpand} className={classes.margin}>
                <FilterIcon />
            </IconButton>
            <Accordion expanded={expanded}>
                <AccordionSummary className={classes.hide} />
                <AccordionDetails>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container direction="row">
                      <Grid item xs={7} style={{ padding: 8 }}>
                        <KeyboardDatePicker
                          autoOk
                          variant="inline"
                          format="dd/MM/yyyy"
                          className="formInput"
                          margin="normal"
                          label={'Fecha Desde'}
                          value={valueFrom ? new Date(valueFrom) : null}
                          fullWidth
                          onChange={handleChangeFrom}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                      <Grid item xs={7} style={{ padding: 8 }}>
                        <KeyboardDatePicker
                          autoOk
                          variant="inline"
                          format="dd/MM/yyyy"
                          className="formInput"
                          label={'Fecha Hasta'}
                          value={valueUntil}
                          // error={touched && !!error}
                          // helperText={touched && !!error ? error : false}
                          // inputProps={{ tabIndex }}
                          fullWidth
                          // onBlur={() => onBlur(value ? new Date(value).toISOString() : null)}
                          onChange={handleChangeUntil}
                          // {...otherInputProps}
                          // {...props}
                        />
                      </Grid>
                      <Grid item xs={12} container direction="row" justify="center">
                        <Button type="submit" variant="contained" color="primary" style={{ marginLeft: 8 }} onClick={() => filterFn(valueFrom, valueUntil)}>
                            Buscar
                        </Button>
                        <Button type="button" variant="contained" style={{ marginLeft: 8 }} onClick={handleReset}>
                            Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </AccordionDetails>
            </Accordion>
            
        </div>
    );
};

export default Filter;
