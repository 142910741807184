import React, { useState, useEffect } from "react";
import { AddCircle, Edit, Add, Delete, CheckCircle } from "@material-ui/icons";
import {
  Grid,
  Modal,
  Fab,
  MenuItem,
  IconButton,
  ExpansionPanelDetails,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Field, FieldArray, reduxForm } from "redux-form";
import {
  renderTextField,
  renderSwitch,
  renderSelectField,
  renderRadioGroup,
  RenderCardRadioGroup,
} from "../form/renderInputsForm";
import GuidesAddPhotoTypeForm from "./guidesAddPhotoTypeForm";
import GuidesEditPhotoTypeForm from "./guidesEditPhotoTypeForm";
import { getStateGuide } from "../../actions/guidesStateActions";
import { getRiskGuide } from "../../actions/guidesRiskActions";
import ItemDelete from "../modal/itemDelete";
import GuidesEditSectionPhotoForm from "./guidesEditSectionPhotoForm";
import GuidesAddSectionPhotoForm from "./guidesAddSectionPhotoForm";
import SortablePanel from "../sortable/SortablePanel";
import { ControlledExpansionPanel } from "./guidePanel";
import vehicleGrid8 from "./../../assets/images/grid-car-8.png";
import vehicleGrid10 from "./../../assets/images/grid-car-10.png";

const ARR_PHOTOS_EXTERNAL_MODEL = [
  {
    name: "Frontal lateral izquierdo",
    description: "",
    position: 0,
    type: ["image-10", "image-8"],
  },
  {
    name: "Frontal",
    description: "",
    position: 1,
    type: ["image-10", "image-8"],
  },
  {
    name: "Frontal lateral derecho",
    description: "",
    position: 2,
    type: ["image-10", "image-8"],
  },
  {
    name: "Lateral izquierda superior",
    description: "",
    position: 3,
    type: ["image-10", "image-8"],
  },
  {
    name: "Lateral derecha superior",
    description: "",
    position: 4,
    type: ["image-10", "image-8"],
  },
  {
    name: "Lateral izquierda inferior",
    description: "",
    position: 5,
    type: ["image-10"],
  },
  {
    name: "Lateral derecha inferior",
    description: "",
    position: 6,
    type: ["image-10"],
  },
  {
    name: "Trasera lateral izquierda",
    description: "",
    position: 7,
    type: ["image-10", "image-8"],
  },
  {
    name: "Trasera",
    description: "",
    position: 8,
    type: ["image-10", "image-8"],
  },
  {
    name: "Trasera lateral derecha",
    description: "",
    position: 9,
    type: ["image-10", "image-8"],
  },
];

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid grey",
    "& .makeStyles-paper-60": {
      borderColor: "transparent",
      borderRadius: 20,
    },
    "& h2": {
      textAlign: "center",
    },
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    boxShadow: theme.shadows[6],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 20,
  },
  cardImage: {
    border: "4px dashed #f4f4f4",
    width: 220,
    margin: "30px 10px",
    flexWrap: "wrap",
  },
}));

const validate = (values) => {
  const errors = {};
  const requiredFields = ["name", "codeId", "risk", "state"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Requerido";
    }
  });

  return errors;
};

const GuidesEditForm = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form);
  const risks = useSelector((state) => state.risks.data);
  const states = useSelector((state) => state.states.data);
  const token = useSelector((state) => state.auth.token);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditSection, setOpenEditSection] = useState(false);
  const [openAddSection, setOpenAddSection] = useState(false);
  const [typePhoto, setTypePhoto] = useState(null);
  const [guideDelete, setGuideDelete] = useState({});
  const [guideDeleteModal, setGuideDeleteModal] = useState(false);


  const handleDeleteGuide = (guide) => {
    setGuideDelete(guide);
    setGuideDeleteModal(true);
  };

  const handleDeleteAccept = async () => {
    let removed;
    if (guideDelete.type === "section") {
      removed = form[props.form]?.values?.photos;
      removed.splice(guideDelete.position, 1);
    }
    if (guideDelete.type === "photo") {
      removed = form[props.form]?.values?.photos[guideDelete?.index]?.related;
      removed.splice(guideDelete.position, 1);
    }
    setGuideDeleteModal(false);
  };

  const handleDeleteCancel = () => {
    setGuideDeleteModal(false);
  };

  const handleOpen = (type) => {
    setTypePhoto(type);
    setOpenAdd(true);
  };

  const handleEditOpen = (type) => {
    setTypePhoto(type);
    setOpenEdit(true);
  };

  const handleEditSectionOpen = (type) => {
    setTypePhoto(type);
    setOpenEditSection(true);
  };

  const handleAddSectionOpen = () => {
    setOpenAddSection(true);
  };

  const handleClose = () => {
    setOpenEdit(false);
    setOpenAdd(false);
    setOpenEditSection(false);
    setOpenAddSection(false);
  };

  const handleRiskEval = (id) => {
    let risk = risks.find((r) => r._id === id);
    let photos = form[props.form]?.values?.photos;

    if (risk && risk.name && risk.name.toLowerCase() === "auto") {
      photos.push(
        { name: "Externa", related: [], type: "card", model: "8" },
        { name: "Interna", related: [], type: "card", model: "8" },
        { name: "Documentacion", related: [], type: "card", model: "8" }
      );
    } else {
      let noInitialAutos = photos.filter(
        (photo) => !["Documentacion", "Externa", "Interna"].includes(photo.name)
      );

      photos.splice(0, photos.length, ...noInitialAutos);
    }
  };

  useEffect(() => {
    dispatch(getStateGuide(token));
    dispatch(getRiskGuide(token));
  }, [dispatch, token]);
  const bull = <span className={classes.bullet}>•</span>;
  return (
    <form>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} style={{ padding: 8 }}>
          <Field
            name="isDefault"
            label="Por Defecto"
            custom={{ tabIndex: 1 }}
            component={renderSwitch}
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ padding: 8 }}>
          <Field
            name="name"
            label="Nombre"
            custom={{ tabIndex: 3 }}
            component={renderTextField}
          />
          <Field
            name="codeId"
            label="Código"
            custom={{ tabIndex: 2 }}
            component={renderTextField}
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ padding: 8 }}>
          <Field
            name="state"
            label="Estado"
            component={renderSelectField}
            custom={{ tabIndex: 4 }}
          >
            {states.length
              ? states.map((state, index) => {
                  return (
                    <MenuItem key={state._id} value={state._id}>
                      {state.name}
                    </MenuItem>
                  );
                })
              : null}
          </Field>
          <Field
            name="risk"
            label="Riesgo"
            component={renderSelectField}
            custom={{ tabIndex: 5 }}
            onChange={(event, value) => handleRiskEval(value)}
          >
            {risks.length
              ? risks.map((risk, index) => {
                  return (
                    <MenuItem key={risk._id} value={risk._id}>
                      {risk.name}
                    </MenuItem>
                  );
                })
              : null}
          </Field>
        </Grid>
        <Grid item xs={12} md={12}>
          {
            <>
              <h3>Sección de Fotos</h3>
              {form[props.form]?.values?.photos?.map((group, index) => (
                <ControlledExpansionPanel
                  key={`group_${index}`}
                  summary={
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      style={{ padding: "0 1rem" }}
                    >
                      <Grid item>{group.name}</Grid>
                      <Grid
                        container
                        style={{ width: "auto" }}
                        item
                        justify="space-between"
                        alignItems="center"
                      >
                        {/* {!["Externa"].includes(group.name) &&
                        risks.find(
                          (r) => r._id === form[props.form]?.values?.risk
                        )?.name === "Auto" ? ( */}
                          <>
                            <IconButton
                              aria-label="edit"
                              onClick={() =>
                                handleEditSectionOpen({
                                  toOpen: group.name,
                                  index,
                                })
                              }
                              disabled={
                                [
                                  "Documentacion",
                                  "Externa",
                                  "Interna",
                                ].includes(group.name) &&
                                risks.find(
                                  (r) =>
                                    r._id === form[props.form]?.values?.risk
                                )?.name === "Auto"
                              }
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              aria-label="add"
                              onClick={() =>
                                handleOpen({ toOpen: group.name, index })
                              }
                              disabled={group.type === "image"}
                            >
                              <AddCircle />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                handleDeleteGuide({
                                  ...group,
                                  position: index,
                                  type: "section",
                                })
                              }
                              disabled={
                                [
                                  "Documentacion",
                                  "Externa",
                                  "Interna",
                                ].includes(group.name) &&
                                risks.find(
                                  (r) =>
                                    r._id === form[props.form]?.values?.risk
                                )?.name === "Auto"
                              }
                            >
                              <Delete />
                            </IconButton>
                          </>
                        {/* ) : null} */}
                      </Grid>
                    </Grid>
                  }
                >
                  <ExpansionPanelDetails>
                    <Grid container justify="center" spacing={2}>
                      {group.name === "Externa" ? (
                        <Grid item xs={11} style={{ padding: 8 }}>
                          <Field
                            name={`photos[${index}].type`}
                            component={renderRadioGroup}
                            handleEditOpen
                            custom={{
                              tabIndex: 1,
                              options: [
                                { value: "card", label: "Tarjeta" },
                                { value: "image", label: "Imagen" },
                              ],
                            }}
                            onChange={(event) => {
                              if (event.currentTarget.value === "card") {
                                group.related.splice(
                                  0,
                                  ARR_PHOTOS_EXTERNAL_MODEL.length
                                );
                              } else {
                                group.related.splice(
                                  0,
                                  group.related.length,
                                  ...ARR_PHOTOS_EXTERNAL_MODEL.filter((photo) =>
                                    photo.type.includes("image-8")
                                  )
                                );
                              }
                            }}
                            value={"card"}
                          />
                        </Grid>
                      ) : null}
                      {group.type === "card" ? (
                        <SortablePanel
                          group={group}
                          index={index}
                          handleEdit={handleEditOpen}
                          handleDelete={handleDeleteGuide}
                        />
                      ) : (
                        <>
                          <Grid lg={10} container item>
                            <Field
                              className={classes.cardImage}
                              value={"8"}
                              name={`photos[${index}].model`}
                              component={RenderCardRadioGroup}
                              handleEditOpen
                              onChange={(event) => {
                                if (+event.currentTarget.value === 8) {
                                  group.related.splice(
                                    0,
                                    group.related.length,
                                    ...ARR_PHOTOS_EXTERNAL_MODEL.filter(
                                      (photo) => photo.type.includes("image-8")
                                    )
                                  );
                                } else {
                                  group.related.splice(
                                    0,
                                    group.related.length,
                                    ...ARR_PHOTOS_EXTERNAL_MODEL.filter(
                                      (photo) =>
                                        photo.type.includes("image-8") ||
                                        photo.type.includes("image-10")
                                    )
                                  );
                                }
                              }}
                              custom={{
                                icon: <CheckCircle />,
                                tabIndex: 1,
                                options: [
                                  {
                                    source: vehicleGrid8,
                                    value: "8",
                                    label: "8 Fotos",
                                  },
                                  {
                                    source: vehicleGrid10,
                                    value: "10",
                                    label: "10 Fotos",
                                  },
                                ],
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </ExpansionPanelDetails>
                </ControlledExpansionPanel>
              ))}
            </>
          }
          <Grid
            container
            justify="flex-end"
            style={{ marginTop: 32, padding: 8 }}
          >
            <Fab color="primary" onClick={handleAddSectionOpen}>
              <Add />
            </Fab>
          </Grid>
        </Grid>
        <Modal
          className={classes.modal}
          open={openAdd}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <FieldArray
              name="photos"
              component={GuidesAddPhotoTypeForm}
              type={typePhoto}
              handleClose={handleClose}
            />
          </div>
        </Modal>
        <Modal
          className={classes.modal}
          open={openEdit}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <FieldArray
              name="photos"
              component={GuidesEditPhotoTypeForm}
              type={typePhoto}
              handleClose={handleClose}
            />
          </div>
        </Modal>
        <Modal
          className={classes.modal}
          open={openEditSection}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <FieldArray
              name="photos"
              component={GuidesEditSectionPhotoForm}
              type={typePhoto}
              handleClose={handleClose}
            />
          </div>
        </Modal>
        <Modal
          className={classes.modal}
          open={openAddSection}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <FieldArray
              name="photos"
              component={GuidesAddSectionPhotoForm}
              handleClose={handleClose}
            />
          </div>
        </Modal>
        <ItemDelete
          tagModal="Guía"
          title={guideDelete && guideDelete.name}
          openModal={guideDeleteModal}
          handleAccept={handleDeleteAccept}
          handleCancel={handleDeleteCancel}
        />
      </Grid>
    </form>
  );
};

export default reduxForm({
  form: "GuidesEditForm",
  validate,
  onSubmit: () => {},
})(GuidesEditForm);
