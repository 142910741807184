export const setAIJson = apiJson => async (dispatch) => {
  dispatch({ type:'SET_AI_JSON', payload: apiJson });
};

export const setAIPhotosStructure = arrPhotos => async (dispatch) => {
  dispatch({ type:'SET_AI_PHOTOS_STRUCTURE', payload: arrPhotos });
};

export const updateAIPhotosStructure = updatePhotoData => async (dispatch) => {
  dispatch({ type:'UPDATE_AI_PHOTOS_STRUCTURE', payload: updatePhotoData });
};
