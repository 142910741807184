import React from 'react';
import jwt_decode from "jwt-decode";
import { navigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import { useSelector } from 'react-redux';
import { updateTracking } from '../../../sevices';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: 0,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    background: 'transparent'
  },
  root: {
    minWidth: 30,
    minHeight: 30,
    background: '#4C1DB7'
  },
  rootOther: {
    background: '#4c1db77a'
  },
  speedDialContainer: {
    position: 'relative'
  },
  sizeSmall: {
    width: 30, 
    height: 30
  },
  rootIcon: {
    color: 'black'
  },
  rootDialAction: {
    height: '26px',
    width: '26px',
    minHeight: '26px',
    boxShadow: 'unset',
    color: 'black',
    background: 'unset',
    '&hover' : {
      background: 'unset'
    }
  }
}));

export default function MenuFab({ vidchat=null, whts=null, sms=null, email=null, index, userId, handleOpenAlert }) {

  const token = useSelector((state) => state.auth.token);

  let objToken = null;
  if (token) {
    objToken = jwt_decode(token);
  }

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const stream = useSelector(state => state.stream);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSms = () => {
    updateTracking(userId, 'BO inspection: Enviado SMS para videollamada', 0, objToken?.user?.email);
    sms();
    handleClose();
  }

  const handleMail = () => {
    updateTracking(userId, 'BO inspection: Enviado email para videollamada', 0, objToken?.user?.email);
    email();
    handleClose();
  }

  const handleWhatsapp = () => {
    updateTracking(userId, 'BO inspection: Enviado email para videollamada', 0, objToken?.user?.email);
    whts();
    handleClose();
  }

  const handleVideochat = () => {
    if (stream.currentRoom !== null && stream.currentRoom !== userId) {
      handleClose();
      handleOpenAlert();
    } else {
      updateTracking(userId, 'BO inspection: Creada videollamada ', 0, objToken?.user?.email);
      navigate(`/videochat?room=${userId}`);
    }
  }

  return (
    <div className={classes.speedDialContainer}>
    <Tooltip title='Enviar videollamada'>
      <VideoCallIcon 
        classes={{
          root: classes.rootIcon,
        }}
        onClick={handleClick}
      />
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >  
        <MenuItem value={'sms'} onClick={handleSms}>SMS video</MenuItem>  
        <MenuItem value={'mail'} onClick={handleMail}>Mail video</MenuItem>  
        <MenuItem value={'whatsapp'} onClick={handleWhatsapp}>Whatsapp video</MenuItem>
        { index &&  <MenuItem value={'videochat'} onClick={handleVideochat}>Entrar a llamada...</MenuItem>}
      </Menu>
    </div>
  );
}