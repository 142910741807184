import React from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  IconButton,
  InputLabel,
  Switch,
  Card,
  CardHeader,
  CardContent,
  Box,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import NumberFormat from "react-number-format";

export const renderAutocompleteField = ({
  input,
  label,
  meta,
  handleChange,
  defaultValue,
  ...custom
}) => {
  if (custom.options.length > 0) {
  }
  return (
    <FormControl fullWidth>
      <Autocomplete
        error={input.value && input.value}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              style={{ margin: "5px 0px" }}
              margin="normal"
              fullWidth
              error={meta.touched && !!meta.error}
              defaultValue={defaultValue?.name}
            />
          )}}
        defaultValue={defaultValue}
        fullWidth
        onChange={(event, data) => input.onChange(data)}
        {...custom}
        value={custom.multiple ? [...input.value] : (defaultValue && input.value==='' ? defaultValue : input.value)}
      />
      <FormHelperText error>
        {meta.touched && !!meta.error ? meta.error : false}
      </FormHelperText>
    </FormControl>
  );
};

export const renderPhoneField = ({
  input,
  name,
  label,
  custom: { tabIndex, placeholder, variant, size },
  meta: { touched, error },
  changeInput,
}) => {
  return (
    <NumberFormat
      {...input}
      className="formInput"
      label={label}
      error={touched && !!error}
      helperText={touched && !!error ? error : false}
      placeholder={placeholder}
      inputProps={{ tabIndex }}
      fullWidth
      variant={variant ? variant : "standard"}
      size={size ? size : "small"}
      format="+## ###########"
      customInput={TextField}
      onValueChange={(values) => {
        changeInput(values);
      }}
    />
  );
};

export const renderTextField = ({
  input,
  label,
  custom: { tabIndex, placeholder, variant, size },
  meta: { touched, error },
  ...allRest
}) => {
  console.log(allRest);
  return (
    <TextField
      className="formInput"
      label={label}
      error={touched && !!error}
      helperText={touched && !!error ? error : false}
      placeholder={placeholder}
      inputProps={{ tabIndex }}
      fullWidth
      variant={variant ? variant : "standard"}
      size={size ? size : "small"}
      {...input}
      {...allRest}
    />
  );
};

export const RenderPasswordField = ({
  input,
  label,
  handleChange,
  custom: { tabIndex, placeholder, variant, size },
  meta: { touched, error },
}) => {
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleChangeInside = (prop) => (event) => {
    handleChange();
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <FormControl>
      <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>
      <Input
        {...input}
        id="standard-adornment-password"
        type={values.showPassword ? "text" : "password"}
        value={values.password}
        onChange={handleChangeInside("password")}
        error={touched && !!error}
        helperText={touched && !!error ? error : false}
        placeholder={placeholder}
        inputProps={{ tabIndex }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export const renderNumberField = ({
  input,
  label,
  meta: { touched, error },
}) => {
  return (
    <TextField
      type="number"
      className="formInput"
      label={label}
      error={touched && !!error}
      helperText={touched && !!error ? error : false}
      fullWidth
      {...input}
    />
  );
};

export const renderSelectField = ({
  input,
  label,
  children,
  custom: { tabIndex, variant, size },
  meta: { touched, error },
  ...rest
}) => {
  return (
    <TextField
      select
      className="formInput"
      label={label}
      children={children}
      error={touched && !!error}
      helperText={touched && !!error ? error : false}
      inputProps={{ tabIndex }}
      variant={variant ? variant : "standard"}
      size={size ? size : "small"}
      fullWidth
      {...input}
      {...rest}
    />
  );
};

export const renderMultiSelectField = ({
  input,
  label,
  children,
  custom: { tabIndex, variant, size },
  meta: { touched, error },
}) => {
  return (
    <TextField
      select
      className="formInput"
      label={label}
      children={children}
      error={touched && !!error}
      helperText={touched && !!error ? error : false}
      inputProps={{ tabIndex }}
      variant={variant ? variant : "standard"}
      size={size ? size : "small"}
      fullWidth
      SelectProps={{
        multiple: true,
      }}
      {...input}
      value={input.value === "" ? [] : input.value}
    />
  );
};

export const renderMultiSelectCheckboxField = ({
  input,
  label,
  getName = null,
  children,
  custom: { tabIndex, placeholder = "Seleccione para agregar", variant, size },
  meta: { touched, error },
}) => {
  return (
    <TextField
      select
      className="formInput"
      label={label}
      children={children}
      error={touched && !!error}
      helperText={touched && !!error ? error : false}
      inputProps={{ tabIndex }}
      variant={variant ? variant : "standard"}
      size={size ? size : "small"}
      fullWidth
      SelectProps={{
        multiple: true,
        renderValue: (selected) => {
          if (selected.length === 0) {
            return <em>{placeholder}</em>;
          }
          return getName ? getName(selected).join(", ") : selected.join(", ");
        },
      }}
      {...input}
      value={input.value === "" ? [] : input.value}
    />
  );
};

export const renderDateField = ({
  input,
  label,
  custom: { tabIndex },
  meta: { touched, error },
}) => {
  return (
    <TextField
      type="date"
      className="formInput"
      label={label}
      error={touched && !!error}
      helperText={touched && !!error ? error : false}
      inputProps={{ tabIndex }}
      fullWidth
      {...input}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export const renderDateTimeField = ({
  input,
  label,
  defaultValue = undefined,
  custom: { tabIndex },
  meta: { touched, error },
}) => {
  return (
    <TextField
      type="datetime-local"
      className="formInput"
      label={label}
      error={touched && !!error}
      helperText={touched && !!error ? error : false}
      inputProps={{ tabIndex, value: defaultValue }}
      fullWidth
      {...input}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export const renderCheckbox = ({ input, label, disabled = false }) => (
  <FormControlLabel
    control={
      <Checkbox
        color="primary"
        disabled={disabled}
        checked={input.value ? true : false}
        onChange={input.onChange}
      />
    }
    label={label}
  />
);

export const renderRadioGroup = ({
  input,
  custom: { options },
  meta: { touched, error },
  ...rest
}) => (
  <FormControl>
    <RadioGroup row value={input.value} onChange={input.onChange} {...rest}>
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio color="primary" />}
          label={option.label}
        />
      ))}
    </RadioGroup>
    {touched && !!error && (
      <FormHelperText error={true}>{error}</FormHelperText>
    )}
  </FormControl>
);

export const RenderCardRadioGroup = ({
  className,
  input,
  custom: { options, icon },
  meta: { touched, error },
  ...rest
}) => {
  return (
    <FormControl>
      <RadioGroup row value={input.value} onChange={input.onChange} {...rest}>
        {options.map((option) => (
          <Card className={className}>
            <CardHeader
              action={
                <FormControlLabel
                  disabled={rest.disabled}
                  key={option.value}
                  value={option.value}
                  control={
                    <Radio
                      className="radioIcon"
                      color="primary"
                      checkedIcon={icon}
                    />
                  }
                />
              }
              title={option.label}
            />
            <CardContent>
              <img height={"250px"} src={option.source} />
            </CardContent>
          </Card>
        ))}
      </RadioGroup>
      {touched && !!error && (
        <FormHelperText error={true}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export const RenderTextFieldDisabled = ({ input, label, value, ...rest }) => (
  <TextField
    label={label}
    className="formInput"
    disabled
    fullWidth
    value={value}
    {...input}
    {...rest}
  />
);

export const RenderCheckboxDisabled = ({ label, value }) => (
  <FormControlLabel
    control={<Checkbox color="primary" checked={value} />}
    label={label}
    disabled
  />
);

export const RenderRadioGroupDisabled = ({ input, options }) => (
  <FormControl>
    <RadioGroup row value={input.value}>
      {options.map((option) => (
        <FormControlLabel
          disabled
          key={option.value}
          value={option.value}
          control={<Radio color="primary" />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

export const renderSwitch = ({ input, label, disabled = false }) => (
  <FormControlLabel
    control={
      <Switch
        color="primary"
        disabled={disabled}
        checked={!!input.value}
        onChange={input.onChange}
      />
    }
    label={label}
  />
);
