import {
    getDataUserLoggedInAPI, updateDataUserInfoAPI, setPasswordUserAPI
} from '../sevices/userInfoService';

export const getDataUserLoggedIn = (token, userid) => async (dispatch) => {
    await dispatch(getDataUserLoggedInAPI(token, userid))
};

export const updateUserInfo = (token, data) => async (dispatch) => {
    await dispatch(updateDataUserInfoAPI(token, data, dispatch))
};

export const setPasswordUser = (token, data) => async (dispatch) => {
    await dispatch(setPasswordUserAPI(token, data, dispatch))
}