import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { inspectionApiUrl } from  '../../../utils/urls';
import { getComments } from  '../../../sevices';
import PropTypes from 'prop-types';
import MaterialTable from "material-table";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Table from '../Table';
import { 
  setSelectedPageTable, 
  setListInspections,
  setListComments
} from '../../../actions/appDataActions';

const columnsComments = [
  { title: 'Fecha', field: 'date' },
  { title: 'Comentario', field: 'comment' },
  { title: 'Email', field: 'email' },
];

const columnsTracking = [
  { title: 'Fecha', field: 'date' },
  { title: 'Descripcion', field: 'msg' },
  { title: 'Email', field: 'email'}
];

const optionsTable = {
  headerStyle: {
    fontWeight: "bold",
  },
  search: false,
  sorting: false,
  paging: false,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  colorBackgrnd: {
    backgroundColor: 'unset'
  },
  colorTab: {
    color: '#000'
  }
}));



function formatDatesData ( arrData ) {
  const formatedDateData = arrData.map (
    obj => {

      const _date = new Date(obj.date);
      const newDate = _date.getDate().toString() +'/'+ (_date.getMonth() + 1).toString() +'/'+ _date.getFullYear().toString()+' '+_date.getHours().toString()+':'+_date.getMinutes().toString();
      return {...obj, date: newDate};     
    }
  )
  return formatedDateData;
}

export default function TabComponent({ id, tracking }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const commentsRedu = useSelector((state) => state.app.commentsList); 
  const [comments, setComments] = useState(commentsRedu[id] || []);

  const dispatch = useDispatch();
  
  const [loadingComments, setLoadingComments] = useState(false);
  const [loadingTracking, setLoadingTracking] = useState(false);

  const [loading, setLoading] = useState(false);
  const [tableTrackingComponent, setTableTrackingComponent] = useState(null);
  const [tableCommentsComponent, setTableCommentsComponent] = useState(null);

  useEffect( ()=> { 
    function getTrackingTbl() {
        return <Table title='Tracking' data={tracking} columns={columnsTracking} />;
    }
    setTableTrackingComponent(getTrackingTbl());
  },[tracking]);

  useEffect( ()=> { 
    function getCommentsTbl() {
        return <Table title='Comentarios' data={comments} columns={columnsComments} />;
    }
    setTableCommentsComponent(getCommentsTbl());
  },[comments]);

  useEffect(() => {
    setComments(commentsRedu[id]);
  }, [commentsRedu])

  useEffect( ()=> {

    if ( !commentsRedu[id] ) {

      setLoadingComments(true);
      let promiseGetCmmts = Promise.resolve(getComments(id));
      promiseGetCmmts.then( ({ data }) => {
        const formatedDateData = formatDatesData( data );
        dispatch(setListComments(id, formatedDateData));
        setComments(formatedDateData);
        setLoadingComments(false);
      }); 

    }
  },[]);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar 
        position="static"
        classes={{
          colorPrimary: classes.colorBackgrnd
        }}
      >
        <Tabs 
          value={value} 
          onChange={handleChange} 
          aria-label="tabs example"
        >
          <Tab 
            label="Comments" {...a11yProps(0)} 
            classes={{
              textColorInherit: classes.colorTab
            }}
          />
          <Tab 
            label="tracking" {...a11yProps(1)} 
            classes={{
              textColorInherit: classes.colorTab
            }}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {
          loadingComments ? 
          <p>Loading...</p> :
          tableCommentsComponent
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
        {
          loadingTracking ?
          <p>Loading...</p> :
          tableTrackingComponent
        }
      </TabPanel>
    </div>
  );
}