import React, { useState } from 'react';
import { ExpandMore } from '@material-ui/icons';
import { withStyles, ExpansionPanel, ExpansionPanelSummary} from '@material-ui/core';

const StyledExpansionPanel = withStyles({
    root: {
        boxShadow: '0 0 0 0'
    }
})(ExpansionPanel);

export const StyledExpansionPanelSummary = withStyles({
    root: {
        flexDirection: 'row-reverse',
        padding: 0,
        '&:hover': {
            cursor: 'default !important'
        }
    },
    focused: {
        backgroundColor: '#fff !important'
    },
    content: {
        margin: 0
    }
})(ExpansionPanelSummary);

export const ControlledExpansionPanel = ({ children, summary }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <StyledExpansionPanel expanded={expanded}>
            <StyledExpansionPanelSummary expandIcon={<ExpandMore />} IconButtonProps={{ onClick: () => { handleExpand(); } }}>
                {summary}
            </StyledExpansionPanelSummary>
            {children}
        </StyledExpansionPanel>
    );
};