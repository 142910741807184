import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons, tableTranslate } from "./tableIcons";
import MaterialTable from "material-table";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "table",
    width: "100%",
    tableLayout: "fixed",
    "& .MuiTableCell-root": {
      padding: 13,
    },
    "& .MuiPaper-root": {
      boxShadow: "none",
      padding: 10,
      "& .MuiTableRow-footer .MuiTableCell-root": {
        borderBottom: "none",
      },
    },
    "& .MuiTableCell-root .MuiIconButton-root": {
      padding: 8
    }
  },
}));

const CustomMaterialTable = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MaterialTable
        icons={tableIcons}
        localization={tableTranslate}
        {...props}
      />
    </div>
  );
};

export default CustomMaterialTable;
