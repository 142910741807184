import React from "react";
import { Grid } from "@material-ui/core";

const Container = ({ children }) => {
	return (
		<Grid container className="appContainer">
			<Grid item xs={12}>
				{children}
			</Grid>
		</Grid>
	);
};

export default Container;