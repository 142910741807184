import React from "react";
import PhotoSquare from '../PhotoSquare';
import UploadPhotoSquare from "./uploadPhotoSquare";
import { v4 as uuidv4 } from 'uuid';

export const gridPhotosFromGuide = ( guideInfo, remoteVideo, idVideo ) => {

    if ( !guideInfo ) return <>loading...</>;
    if ( guideInfo.length === 0 ) return <div>No Photos Available. Check your photo's Guide. <br /><a href='/guides'>Check my photo's guide</a></div>

    return (
        guideInfo.photos.map(
            ({ name, related }) => 
                <div style={{display:'flex', flexWrap:'wrap', flexDirection:'column'}}>
                    <div>
                        <h4 style={{ fontSize: '18px', color: "#4C1DB7", fontWeight: 'bold'}}>{name}</h4>
                    </div>
                    {
                        related.length > 0 &&
                        <div>
                            { displayGridPhotosInRightOrder( name, related, remoteVideo, idVideo) }
                        </div>
                    }
                </div>
        )
    )
}

export const gridPhotosFromGuideUploadInput = ( guideInfo, exclude, inspectionId ) => {
    if ( !guideInfo ) return <>loading...</>;
    if ( guideInfo.length === 0 ) return <div>No Photos Available. Check your photo's Guide. <br /><a href='/guides'>Check my photo's guide</a></div>

    return (
        guideInfo.photos.map(
            ({ name, related }) => 
                <div style={{display:'flex', flexWrap:'wrap', flexDirection:'column'}}>
                    {
                        exclude.includes(name) && <></>
                    }
                    {
                        !exclude.includes(name) &&
                        <>
                            <div>
                                <h4 style={{ fontSize: '14px', fontWeight: 'medium', color: '#4B4B4B' }}>{name}</h4>
                            </div>
                            
                            {
                                related.length > 0 &&
                                <div>
                                    { displayGridPhotosUploadSquares( name, related, inspectionId ) }
                                </div>
                            }
                        </>
                    }
                </div>
        )
    ) 
}

export const displayGridPhotosUploadSquares = ( section, photoList, inspectionId ) => {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap'}}>
            {
                photoList.map( 
                    ({ name, position, photo }) => 
                        <UploadPhotoSquare 
                            section={section}
                            position={position}
                            id={name.trim()} 
                            inspectionId={inspectionId}
                            title={name} 
                            key={inspectionId+name.trim()}  
                            defaultImage={photo}
                        /> 
                )
            }
        </div>
    )
}

export const displayGridPhotosInRightOrder = ( section, photoList, remoteVideo, idVideo ) => {
    return (
        <div>
            {
                photoList.map( 
                    ({ name, position, photo }) => 
                        <PhotoSquare 
                            section={section}
                            position={position}
                            id={name.trim()} 
                            title={name} 
                            key={uuidv4()} 
                            remoteVideo={remoteVideo} 
                            idVideo={idVideo} 
                            defaultImage={photo}
                        /> 
                )
            }
        </div>
    )
}
