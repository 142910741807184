import React from 'react';
import { Link, navigate } from '@reach/router';
import { Grid, Breadcrumbs, Paper } from '@material-ui/core';
import InspectionAddForm from '../../components/inspection/inspectionAddForm'
import InspectionTitle from '../../components/inspection/inspectionTitle';
import InspectionDynamicForm from '../../components/inspection/inspectionDynamicForm';

const InspectionAdd = ({ type }) => {

    const handleSuccessInspection = () => {
        navigate('/inspections');
    };

    const handleData = (values) => {
        console.log('I AM DI FATHER', values);
    };

    return (
      <Grid container>
        <Grid item xs={12}>
          <div style={{ marginBottom: 16, padding: 16 }}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link to="/inspections">Home</Link>
              <Link to="/inspections">Inspecciones</Link>
              <Link to="/inspections/add">Crear</Link>
            </Breadcrumbs>
          </div>
          <Paper style={{ padding: 16 }}>
            <InspectionTitle
              typeForm="inspectionAdd"
              handleSuccessInspection={handleSuccessInspection}
            />
            {type === "dynamic" ? (
              <InspectionDynamicForm handleData={handleData} />
            ) : (
              <InspectionAddForm />
            )}
          </Paper>
        </Grid>
      </Grid>
    );
}
 
export default InspectionAdd;