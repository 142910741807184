import { createAsyncAction } from 'redux-promise-middleware-actions';
import { GET_STATES_GUIDE } from '../actions/types';
import { exeptionCodeResponse, inspectionApiUrl, validateResponse } from '../utils/urls';

export const getStateGuidesAPI = createAsyncAction(GET_STATES_GUIDE, async (token = null) => {
  const headers = { Authorization: `Bearer ${token}` };
  const res = await fetch(`${inspectionApiUrl}/state`, { headers })
    .then((response) => validateResponse(response))
    .catch((error) => {
      throw exeptionCodeResponse(error);
    });
    return res;
});
