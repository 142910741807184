import React, { useState, useEffect} from 'react';
import jwt_decode from "jwt-decode";
import { ValidatorForm } from 'react-form-validator-core';
import { useSelector, useDispatch } from 'react-redux';
import {
    Grid,
    Avatar,
    Typography,
    TextField,
    Button,
    Fab,
    CircularProgress
} from '@material-ui/core'
import { useStyles } from './styles';
import IconPencil from './../../../assets/images/iconPencilWhite.svg';
import { updateUserInfo } from './../../../actions/userInfoActions'

const EditProfileBasic = ({ dataProfile, disableInput, handleDisableEditProfile }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false)
    const [dataForm, setDataForm] = useState({
        _id: '',
        userId: '',
        fullName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        pictureUrl: ''
    })
    
    const [imageErrorText, setImageErrorText] = useState(null);
    
    const token = useSelector((state) => state.auth.token);
    
    const handleSubmit = async () => {
        setLoading(true)
        await dispatch(updateUserInfo(token, dataForm))
        handleDisableEditProfile()
        setLoading(false)
    }


    let objToken = null;
    if (token) {
        objToken = jwt_decode(token);
    }
    
    const handleChange = (e) => {
        setDataForm({
            ...dataForm,
            [e.target.name]: e.target.value
        })
    }

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const imageDelete = () => {
        setDataForm({
            ...dataForm,
            pictureUrl: null
        })
    };

    const handlePreviewImage = async (e) => {
        const imgSize = Math.round((e.target.files[0].size / 1024))

        if(imgSize >= 80){
            setImageErrorText('La imagen de perfil no debe ser mayor a 80kb')
            setTimeout(() => {
                setImageErrorText(null)
            }, 3000)
            return e.preventDefault()
        }
        try {
            const imageToForm = await toBase64(e.target.files[0]);
            setDataForm({
                ...dataForm,
                pictureUrl: imageToForm,
            })

            setImageErrorText(null);
        } catch (error) {
            imageDelete();
            setImageErrorText('Ocurrio un error, intente de nuevo');
        }
    }
    useEffect(() => {
       
        if (dataProfile) {
            setDataForm({
                ...dataForm,
                _id: dataProfile._id,
                userId: objToken?.user?._id,
                fullName: dataProfile.fullName,
                firstName: dataProfile.firstName,
                lastName: dataProfile.lastName,
                email: objToken.user.email,
                address: dataProfile.address ? dataProfile.address : '',
                phone: dataProfile.phone ? dataProfile.phone : '',
                pictureUrl: dataProfile.pictureUrl ? dataProfile.pictureUrl : '',
                cc: dataProfile.cc ? dataProfile.cc : ''
            })
        }
    }, [dataProfile])
 
    return (
        
            <ValidatorForm
                instantValidate={true}
                onSubmit={() => handleSubmit()}
            >
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.sectionAvatar}
            >
                <Grid style={{ position: 'relative' }}>
                    {dataForm?.pictureUrl ? (
                        <Avatar src={dataForm?.pictureUrl} className={classes.photoProfile} />
                    ) : (
                            <Avatar className={classes.photoProfile}>
                                <Typography component="h4" style={{ fontStyle: 'uppercase' }}>
                                    {`${dataForm?.firstName?.charAt(0)}${dataForm?.lastName?.charAt(0)}`}
                                </Typography>
                            </Avatar>
                        )}
                    <label htmlFor="upload-photo" style={{ position: 'absolute', bottom: '0px', right: '0px' }}>
                        <input
                            style={{ display: 'none' }}
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                            disabled={disableInput}
                            onChange={(e) => handlePreviewImage(e)}
                        />
                        {!disableInput && (
                            <Fab component="span" className="iconUpload">
                                <img src={IconPencil} />
                            </Fab>
                        )}
                    </label>
                </Grid>
                <Typography className={classes.imgMessageError}>
                    {imageErrorText}
                </Typography>
                <Typography variant="h6" className="nameProfile">{`${dataForm.firstName} ${dataForm.lastName}`}</Typography>
                <Typography variant="p"  className={classes.textSmall}>Compañía: {(objToken && objToken?.user?.companyName) || 'No Asignada'}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.spaceInput}>
                <TextField
                    label="NOMBRE"
                    onChange={(e) => handleChange(e)}
                    name="firstName"
                    value={dataForm.firstName || ''}
                    validators={["required", "minStringLength:1", "maxStringLength:40"]}
                    errorMessages={["El campo es requerido", "Debe ingresar mas carácteres", "Número máximo de caracteres"]}
                    disabled={disableInput}
                    style={{width:'100%'}}
                />
            </Grid>
            <Grid item xs={12} className={classes.spaceInput}>
                <TextField
                    label="APELLIDO"
                    onChange={(e) => handleChange(e)}
                    name="lastName"
                    value={dataForm.lastName || ''}
                    validators={["required", "minStringLength:1", "maxStringLength:40"]}
                    errorMessages={["El campo es requerido", "Debe ingresar mas carácteres", "Número máximo de caracteres"]}
                    disabled={disableInput}
                    style={{width:'100%'}}
                />
            </Grid>
            <Grid item xs={12} className={classes.spaceInput}>
                <TextField
                    label="CORREO ELECTRONICO"
                    onChange={(e) => handleChange(e)}
                    name="email"
                    value={dataForm.email || ''}
                    validators={["required", "isEmail"]}
                    errorMessages={["El campo es requerido", "Email no es válido"]}
                    disabled={true}
                    style={{width:'100%'}}
                />
            </Grid>
            <Grid item xs={12} className={classes.spaceInput}>
                <TextField
                    label="DIRECCION"
                    onChange={(e) => handleChange(e)}
                    name="address"
                    value={dataForm.address || ''}
                    validators={["required", "minStringLength:1", "maxStringLength:40"]}
                    errorMessages={["El campo es requerido", "Debe ingresar mas carácteres", "Número máximo de caracteres"]}
                    disabled={disableInput}
                    style={{width:'100%'}}
                />
            </Grid>
            <Grid item xs={12} className={classes.spaceInput}>
                <TextField
                    label="TELÉFONO"
                    name="phone"
                    value={dataForm.phone || ''}
                    validators={['required', 'minStringLength:12']}
                    errorMessages={["El campo es requerido", "La cantidad minima de campos es 12"]}
                    disabled={disableInput}
                    onChange={(e) => handleChange(e)}
                    style={{width:'100%'}}
                />
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={!disableInput ? classes.sectBtnSave : null}
            >
                <div className={classes.wrapper}>
                    <Button disabled={disableInput || loading} variant="contained" color="primary" type="submit" className={classes.btn}>
                        Guardar Básico
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </Grid>
            </ValidatorForm>
    );
}

export default EditProfileBasic;