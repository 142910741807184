import React, {Fragment} from 'react';
import { Grid, Button } from '@material-ui/core';
import { navigate } from '@reach/router';
import CardPhoto from './CardPhoto/CardPhoto';

const Photos = ({ data }) => {

  console.log('data', data)
  const handleReturn = () => {
    navigate('/inspections')
  }

  return (
    <Fragment>
      {
        (data.date && false) ? 
          <div
            style={{
              backgroundColor: '#E5E5FF'
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <CardPhoto id={data._id} title="Frontal" photo={data.photo.frontal.images.length > 0 ? data.photo.frontal.images[data.photo.frontal.images.length-1] : data.photo.frontal.images[0]} data={{metadata: data.photo.frontal.metadata, comments: data.photo.frontal.comments, status: data.photo.frontal.status}} />
              </Grid>
              <Grid item xs={4}>
                <CardPhoto 
                  id={data._id} 
                  title="Esquina superior izquierda" 
                  photo={data.photo.side_frontal_left.images.length > 0 ? data.photo.side_frontal_left.images[data.photo.side_frontal_left.images.length-1] : data.photo.side_frontal_left.images[0]} data={{metadata: data.photo.side_frontal_left.metadata, comments: data.photo.side_frontal_left.comments, status: data.photo.side_frontal_left.status}} />
              </Grid>
              <Grid item xs={4}>
                <CardPhoto 
                  id={data._id} 
                  title="Esquina superior derecha" 
                  photo={data.photo.side_frontal_right.images.length > 0 ? data.photo.side_frontal_right.images[data.photo.side_frontal_right.images.length-1] : data.photo.side_frontal_right.images[0]} data={{metadata: data.photo.side_frontal_right.metadata, comments: data.photo.side_frontal_right.comments, status: data.photo.side_frontal_right.status}} />
              </Grid>
              <Grid item xs={4}>
                <CardPhoto 
                  id={data._id} 
                  title="Trasera" 
                  photo={data.photo.back.images.length > 0 ? data.photo.back.images[data.photo.back.images.length-1] : data.photo.back.images[0]} data={{metadata: data.photo.back.metadata, comments: data.photo.back.comments, status: data.photo.back.status}} />
              </Grid>
              <Grid item xs={4}>
                <CardPhoto 
                  id={data._id} 
                  title="Esquina trasera izquierda" 
                  photo={data.photo.side_back_left.images.length > 0 ? data.photo.side_back_left.images[data.photo.side_back_left.images.length-1] : data.photo.side_back_left.images[0]} data={{metadata: data.photo.side_back_left.metadata, comments: data.photo.side_back_left.comments, status: data.photo.side_back_left.status }}/>
              </Grid>
              <Grid item xs={4}>
                <CardPhoto 
                  id={data._id} 
                  title="Esquina trasera derecha" 
                  photo={data.photo.side_back_right.images.length > 0 ? data.photo.side_back_right.images[data.photo.side_back_right.images.length-1] : data.photo.side_back_right.images[0]} data={{metadata: data.photo.side_back_right.metadata, comments: data.photo.side_back_right.comments, status: data.photo.side_back_right.status }}/>
              </Grid>
              <Grid item xs={4}>
                <CardPhoto 
                  id={data._id} 
                  title="Lateral Derecha superior" 
                  photo={data.photo.side_right_top.images.length > 0 ? data.photo.side_right_top.images[data.photo.side_right_top.images.length-1] : data.photo.side_right_top.images[0]} data={{metadata: data.photo.side_right_top.metadata, comments: data.photo.side_right_top.comments, status: data.photo.side_right_top.status }}/>
              </Grid>
              <Grid item xs={4}>
                <CardPhoto 
                  id={data._id} 
                  title="Lateral inferior derecha" 
                  photo={data.photo.side_right_lower.images.length > 0 ? data.photo.side_right_lower.images[data.photo.side_right_lower.images.length-1] : data.photo.side_right_lower.images[0]} data={{metadata: data.photo.side_right_lower.metadata, comments: data.photo.side_right_lower.comments, status: data.photo.side_right_lower.status }}/>
              </Grid>
              <Grid item xs={4}>
                <CardPhoto 
                  id={data._id} 
                  title="Lateral superior izquierdo" 
                  photo={data.photo.side_left_top.images.length > 0 ? data.photo.side_left_top.images[data.photo.side_left_top.images.length-1] : data.photo.side_left_top.images[0]} data={{metadata: data.photo.side_left_top.metadata, comments: data.photo.side_left_top.comments, status: data.photo.side_left_top.status}} />
              </Grid>
              <Grid item xs={4}>
                <CardPhoto 
                  id={data._id} 
                  title="Lateral inferior izquierda" 
                  photo={data.photo.side_left_lower.images.length > 0 ? data.photo.side_left_lower.images[data.photo.side_left_lower.images.length-1] : data.photo.side_left_lower.images[0]} data={{metadata: data.photo.side_left_lower.metadata, comments: data.photo.side_left_lower.comments, status: data.photo.side_left_lower.status}} />
              </Grid>
              <Grid item xs={4}>
                <CardPhoto 
                  id={data._id} 
                  title="Tablero" 
                  photo={data.photo.dashboard.images.length > 0 ? data.photo.dashboard.images[data.photo.dashboard.images.length-1] : data.photo.dashboard.images[0]} data={{metadata: data.photo.dashboard.metadata, comments: data.photo.dashboard.comments, status: data.photo.dashboard.status}} />
              </Grid>
              <Grid item xs={4}>
                <CardPhoto 
                  id={data._id} 
                  title="Lateral del carnet" 
                  photo={data.photo.front_card.images.length > 0 ? data.photo.front_card.images[data.photo.front_card.images.length-1] : data.photo.front_card.images[0]} data={{metadata: data.photo.front_card.metadata, comments: data.photo.front_card.comments, status: data.photo.front_card.status}} />
              </Grid>
              <Grid item xs={4}>
                <CardPhoto 
                  id={data._id} 
                  title="Trasera del carnet" 
                  photo={data.photo.back_card.images.length > 0 ? data.photo.back_card.images[data.photo.back_card.images.length-1] : data.photo.back_card.images[0]} data={{metadata: data.photo.back_card.metadata, comments: data.photo.back_card.comments, status: data.photo.back_card.status}} />
              </Grid>
              <Grid container justify="flex-end" style={{ marginTop: 32, padding: 8 }}>
                  <Button variant="outlined" color="primary" onClick={() => handleReturn()}>
                    Regresar
                  </Button>
              </Grid>
            </Grid>
          </div>
          :
          <></>
      }

      {
        data.photos ?
        <>
          {
            data.photos.map(
              ( dataArr ) => 
              <>
                <h3>{dataArr[0]}</h3>
                <div style={{display:'flex', flexWrap:'wrap'}}>
                {
                  dataArr[1].map(
                    photoInfo => 
                      <div>
                        <CardPhoto 
                          id={photoInfo.position} 
                          title={photoInfo.name.trim()} 
                          photo={photoInfo.photo} 
                          data={
                            {
                              metadata: '', 
                              comments: photoInfo.comments, 
                              status: photoInfo.status
                            }
                          }
                        />
                      </div>
                      
                  )
                }
                </div>
              </>
            )
          }
        </> 
        :
        <></>
        
      }
    </Fragment>
  )
}

export default Photos;