import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, navigate } from "@reach/router";
import axios from "axios";
import Tabs from "../../components/inspection/Tab";
import AddCommentComponent from "../../components/inspection/AddCommentComponent";
import MenuStatesComponent from "../../components/inspection/MenuStatesComponent";
import FilterButtonsComponent from "../../components/inspection/filterButtonsComponent";
import MenuFab from "../../components/inspection/MenuFab";
import MenuVideoChat from "../../components/inspection/MenuVideochat";
import {
  Fab,
  Breadcrumbs,
  Grid,
  Typography,
  DialogContentText,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  TablePagination,
} from "@material-ui/core";
import { MTableToolbar } from "material-table";
import Utils from "../../utils/utils";
import { OrderByFilter } from "../../utils";
import {
  inspectionApiUrl,
  eventsUrlApi,
  inspectionAppUrl,
} from "../../utils/urls";
import {
  Android,
  AddComment,
  Pageview,
  Add,
  DriveEta,
  EditAttributes,
  Ballot,
  PictureAsPdf,
  ViewModule
} from "@material-ui/icons";
import {
  setSelectedPageTable,
  setListInspections,
} from "../../actions/appDataActions";
import Loading from "../../components/loading/loadingCircular";
import { updateTracking } from "../../sevices";
import getPDFInspections from "../../utils/getPDF"
import { getModels, getVersionsByModel } from "../../sevices/inspectionService";
import { useGetBrandsSWR,useGetGuidesSWR } from "../../hooks/inspections";

import { 
  sendMessageGeneric,
  sendMessageWhatsApp 
} from "../../hooks/sendMessages";

import { useStyles } from "./styles";
import { setNewInspectionsWithState } from "../../actions/appDataActions";
import CustomMaterialTable from "../../components/table/CustomMaterialTable";
import { LIST_STATES_INSPECTION } from "../../components/inspection/filterButtonsComponent/dinamicFilterButtons";
import { decodedToken } from '../../utils';

function createData(
  inspectionId,
  date,
  data,
  email,
  phone,
  status,
  id,
  tracking,
  expirationDate,
  _id,
  personaInfo,
  guideId
) {
  return {
    inspectionId,
    date,
    data,
    email,
    phone,
    status,
    id,
    tracking,
    expirationDate,
    _id,
    personaInfo,
    guideId,
  };
}

const Inspections = () => {
  const filterList = useSelector((state) => state.filter.filterList);
  const globalFilter = useSelector((state) => state.filter.globalFilter);
  const listNotif = useSelector((state) => state.app.inspectionsList);
  const { labMode } = useSelector((state) => state.app);

  const inspectionWithStates = useSelector((state) => state.app.inspectionWithStates);
  const brandsValueSWR = useGetBrandsSWR('desde 2');
  
  const objToken = decodedToken(useSelector((state) => state.auth.token));
  const {result: resultsGuide, loading: loadingGuides} = useGetGuidesSWR(objToken.user.companyId);

  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [keyRefetch, setKeyRefetch] = useState(null);

  const [openAlert, setOpenAlert] = useState(false);
  const [, setStatusMessage] = useState("loading...");
  const state = useSelector((state) => state);
  const [initialPageTable] = useState(state.app.inspectionSelectedPage);
  const [inspectionsFiltered, setInspectionsFiltered] = useState(null);
  const [isLoaded, setIsLoaded] = useState(listNotif ? true : false);
  const [titleDialog, setTitleDialog] = useState("");
  const [msgModal, setMsgModal] = useState(
    "Su correo ha sido enviado exitosamente!"
  );
  const [idComment, setIdComment] = useState(null);
  const classes = useStyles();

  const [inspectionsCount, setInspectionsCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const handlePageChange = (event, value) => {
      setPage(value);
  };

  const handlePageSizeChange = (event) => {
      setPageSize(event.target.value);
      setPage(0);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setKeyRefetch(Math.random());
  },[]);

  const handleOpenAlert = () => setOpenAlert(true);
  const handleCloseAlert = () => setOpenAlert(false);

  const columnsInspections = [
    { title: "Id", field: "inspectionId", width: "10%" },
    {
      title: "Nombre / Apellido",
      field: "personaInfo",
      render: (rowData) => {
        return (
          <>
            {rowData?.personaInfo || ""}
            <Typography className={classes.muted} variant={"body1"}>
              {rowData.email}
            </Typography>
          </>
        );
      },
    },
    {
      title: "Fecha de Alta",
      field: "date",
      render: (row) => Utils.dateToFormatUTC(row?.date, "DD/MM/YYYY"),
      customSort: (a, b) => Utils.sortByDateUnix(a?.date, b?.date),
    },
    {
      title: "Fecha de Expiracion",
      field: "expirationDate",
      render: (row) => Utils.dateToFormatUTC(row?.expirationDate, "DD/MM/YYYY"),
    },
    {
      title: "Datos",
      field: "data",
      align: "center",
      width: "auto",
      render: (rowData) => {
        const [brand, model, version = "No registrado"] =
          rowData?.data?.split(",");
        return (
          <Tooltip
            title={
              <Grid container direction="column">
                {brand ? (
                  <Grid container justifyContent="start" alignItems="center">
                    <DriveEta /> {`marca: ${brand}`}
                  </Grid>
                ) : null}
                {model ? (
                  <Grid container justifyContent="start" alignItems="center">
                    <EditAttributes />
                    {`modelo: ${model}`}
                  </Grid>
                ) : null}
                {version ? (
                  <Grid container justifyContent="start" alignItems="center">
                    <EditAttributes />
                    {`version: ${version || "No registrado"}`}
                  </Grid>
                ) : null}
              </Grid>
            }
            classes={{
              arrow: classes.arrow,
              tooltip: classes.tooltip,
            }}
            arrow
            disableTouchListener
          >
            <Ballot />
          </Tooltip>
        );
      },
    },
    { title: "Telefono", field: "phone", width: "auto" },
    {
      title: "Estado",
      field: "status",
      render: (rowData) => {
        return (
          <div>
            <MenuStatesComponent
              id={rowData._id}
              email={rowData.email}
              currentStatus={rowData.status}
              index={rowData.tableData.id}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    (async () => {
      if (!!objToken?.user?.companyId) {
        // base url
        let url = `${inspectionApiUrl}/inspections/${objToken?.user?.companyId}/?page=${page}&size=${pageSize}`

        // create list with statuses
        const statusNumerList = filterList.map((filter) => LIST_STATES_INSPECTION.indexOf(filter));

        if (statusNumerList.length > 0) {
          url += `&statuses=${statusNumerList}`;
        }

        axios
          .get(url)
          .then(async ({ data }) => {
            const { docs, totalDocs } = data;
            const rows = await Promise.all(
              data.docs?.map(async (inspection) => {
                const brandValue = brandsValueSWR?.result?.find((brand) => {
                  return brand._id === inspection.brandId;
                });
                let modelValue = "No registrado";
                
                // get models
                if (brandValue) {
                  const modelsArr = await getModels(inspection.brandId);
                  
                  if (!modelsArr) {
                    modelValue = "No registrado";
                  }
                  modelValue = modelsArr.find(
                    (model) => model._id === inspection.modelId
                  );
                }

                // get versions
                let versionsValue = "No registrado";
                
                if (modelValue && modelValue !== "No registrado") {
                  const versions = await getVersionsByModel(modelValue._id);
                  versionsValue = versions.find(
                    (version) => version._id === inspection.versionId
                  );
                }

                const _data = `${brandValue?.name || "No Registrado"}, ${
                  modelValue?.name || "No Registrado"
                }, ${versionsValue?.name}`;

                return createData(
                  inspection.inspectionId,
                  inspection.date[inspection.date.length - 1],
                  _data,
                  inspection.email,
                  inspection.phone,
                  inspection.status,
                  inspection.id,
                  inspection.tracking,
                  inspection.expirationDate[
                    inspection.expirationDate.length - 1
                  ],
                  inspection._id,
                  inspection.name + " " + inspection.lastname,
                  inspection.guideId
                );
              })
            );
            dispatch(setListInspections(rows));
            setInspectionsFiltered(rows);
            dispatch(setNewInspectionsWithState(rows));
            setIsLoaded(true);
            setInspectionsCount(totalDocs);
          });
      } else {
        setStatusMessage("No companyId asignado");
      }
    })();
  }, [brandsValueSWR.result, page, pageSize, filterList]);

  const handleInitPage = (page) => {
    dispatch(setSelectedPageTable(page));
  };

  const errorMessageMissingGuide = () => {
    setTitleDialog("Error envio de inspeccion");
    setMsgModal(
      "Esta inspeccion no tiene asociada una guia. Por favor, elija una antes de enviar la inspeccion"
    );
    handleClickOpenDialog();
  };

  const handleClickSendEmail = (id, email, guideId) => {
    if (!guideId) {
      errorMessageMissingGuide();
      return;
    }
    sendMessageGeneric(id, email, 'sendEmail', false, undefined, () => {

      setMsgModal("Su correo ha sido enviado exitosamente!");
      setTitleDialog("Envio de Correo");
      updateTracking(
        id,
        "BO inspection: Enviado Email a " + email,
        0,
        objToken?.user?.email
      );
      handleClickOpenDialog();

    }, () => {
      
      setMsgModal(
        "Hubo un problema con su envio de correo, intente nuevamente luego"
      );
      setTitleDialog("Envio de Correo");
      handleClickOpenDialog();

    });

  };

  const handleClickSendEmailVideo = (id, email, guideId) => {
    if (!guideId) {
      errorMessageMissingGuide();
      return;
    }

    sendMessageGeneric(id, email, 'sendEmail', true, undefined, () => {

      setMsgModal("Su correo ha sido enviado exitosamente!");
      setTitleDialog("Envio de Correo");
      updateTracking(
        id,
        "BO inspection: Enviado Email a " + email,
        0,
        objToken?.user?.email
      );
      handleClickOpenDialog();

    }, () => {
      
      setMsgModal(
        "Hubo un problema con su envio de correo, intente nuevamente luego"
      );
      setTitleDialog("Envio de Correo");
      handleClickOpenDialog();

    });

  };

  const handleClickSendSms = (id, smsNumber, guideId) => {
    if (!guideId) {
      errorMessageMissingGuide();
      return;
    }

    sendMessageGeneric(id, smsNumber, 'sendSms', false, undefined, () => {

      setMsgModal("Su SMS ha sido enviado exitosamente!");
      setTitleDialog("Envio de SMS");
      updateTracking(
        id,
        "BO inspection: Enviado SMS a " + smsNumber,
        0,
        objToken?.user?.email
      );
      handleClickOpenDialog();

    }, () => {
      
      setMsgModal(
        "Hubo un problema con su envio de SMS e intenten nuevamente"
      );
      setTitleDialog("Envio de SMS");
      handleClickOpenDialog();

    });
  };

  const handleClickSendSmsVideo = (id, smsNumber, guideId) => {
    if (!guideId) {
      errorMessageMissingGuide();
      return;
    }

    sendMessageGeneric(id, smsNumber, 'sendSms', true, undefined, () => {

      setMsgModal("Su SMS ha sido enviado exitosamente!");
      setTitleDialog("Envio de SMS");
      updateTracking(
        id,
        "BO inspection: Enviado SMS a " + smsNumber,
        0,
        objToken?.user?.email
      );
      handleClickOpenDialog();

    }, () => {
      
      setMsgModal(
        "Hubo un problema con su envio de SMS e intenten nuevamente"
      );
      setTitleDialog("Envio de SMS");
      handleClickOpenDialog();

    });
  };

  const handleClickSendWhts = (id, whatsappNumber, guideId) => {
    if (!guideId) {
      errorMessageMissingGuide();
      return;
    }

    sendMessageWhatsApp(id, whatsappNumber, 
      () => {
        setMsgModal("Su mensaje por whatsapp ha sido enviado exitosamente!");
        setTitleDialog("Envio de WhatsApp");
        updateTracking(
          id,
          "BO inspection: Enviado Mensaje de WhatsApp a " + whatsappNumber,
          0,
          objToken?.user?.email
        );
        handleClickOpenDialog();
      },
      () => {
        setMsgModal(
          "Hubo un problema con su envio de whatsapp, Verifique que su numero corresponda al siguiente formato Ej: +584242031762 e intenten nuevamente luego"
        );
        setTitleDialog("Envio de WhatsApp");
        handleClickOpenDialog();
      }  
    );
  };

  const handleClickSendWhtsVideo = (id, whatsappNumber, guideId) => {
    if (!guideId) {
      errorMessageMissingGuide();
      return;
    }

    sendMessageGeneric(id, whatsappNumber, 'sendWhatsapp', true, undefined, () => {

      setMsgModal("Su correo ha sido enviado exitosamente!");
      setTitleDialog("Envio de Correo");
      updateTracking(
        id,
        "BO inspection: Enviado Mensaje de WhatsApp a " + whatsappNumber,
        0,
        objToken?.user?.email
      );
      handleClickOpenDialog();

    }, () => {
      
      setMsgModal(
        "Hubo un problema con su envio de correo, intente nuevamente luego"
      );
      setTitleDialog("Envio de WhatsApp");
      handleClickOpenDialog();

    });
  };

  const handleClickAddComment = (id) => {
    setIdComment(id);
    setMsgModal(null);
    setTitleDialog("Agregar Comentario");
    handleClickOpenDialog();
  };

  function formatedDateTracking(trackingArr) {
    const formatedDataTracking = trackingArr.map((tracking) => {
      return {
        ...tracking,
        date: Utils.dateToFormat(tracking.date, "DD/MM/YYYY h:mm"),
      };
    });
    return formatedDataTracking;
  }

  const handleAddInspection = (type) => {
    navigate(`inspections/add/${type}`);
  }; 

  const downloadPDF = async (event, rowData) => {
    const fileInfo = await getPDFInspections(rowData._id);
    Utils.createAndDownload(fileInfo.blob, fileInfo.data.name);
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={msgModal ? false : true}
        maxWidth={msgModal ? "xs" : "sm"}
        classes={{
          paper: classes.rootWholeDialog,
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          classes={{
            root: classes.rootTitle,
          }}
        >
          {titleDialog}
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.bodyDialog,
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {msgModal ? (
              <p>{msgModal}</p>
            ) : (
              <AddCommentComponent id={idComment} close={handleCloseDialog} />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {msgModal && (
            <Button onClick={handleCloseDialog} color="primary">
              Aceptar
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Grid item xs={12} style={{ marginBottom: 64 }}>
        <div style={{ marginBottom: 16, padding: 16 }}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link to="/dashboard">Home</Link>
            <Typography color="textPrimary">Inspecciones</Typography>
          </Breadcrumbs>
        </div>
        {brandsValueSWR.loading && isLoaded && inspectionWithStates && (
          <React.Fragment key={keyRefetch}>
            <CustomMaterialTable
              isLoading={!isLoaded}
              title=""
              columns={columnsInspections}
              data={inspectionWithStates}
              actions={[
                (rowData) => ({
                  icon: () => {
                    return labMode ? (
                      <Android
                        classes={{
                          root: classes.rootIcon,
                        }}
                      />
                    ) : (
                      <></>
                    );
                  },
                  tooltip: "AI inspector",
                  onClick: () => {
                    navigate(`/inspector?id=${rowData._id}&tmp=${rowData.id}`);
                  },
                }),
                {
                  icon: () => (
                    <Pageview
                      classes={{
                        root: classes.rootIcon,
                      }}
                    />
                  ),
                  tooltip: "Mostrar",
                  onClick: (event, rowData) => {
                    handleInitPage(page);
                    navigate(`/inspections/show/${rowData._id}`);
                  },
                },
                (rowData) => ({
                  icon: () => {
                    return (
                      <MenuVideoChat
                        whts={() =>
                          handleClickSendWhtsVideo(
                            rowData.id,
                            rowData.phone,
                            rowData.guideId
                          )
                        }
                        sms={() =>
                          handleClickSendSmsVideo(
                            rowData.id,
                            rowData.phone,
                            rowData.guideId
                          )
                        }
                        email={() =>
                          handleClickSendEmailVideo(
                            rowData.id,
                            rowData.email,
                            rowData.guideId
                          )
                        }
                        index={rowData.tableData.id + 1}
                        userId={rowData.id}
                        handleOpenAlert={handleOpenAlert}
                      />
                    );
                  },
                }),
                (rowData) => ({
                  icon: () => {
                    return (
                      <MenuFab
                        whts={() =>
                          handleClickSendWhts(
                            rowData.id,
                            rowData.phone,
                            rowData.guideId
                          )
                        }
                        sms={() =>
                          handleClickSendSms(
                            rowData.id,
                            rowData.phone,
                            rowData.guideId
                          )
                        }
                        email={() =>
                          handleClickSendEmail(
                            rowData.id,
                            rowData.email,
                            rowData.guideId
                          )
                        }
                        index={rowData.tableData.id}
                      />
                    );
                  },
                }),
                {
                  icon: () => (
                    <AddComment
                      classes={{
                        root: classes.rootIcon,
                      }}
                    />
                  ),
                  tooltip: "Agregar comentario",
                  onClick: (event, rowData) => {
                    handleClickAddComment(rowData._id, rowData.phone);
                  },
                },
                {
                  icon: () => (
                    <PictureAsPdf
                      classes={{
                        root: classes.rootIcon,
                      }}
                    />
                  ),
                  tooltip: "Descargar PDF",
                  onClick: downloadPDF,
                },
              ]}
              options={{
                paging: true,
                initialPage: initialPageTable,
                actionsColumnIndex: -1,
                actionsCellStyle: {
                  paddingLeft: 16,
                },
                headerStyle: {
                  fontWeight: "bold",
                },
                thirdSortClick: false,
              }}
              detailPanel={({ _id, tracking }) => (
                <div>
                  <Tabs id={_id} tracking={formatedDateTracking(tracking)} />
                </div>
              )}
              components={{
                Toolbar: (props) => (
                  <div className={classes.toolbarmt}>
                    <FilterButtonsComponent />
                    <MTableToolbar {...props} />
                  </div>
                ),
                Pagination: (props) => (
                  <TablePagination
                    {...props}
                    count={inspectionsCount}
                    rowsPerPage={pageSize}
                    page={page}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={(e) => {
                      handlePageSizeChange(e);
                      props.onChangeRowsPerPage(e);
                    }}
                  />
                ),
              }}
            />
          </React.Fragment>
        )}
        {brandsValueSWR.loading && !isLoaded && <Loading />}
        <div style={{ position: "relative" }}>
          {!loadingGuides && isLoaded && resultsGuide?.length === 0 && (
            <div style={{ position: "absolute", right: 0, top: 10 }}>
              <p>No posee guias activas</p>
              <a href="/guides/add" style={{ color: "blue" }}>
                Crear Guia
              </a>
            </div>
          )}
          <Fab
            disabled={!loadingGuides && resultsGuide?.length === 0}
            color="primary"
            style={{ right: 100 }}
            className={classes.addFab}
            onClick={() => handleAddInspection("dynamic")}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 56,
                height: 56,
              }}
            >
              <Add style={{ position: "absolute", top: 7, left: 6 }} />
              <ViewModule style={{ position: "absolute" }} />
            </div>
          </Fab>
          <Fab
            disabled={!loadingGuides && resultsGuide?.length === 0}
            color="primary"
            className={classes.addFab}
            onClick={() => handleAddInspection("simple")}
          >
            <Add />
          </Fab>
        </div>
      </Grid>
      <Dialog open={openAlert} onClose={handleCloseAlert}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            No puedes entrar a otra llamada sin haber finalizado la anterior.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Inspections;
