import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    controls: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 0,
      transition: 'opacity .2s ease-in',
      '&:hover': {
        opacity: 1
      },
      backgroundColor: 'rgba(0, 0, 0, .4)'
    },
    modal: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      display: 'flex',
      flexDirection: 'column',
      minWidth: 300,
      minHeight: 300,
      '& > div': {
        flex: 1
      }
    },
    controlButtons: {
      color: '#fff',
      border: '1px solid #fff',
      margin: '0px 2.5px'
    },
    closeZoomIcon: {
      position:'absolute', 
      right:10, 
      top:10, 
      zIndex:99,
      cursor: 'pointer'
    }
}));