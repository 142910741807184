import { useEffect, useState } from 'react';
import Axios from 'axios';
import useSWR from 'swr'
import { inspectionApiUrl, quotationApiUrl,  } from '../utils/urls';

export const useGetYears = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        Axios.get(`${quotationApiUrl}/catalog/years`)
            .then( ({ data })  => {
                const arrayData = data.map(
                    year => {
                        return {'label': year, 'value': year};
                    }
                );
                setData(arrayData);
            })
            .catch(err => {
                setData([]);
            })
            .finally(() => {
                setLoading(false);
            });
    },[])

    return [data, loading];
}

export const useGetBrands = () => {
    const [data, setBrands] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        Axios.get(`${quotationApiUrl}/catalog/brand/`)
            .then( ({ data })  => {
                setBrands(data);
            })
            .catch(err => {
                console.log('error at retreiving brands: ', err);
                setBrands([]);
            })
            .finally(()=> {
                setLoading(false);
            });
    },[])

    return [data, loading];
}
export const useGetModels = ( brandId ) => {
    console.log('brandId=>', brandId)
    const [data, setModels] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        Axios.get(`${quotationApiUrl}/catalog/model/${brandId}`) 
            .then( ({ data }) => {
                setModels(data)
            })
            .catch(err => {
                console.log('error at retreiving brands: ', err);
                setModels([]);
            })
            .finally(()=> {
                setLoading(false);
            });
    },[])

    return [data, loading];
}

export const useGetBrandsSWR = () => {

    const [loading, setLoading] = useState(false);

    const fetcher = (...args) => fetch(...args).then(
        (res) => {
            setLoading(true);
            return res.json()
        }
    ).catch(error => {
        setLoading(true);
        return {data:[], error }
    })

    const { data: result, error } = useSWR(`${quotationApiUrl}/catalog/brand/`, fetcher);
    return { result, loading, error }
}

export const useGetGuidesSWR = (companyId) => {
    const [loading, setLoading] = useState(true);
    const fetcher = (...args) => fetch(...args).then(
        (res) => {
            return res.json()
        }
    ).catch(error => {
        return {data:[], error }
    }).finally(()=>{
        setLoading(false);
    })

    const { data: result, error } = useSWR(`${inspectionApiUrl}/guides/company/${companyId}`, fetcher);
    return { result, loading, error }
}
